<ng-container
  *ngFor="
    let openSheet of globalSheetsService.openSheets$ | async;
    trackBy: trackBySheetId
  ">
  <ng-container [ngSwitch]="openSheet?.type">
    <app-employment-edit
      *ngSwitchCase="globalSheetTypeEnum.EMPLOYMENT_EDIT"
      [sheetId]="openSheet.sheetId"
      [employmentId]="openSheet.uuid"
      (sheetClosed)="removeSheet($event)"></app-employment-edit>
    <app-employment-overview
      *ngSwitchCase="globalSheetTypeEnum.EMPLOYEE_EDIT"
      [sheetId]="openSheet.sheetId"
      [employmentId]="openSheet.uuid"
      (sheetClosed)="removeSheet($event)"></app-employment-overview>
    <app-company-user-support
      *ngSwitchCase="globalSheetTypeEnum.COMPANY_SUPPORT"
      [sheetId]="openSheet.sheetId"
      (sheetClosed)="removeSheet($event)"></app-company-user-support>
    <app-employee-support
      *ngSwitchCase="globalSheetTypeEnum.EMPLOYEE_SUPPORT"
      [sheetId]="openSheet.sheetId"
      (sheetClosed)="removeSheet($event)"></app-employee-support>
    <app-profile
      *ngSwitchCase="globalSheetTypeEnum.PROFILE_EDIT"
      (sheetClosed)="removeSheet($event)"
      [sheetId]="openSheet.sheetId"></app-profile>
  </ng-container>
</ng-container>
