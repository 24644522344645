import { addHours, addMinutes, startOfDay } from 'date-fns';

// can convert 10:00 or 1000 to a Date with time 10:00 AM (day is not respected / always set to today)
export function militaryTimeToDate(militaryTime: number | string): Date {
  let militaryTimeString = militaryTime ? militaryTime.toString() : '000';
  if (militaryTimeString.length < 3) {
    militaryTimeString = '00' + militaryTimeString;
  }
  const hours = militaryTimeString.slice(0, militaryTimeString.length - 2);
  const minutes = militaryTimeString.slice(
    militaryTimeString.length - 2,
    militaryTimeString.length,
  );
  return addMinutes(
    addHours(startOfDay(new Date()), parseInt(hours, 10)),
    parseInt(minutes, 10),
  );
}
