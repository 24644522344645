export function getDurationMinutes(duration: string): number {
  if (!duration) {
    return 0;
  }
  duration = duration.replace(new RegExp(' ', 'g'), '');
  duration = duration.replace('m', '');

  const separatorIndex = duration.indexOf('h');

  if (separatorIndex !== -1) {
    const hours = duration.slice(0, separatorIndex);
    const minutes = duration.slice(separatorIndex + 1, duration.length);
    return (
      parseInt(hours, 10) * 60 +
      (minutes && minutes !== '' ? parseInt(minutes, 10) : 0)
    );
  } else {
    return parseInt(duration, 10);
  }
}

export function getDurationSeconds(duration: string): number {
  return getDurationMinutes(duration) * 60;
}
