<unijob-modal
  [id]="modalId"
  size="s"
  (modalOpened)="openApproveModal()"
  [formGroup]="approveDaysForm">
  <ng-container slot="header">
    {{ "approveDays" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    <ng-container *ngIf="toBeApprovedDays.length; else noApprovableDays">
      <div class="input-wrap !uj-mb-20">
        <label class="input-label"
          >{{ "approveDaysUntil" | i18next | firstUppercase }}
        </label>
        <unijob-date-picker
          formControlName="approveUntilDate"
          [placeholder]="'chooseDate' | i18next"
          [hasLastDay]="true"
          [onDayCreateFunction]="datePickerOnDayCreateFunction()"
          [dayDisabledFunctions]="[datePickerDayDisabledFunction()]">
        </unijob-date-picker>
      </div>
      <div
        class="uj-flex uj-flex-col uj-gap-2"
        *ngIf="approveUntilDateControl.value?.length">
        <div
          class="uj-flex uj-justify-between"
          *ngIf="selectedAbsencesCount">
          <span class="text-m-b uj-text-text-grey">{{
            "amountOfAbsences" | i18next
          }}</span>
          <span class="text-m-b">{{
            selectedAbsencesCount + " " + ("days" | i18next)
          }}</span>
        </div>
        <div
          class="uj-flex uj-justify-between"
          *ngIf="selectedExpensesTotals && selectedExpensesTotals.length > 0">
          <span class="text-m-b uj-text-text-grey">{{
            "totalExpenses" | i18next
          }}</span>
          <span class="text-m-b">
            <ng-container
              *ngFor="
                let expenseTotal of selectedExpensesTotals;
                let last = last
              ">
              <span class="total-expenses"
                >{{ expenseTotal.value }} {{ expenseTotal.currency }}</span
              >{{ last ? "" : "," }}
            </ng-container>
          </span>
        </div>
        <div class="uj-flex uj-justify-between">
          <span class="text-m-b uj-text-text-grey">{{
            "totalHours" | i18next
          }}</span>
          <span class="text-m-b"> {{ formattedHoursTotal }}</span>
        </div>
      </div>
    </ng-container>
    <ng-template #noApprovableDays>
      <div class="no-approvable-days-info">
        {{ "noDataToApprove" | i18next | firstUppercase }}
      </div>
    </ng-template>
  </ng-container>
  <ng-container slot="footer">
    <button
      [disabled]="
        approveUntilDateControl.touched && approveUntilDateControl.invalid
      "
      *ngIf="toBeApprovedDays.length"
      (click)="openSubmitConfirmation()"
      class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
      unijob-button
      type="button"
      variant="primary"
      theme="flat"
      size="m"
      corner="rounded"
      btnColor="bg-pink"
      contentColor="content-color-white">
      {{ "approveDays" | i18next | firstUppercase }}
    </button>
  </ng-container>
</unijob-modal>

<unijob-modal
  [id]="approveConfirmModalId"
  size="s">
  <ng-container slot="header">
    {{ "approveDaysConfirmation" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    {{ "approveDaysHint" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="footer">
    <div class="uj-flex uj-justify-end uj-gap-8">
      <button
        (click)="modals.close(approveConfirmModalId)"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        size="m"
        corner="rounded"
        contentColor="content-color-violet">
        {{ "close" | i18next | firstUppercase }}
      </button>
      <button
        (click)="submitDays()"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="primary"
        theme="flat"
        size="m"
        corner="rounded"
        btnColor="bg-pink"
        contentColor="content-color-white">
        {{ "confirm" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</unijob-modal>
