<div
  class="background"
  [class.wide-content-background]="type === 'text-content'"></div>
<div class="content-box" [class.wide-content-box]="type === 'text-content'">
  <header class="app-header">
    <img src="/assets/logo.svg" alt="Logo" class="main-logo" />
    <ng-content select="[slot=title]"></ng-content>
  </header>

  <div
    [class.form-wrapper]="type === 'form-box'"
    [class.content-area]="type === 'text-content'">
    <div class="inner-content">
      <ng-content select="[slot=content]"></ng-content>
    </div>
  </div>
</div>
