import { differenceInMinutes, format } from 'date-fns';
import i18next from 'i18next';

export function getLastEditTime(updatedAt: Date): {
  lastEditTime: string;
  lastEditTimeMobile: string;
} {
  let lastEditTime = '';
  let lastEditTimeMobile = '';

  const minutes = differenceInMinutes(new Date(), updatedAt);
  if (minutes < 1) {
    lastEditTime = i18next.t('last_changes_just_now');
    lastEditTimeMobile = lastEditTime;
  } else if (minutes < 60) {
    lastEditTime = i18next.t('last_changes_minutes_ago', {
      timePast: minutes,
    });
    lastEditTimeMobile = i18next.t('last_changes_minutes_ago_short', {
      timePast: minutes,
    });
  } else if (minutes < 1440) {
    lastEditTime = i18next.t('last_changes_hours_ago', {
      timePast: Math.floor(minutes / 60),
    });
    lastEditTimeMobile = i18next.t('last_changes_hours_ago_short', {
      timePast: Math.floor(minutes / 60),
    });
  } else {
    lastEditTime = i18next.t('last_changes_at', {
      timePast: format(updatedAt, 'dd.MM.yy'),
    });
    lastEditTimeMobile = lastEditTime;
  }
  return {
    lastEditTime,
    lastEditTimeMobile,
  };
}
