import { logCustomError } from './logError';
import imageCompression from 'browser-image-compression';

export async function compressFile(
  file: File,
  compressProgressFunc?: (percentage: number) => void,
): Promise<File> {
  const options: any = {
    maxSizeMB: 2,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  if (compressProgressFunc) {
    options.onProgress = compressProgressFunc;
  }
  try {
    const compressedFile = await imageCompression(file, options);
    return new File([compressedFile], file.name, { type: file.type });
  } catch (error) {
    if (file.type !== 'application/pdf') {
      logCustomError(error);
    }
    // if the file cant be compressed return the file
    return file;
  }
}
