<unijob-avatar
  *ngIf="_user"
  class="uj-flex uj-justify-center"
  [content]="_user.profile.image ? 'image' : 'letters'"
  [imageUrl]="objectUrl ? objectUrl : ''"
  [letters]="
    _user.profile.firstName.charAt(0) +
    (onlyFirstName ? '' : _user.profile.lastName.charAt(0))
  "
  [size]="size"
  [interactive]="_interactive">
  <ng-container slot="tag">
    <ng-content select="[slot=tag]"> </ng-content>
  </ng-container>
</unijob-avatar>
