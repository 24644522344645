<unijob-modal
  [id]="modalId"
  size="s"
  [preventClosing]="reportDurationForm.dirty"
  (closePrevented)="failedToClose()"
  (closeClicked)="closeModal()">
  <ng-container slot="header">
    {{ "report" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    <div
      *ngIf="isDisabled && !(userService.userIsCompanyUser$ | async)"
      class="uj-flex uj-flex-col uj-gap-12 uj-mb-20">
      <span class="uj-text-lgr">
        {{ "uploadedWrongRapport" | i18next | firstUppercase }}
      </span>
      <a
        routerLink="/contact"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        size="m"
        corner="rounded"
        contentColor="content-color-violet">
        {{ "contact" | i18next | firstUppercase }}
      </a>
    </div>
    <div
      *ngIf="modalIsOpen"
      class="uj-flex uj-flex-col uj-gap-20"
      [formGroup]="reportDurationForm">
      <div class="input-wrap !uj-mb-0">
        <label class="input-label"
          >{{ "timespan" | i18next | firstUppercase
          }}<span class="red-text">*</span>
        </label>
        <unijob-date-picker
          formControlName="reportTimespan"
          mode="range"
          [placeholder]="'chooseDateRange' | i18next"
          [dayDisabledFunctions]="[datePickerDayDisabledFunction()]">
        </unijob-date-picker>
      </div>

      <div class="input-wrap !uj-mb-0">
        <label class="input-label"
          >{{
            (!report?.createdAt ? "addAttachment" : "attachment")
              | i18next
              | firstUppercase
          }}<span class="red-text">*</span>
        </label>
        <ng-container *ngIf="loading; else showFileUpload">
          <div class="uj-flex uj-justify-center">
            <app-loading-spinner
              class="uj-block"
              position="static"></app-loading-spinner>
          </div>
        </ng-container>
        <ng-template #showFileUpload>
          <unijob-input-file
            formControlName="reportDocument"
            inputId="reportDocument"
            name="file"
            [compression]="true"
            [maxCompressionSizeMB]="40"
            [maxFileSize]="320000000"
            [errorFileTooLargeMessage]="
              'fileToLargeError' | i18next | firstUppercase
            "
            [showDelete]="!report?.createdAt"
            [disabled]="!report?.createdAt"
            (ngModelChange)="fileChanged($event)"
            [uploadLabel]="'chooseFile' | i18next | firstUppercase"
            [uploadTitle]="'dropDocumentHere' | i18next | firstUppercase"
            uploadDescription="ex. pdf, doc, max. 40MB"
            showDownload
            showPreview
            showInvalidState></unijob-input-file>
        </ng-template>
      </div>
    </div>
    <div
      *ngIf="!isDisabled && !(userService.userIsCompanyUser$ | async)"
      class="uj-mt-20">
      <unijob-info-box
        type="WARNING"
        [title]="'reportIsTransmittedDirectly' | i18next | firstUppercase"
        [message]="
          'reportIsTransmittedDirectlyMessage' | i18next | firstUppercase
        ">
      </unijob-info-box>
    </div>
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col sm:uj-flex-row uj-w-full uj-gap-8 uj-justify-end">
      <button
        *ngIf="report?.createdAt"
        (click)="closeModal()"
        [isDisabled]="reportDurationForm.invalid"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="primary"
        theme="flat"
        size="m"
        corner="rounded"
        btnColor="bg-pink"
        contentColor="content-color-white">
        {{ "close" | i18next | firstUppercase }}
      </button>
      <button
        *ngIf="!report?.createdAt"
        (click)="saveReport()"
        [isDisabled]="reportDurationForm.invalid"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="primary"
        theme="flat"
        size="m"
        corner="rounded"
        btnColor="bg-pink"
        contentColor="content-color-white">
        {{ "uploadReport" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</unijob-modal>

<unijob-modal
  [id]="unsavedChangesModalId"
  (closePrevented)="failedToClose()"
  size="s">
  <ng-container slot="header">
    {{ "unsavedChangesTitle" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    {{ "unsavedChangesConfirmation" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col sm:uj-flex-row uj-w-full uj-gap-8 uj-justify-end">
      <button
        (click)="discardChanges()"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        size="m"
        iconLeft="trash"
        contentColor="content-color-dark-grey"
        corner="rounded">
        {{ "discardChanges" | i18next | firstUppercase }}
      </button>
      <button
        (click)="saveChanges()"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="primary"
        theme="flat"
        size="m"
        corner="rounded"
        btnColor="bg-pink"
        contentColor="content-color-white">
        {{ "uploadReport" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</unijob-modal>
