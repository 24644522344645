export function parseDecimalToTimeString(decimal: string): string {
  const separator = decimal.includes('.') ? '.' : ',';
  const [hoursPart, minutesPart] = decimal.split(separator);
  const hours = parseInt(hoursPart, 10);
  const minutes = Math.round(
    parseFloat(`0.${minutesPart.replace(',', '.')}`) * 60,
  );

  return `${hours.toString().padStart(1, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
}
