import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  GlobalSheetOptions,
  GlobalSheetsService,
  GlobalSheetTypeEnum,
} from './global-sheets.service';
import { SheetService } from '@intemp/unijob-ui';

@Component({
  selector: 'app-global-sheets',
  templateUrl: './global-sheets.component.html',
  styleUrls: ['./global-sheets.component.scss'],
})
export class GlobalSheetsComponent {
  globalSheetTypeEnum = GlobalSheetTypeEnum;

  constructor(
    public sheetsService: SheetService,
    private route: ActivatedRoute,
    public globalSheetsService: GlobalSheetsService,
  ) {}

  trackBySheetId(index: number, sheet: GlobalSheetOptions): string {
    return sheet.sheetId;
  }

  removeSheet(sheetId: string): void {
    this.globalSheetsService.removeGlobalSheet(sheetId);
  }
}
