import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollToTopComponent } from './scroll-to-top.component';
import { ButtonModule } from '@intemp/unijob-ui';
import { SharedDefaultModule } from '../shared-default/shared-default.module';

@NgModule({
  declarations: [ScrollToTopComponent],
  exports: [ScrollToTopComponent],
  imports: [CommonModule, ButtonModule, SharedDefaultModule],
})
export class ScrollToTopModule {}
