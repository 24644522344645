<unijob-modal
  [id]="modalId"
  [size]="(isSmDown$ | async) ? 's' : 'm'"
  [preventClosing]="!!expensesForm?.dirty"
  (closePrevented)="failedToClose()"
  (closeClicked)="closeModal()">
  <ng-container slot="header">
    {{ "expenses" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    <div
      *ngIf="modalIsOpen"
      [formGroup]="expensesForm">
      <div class="input-wrap">
        <label class="input-label"
          >{{ "date" | i18next | firstUppercase
          }}<span class="red-text">*</span>
        </label>
        <unijob-date-picker
          formControlName="expenseDate"
          fontWeight="font-bold"
          [placeholder]="'chooseDate' | i18next"
          (ngModelChange)="setDay($event)"
          [dayDisabledFunctions]="[datePickerDayDisabledFunction()]"
          [required]="true">
        </unijob-date-picker>
      </div>
      <div>
        <span class="text-m-b">{{ "entries" | i18next | firstUppercase }}</span>
        <div class="uj-flex uj-flex-col md:uj-flex-row uj-gap-12 uj-mt-4">
          <div class="uj-flex-1 uj-flex uj-flex-col uj-gap-20">
            <ng-container formArrayName="expensesEntries">
              <ng-container
                *ngFor="
                  let expenseForm of expensesEntries().controls;
                  let i = index;
                  trackBy: trackByUuid
                ">
                <div class="uj-flex uj-gap-8">
                  <span
                    class="uj-h-auto uj-w-[3px] uj-bg-violet-290/54 uj-rounded"></span>
                  <div
                    *ngIf="expensesForm"
                    style="max-width: calc(100% - 11px)"
                    class="uj-flex-1 uj-flex uj-flex-col uj-gap-8 uj-w-full"
                    [formGroup]="expenseForm">
                    <div
                      class="uj-flex uj-gap-8 uj-flex-col md:uj-flex-row md:uj-gap-0"
                      [ngClass]="{
                        'uj-outline uj-outline-1 uj-outline-offset-2 uj-outline-text-error uj-rounded-sm uj-mt-2':
                          allFieldsAreInvalid(i)
                      }">
                      <unijob-input
                        class="uj-flex-1"
                        formControlName="note"
                        [placeholder]="'description' | i18next"
                        elevation="box-outline"
                        inputType="text"
                        fontWeight="font-bold"
                        [showInvalidState]="!allFieldsAreInvalid(i)"
                        [positionInGroup]="
                          (isSmDown$ | async) ? 'none' : 'first'
                        "
                        bgColor="bg-white"></unijob-input>
                      <unijob-input
                        class="uj-w-full md:uj-w-[140px]"
                        formControlName="cost"
                        placeholder="CHF"
                        elevation="box-outline"
                        fontWeight="font-bold"
                        inputType="text"
                        [showInvalidState]="!allFieldsAreInvalid(i)"
                        name="cost"
                        (change)="costInputUpdate(i)"
                        [positionInGroup]="
                          !dayStatusOpen && !(isSmDown$ | async)
                            ? 'last-border-none'
                            : (isSmDown$ | async)
                              ? 'none'
                              : 'middle-border-none'
                        "
                        bgColor="bg-white"></unijob-input>
                      <div *ngIf="isSmDown$ | async">
                        <ng-container
                          *ngIf="
                            !dayStatusOpen &&
                              expensesEntry?.createdAt &&
                              expenseForm.controls?.documentFiles?.value ===
                                null;
                            else showDocumentUploader
                          ">
                          <div
                            class="uj-flex uj-p-[10px] uj-rounded uj-gap-8 uj-bg-surface-light uj-border-[1px] uj-border-border">
                            <unijob-icon
                              icon="file_document_blank"
                              class="uj-icon-grey"
                              size="20"></unijob-icon>
                            <span class="text-m-b">{{
                              "noReceiptUploaded" | i18next | firstUppercase
                            }}</span>
                          </div>
                        </ng-container>
                      </div>
                      <button
                        *ngIf="!(isSmDown$ | async) && dayStatusOpen"
                        [isDisabled]="
                          (i === 0 &&
                            !expenseForm.value.note &&
                            !expenseForm.value.cost &&
                            !expenseForm.value.documentFiles) ||
                          expenseForm.disabled
                        "
                        (click)="deleteEntry(i)"
                        class="!uj-w-full md:!uj-w-auto uj-whitespace-nowrap input-delete"
                        unijob-button
                        type="button"
                        variant="outline"
                        theme="flat"
                        size="m"
                        iconOnly="trash"
                        positionInGroup="last"
                        contentColor="content-color-dark-grey"></button>
                      <ng-container
                        *ngIf="
                          isCompanyUser &&
                          dayStatusOpen &&
                          expenseForm.controls.createdByFullName?.value !==
                            loggedInUserFullName &&
                          !expenseForm.controls.isNewAdjustment?.value
                        ">
                        <button
                          (click)="adjustEntry(i)"
                          class="!uj-w-full md:!uj-w-auto uj-whitespace-nowrap"
                          unijob-button
                          type="button"
                          variant="outline"
                          theme="flat"
                          size="m"
                          corner="rounded"
                          [iconOnly]="
                            (isSmDown$ | async) ? undefined : 'edit_pen_1'
                          "
                          [iconLeft]="
                            !(isSmDown$ | async) ? undefined : 'edit_pen_1'
                          "
                          positionInGroup="last"
                          contentColor="content-color-dark-grey">
                          <ng-container *ngIf="isSmDown$ | async">
                            {{ "edit" | i18next }}
                          </ng-container>
                        </button>
                      </ng-container>
                      <button
                        *ngIf="(isSmDown$ | async) && dayStatusOpen"
                        [isDisabled]="expenseForm.disabled"
                        (click)="deleteEntry(i)"
                        class="!uj-w-full md:!uj-w-auto uj-whitespace-nowrap"
                        unijob-button
                        type="button"
                        variant="outline"
                        theme="flat"
                        size="m"
                        corner="rounded"
                        iconLeft="trash"
                        contentColor="content-color-dark-grey">
                        {{ "delete" | i18next }}
                      </button>
                    </div>
                    <ng-container
                      *ngIf="
                        expensesEntriesControl.controls[i].controls
                          .previousVersion as previousForm
                      ">
                      <ng-container>
                        <div
                          [formGroup]="previousForm"
                          class="uj-flex uj-gap-2 uj-flex-col">
                          <div
                            class="uj-flex uj-gap-8 uj-flex-col md:uj-flex-row md:uj-gap-0">
                            <div
                              [ngClass]="{
                                'uj-relative stroke-content': !(
                                  isSmDown$ | async
                                )
                              }"
                              class="uj-flex-1 uj-flex uj-gap-8 uj-flex-col md:uj-flex-row md:uj-gap-0">
                              <unijob-input
                                class="uj-flex-1"
                                [ngClass]="{
                                  'uj-relative stroke-content':
                                    isSmDown$ | async
                                }"
                                formControlName="note"
                                [placeholder]="'description' | i18next"
                                elevation="box-outline"
                                inputType="text"
                                fontWeight="font-bold"
                                [positionInGroup]="
                                  (isSmDown$ | async) ? 'none' : 'first'
                                "
                                bgColor="bg-white"></unijob-input>
                              <unijob-input
                                class="uj-w-full md:uj-w-[140px]"
                                [ngClass]="{
                                  'uj-relative stroke-content':
                                    isSmDown$ | async
                                }"
                                formControlName="cost"
                                placeholder="CHF"
                                elevation="box-outline"
                                fontWeight="font-bold"
                                inputType="text"
                                [positionInGroup]="
                                  !dayStatusOpen && !(isSmDown$ | async)
                                    ? 'last-border-none'
                                    : (isSmDown$ | async)
                                      ? 'none'
                                      : 'middle-border-none'
                                "
                                bgColor="bg-white"></unijob-input>
                            </div>
                            <button
                              *ngIf="!(isSmDown$ | async) && dayStatusOpen"
                              [isDisabled]="previousForm.disabled"
                              class="!uj-w-full md:!uj-w-auto input-delete"
                              unijob-button
                              type="button"
                              variant="outline"
                              theme="flat"
                              size="m"
                              corner="rounded"
                              iconOnly="trash"
                              positionInGroup="last"
                              contentColor="content-color-dark-grey"></button>
                          </div>
                          <div
                            *ngIf="
                              !isCompanyUser &&
                              expenseForm.controls.createdByFullName?.value
                            ">
                            <span class="text-s uj-text-text-warning-1">
                              {{
                                expenseForm.controls.createdAt?.value
                                  | date: "dd.MM.yyyy"
                              }}
                              |
                              {{ "adjustedBy" | i18next | firstUppercase }}
                              {{
                                expenseForm.controls.createdByFullName?.value
                              }}
                            </span>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                    <div *ngIf="!(isSmDown$ | async)">
                      <ng-container
                        *ngIf="
                          (expenseForm.controls.createdByFullName?.value !==
                            loggedInUserFullName &&
                            expenseForm.controls.documentFiles?.value ===
                              null) ||
                            expensesForm.controls.expensesEntries.controls[i]
                              .controls.previousVersion;
                          else showDocumentUploader
                        ">
                        <div
                          class="uj-flex uj-p-[10px] uj-rounded uj-gap-8 uj-bg-surface-light uj-border-[1px] uj-border-border">
                          <unijob-icon
                            icon="file_document_blank"
                            class="uj-icon-grey"
                            size="20"></unijob-icon>
                          <span class="text-m-b">{{
                            "noReceiptUploaded" | i18next | firstUppercase
                          }}</span>
                        </div>
                      </ng-container>
                    </div>
                    <ng-template #showDocumentUploader>
                      <unijob-input-file
                        formControlName="documentFiles"
                        [inputId]="'file-input-' + i"
                        name="file"
                        [size]="(isSmDown$ | async) ? 'size-s' : 'size-m'"
                        [color]="(isSmDown$ | async) ? 'violet' : 'pink'"
                        [compression]="true"
                        [maxCompressionSizeMB]="40"
                        [maxFileSize]="320000000"
                        [errorFileTooLargeMessage]="
                          'fileToLargeError' | i18next | firstUppercase
                        "
                        (ngModelChange)="fileChange($event, i)"
                        [uploadLabel]="'chooseFile' | i18next | firstUppercase"
                        [uploadTitle]="
                          'dropDocumentHere' | i18next | firstUppercase
                        "
                        uploadDescription="ex. pdf, doc, max. 40MB"
                        [showDelete]="
                          expenseForm.controls.createdByFullName?.value ===
                            loggedInUserFullName &&
                          dayStatusOpen &&
                          !expenseForm.disabled
                        "
                        showDownload
                        showInvalidState></unijob-input-file>
                    </ng-template>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <div
            class="uj-flex uj-justify-center md:uj-block"
            *ngIf="dayStatusOpen">
            <button
              (click)="addExpenseEntry()"
              class="uj-w-auto uj-whitespace-nowrap"
              unijob-button
              type="button"
              variant="primary"
              theme="flat"
              size="m"
              corner="rounded"
              iconOnly="plus"
              btnColor="bg-violet"
              contentColor="content-color-white"></button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container slot="footer">
    <div
      *ngIf="modalIsOpen"
      class="uj-flex uj-flex-col md:uj-flex-row uj-w-full uj-gap-8 uj-justify-end">
      <button
        *ngIf="!!(canDeleteExpenses$ | async)"
        (click)="showDeletePrompt()"
        class="!uj-w-full md:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        size="m"
        corner="rounded"
        iconLeft="trash"
        contentColor="content-color-dark-grey">
        {{ "delete" | i18next | firstUppercase }}
      </button>
      <button
        *ngIf="
          (day && !dayStatusOpen && !isCompanyUser) ||
          (isCompanyUser && !dayStatusOpen)
        "
        (click)="closeModal()"
        class="!uj-w-full md:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="primary"
        theme="flat"
        size="m"
        corner="rounded"
        btnColor="bg-pink"
        contentColor="content-color-white">
        {{ "close" | i18next | firstUppercase }}
      </button>
      <button
        *ngIf="dayStatusOpen"
        (click)="saveExpenses(expensesEntry?.createdAt ? 'update' : 'create')"
        [isDisabled]="!!(saveButtonIsDisabled$ | async)"
        class="!uj-w-full md:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="primary"
        theme="flat"
        size="m"
        corner="rounded"
        btnColor="bg-pink"
        contentColor="content-color-white">
        {{
          (expensesEntry?.createdAt ? "save" : "support.employee.enterExpenses")
            | i18next
            | firstUppercase
        }}
      </button>
    </div>
  </ng-container>
</unijob-modal>

<unijob-modal
  [id]="unsavedChangesModalId"
  size="s">
  <ng-container slot="header">
    {{ "unsavedChangesTitle" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    {{ "unsavedChangesConfirmation" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col sm:uj-flex-row uj-w-full uj-gap-8 uj-justify-end">
      <button
        (click)="discardChanges(this.unsavedChangesModalId)"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        size="m"
        iconLeft="trash"
        contentColor="content-color-dark-grey"
        corner="rounded">
        {{ "discardChanges" | i18next | firstUppercase }}
      </button>
      <button
        (click)="saveChanges()"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="primary"
        theme="flat"
        size="m"
        corner="rounded"
        btnColor="bg-pink"
        contentColor="content-color-white">
        {{ "save" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</unijob-modal>

<unijob-modal
  [id]="deleteEntryModalId"
  size="s">
  <ng-container slot="header">
    {{ "deleteEntry" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    {{ "entryDeleteHint" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col sm:uj-flex-row uj-w-full uj-gap-8 uj-justify-end">
      <button
        (click)="discardChanges(this.deleteEntryModalId)"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        size="m"
        contentColor="content-color-dark-grey"
        corner="rounded">
        {{ "cancel" | i18next | firstUppercase }}
      </button>
      <button
        (click)="deleteAllEntries()"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        iconLeft="trash"
        type="button"
        variant="primary"
        theme="flat"
        size="m"
        corner="rounded"
        btnColor="bg-pink"
        contentColor="content-color-white">
        {{ "delete" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</unijob-modal>
