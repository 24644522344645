import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ResponsiveService, SheetService } from '@intemp/unijob-ui';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-employee-support',
  templateUrl: './employee-support.component.html',
  styleUrls: ['./employee-support.component.scss'],
})
export class EmployeeSupportComponent implements AfterViewInit {
  @Input({ required: true }) sheetId!: string;
  @Output() sheetClosed = new EventEmitter<string>();

  isSmDown$ = this.responsiveService.isSmDown$;

  hoursEntriesActionButtonList: {
    svg: SafeHtml;
    title: string;
    content: string;
  }[] = [
    {
      svg: this.sanitizer.bypassSecurityTrustHtml(
        '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="40" height="40" rx="4" fill="#281E78"/> <mask id="mask0_1603_426521" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="10" width="20" height="20"> <path d="M20 14.1667V25.8334" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M14.168 20H25.8346" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </mask> <g mask="url(#mask0_1603_426521)"> <rect x="10" y="10" width="20" height="20" fill="white"/> </g> </svg>',
      ),
      title: 'support.employee.add',
      content: 'support.employee.addHoursEntries',
    },
    {
      svg: this.sanitizer.bypassSecurityTrustHtml(
        '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill="white"/> <mask id="mask0_1603_426531" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="10" width="20" height="20"> <path d="M27.5 22.5H24.1667C23.7246 22.5 23.3007 22.6756 22.9882 22.9882C22.6756 23.3007 22.5 23.7246 22.5 24.1667V27.5" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M15.8333 12.5H24.1667C25.0507 12.5 25.8986 12.8512 26.5237 13.4763C27.1488 14.1014 27.5 14.9493 27.5 15.8333V22.786C27.5 23.67 27.1488 24.5179 26.5237 25.143L25.143 26.5237C24.5179 27.1488 23.67 27.5 22.786 27.5H15.8333C14.9493 27.5 14.1014 27.1488 13.4763 26.5237C12.8512 25.8986 12.5 25.0507 12.5 24.1667V15.8333C12.5 14.9493 12.8512 14.1014 13.4763 13.4763C14.1014 12.8512 14.9493 12.5 15.8333 12.5V12.5Z" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </mask> <g mask="url(#mask0_1603_426531)"> <rect x="10" y="10" width="20" height="20" fill="#323232"/> </g> <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#EEEDF2"/> </svg>',
      ),
      title: 'note',
      content: 'support.employee.addNote',
    },
    {
      svg: this.sanitizer.bypassSecurityTrustHtml(
        '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill="white"/> <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#EEEDF2"/> <mask id="mask0_2526_42895" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="10" width="20" height="20"> <path d="M27.5 22.5H24.1667C23.7246 22.5 23.3007 22.6756 22.9882 22.9882C22.6756 23.3007 22.5 23.7246 22.5 24.1667V27.5" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M15.8333 12.5H24.1667C25.0507 12.5 25.8986 12.8512 26.5237 13.4763C27.1488 14.1014 27.5 14.9493 27.5 15.8333V22.786C27.5 23.67 27.1488 24.5179 26.5237 25.143L25.143 26.5237C24.5179 27.1488 23.67 27.5 22.786 27.5H15.8333C14.9493 27.5 14.1014 27.1488 13.4763 26.5237C12.8512 25.8986 12.5 25.0507 12.5 24.1667V15.8333C12.5 14.9493 12.8512 14.1014 13.4763 13.4763C14.1014 12.8512 14.9493 12.5 15.8333 12.5V12.5Z" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M16.6667 16.6666H23.3334" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M16.6667 20H20.8334" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M16.6667 23.3334H19.1667" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </mask> <g mask="url(#mask0_2526_42895)"> <rect x="10" y="10" width="20" height="20" fill="#323232"/> </g> <rect x="26" y="6" width="8" height="8" rx="4" fill="#E6007E"/> </svg>',
      ),
      title: 'note',
      content: 'support.employee.editNote',
    },
    {
      svg: this.sanitizer.bypassSecurityTrustHtml(
        '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill="white"/> <mask id="mask0_1603_426554" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="10" width="20" height="20"> <path d="M14.7209 17.2223V26.4816C14.7209 26.9727 14.916 27.4437 15.2633 27.791C15.6106 28.1383 16.0816 28.3334 16.5728 28.3334H23.9802C24.4713 28.3334 24.9423 28.1383 25.2896 27.791C25.6369 27.4437 25.832 26.9727 25.832 26.4816V17.2223M22.1283 18.1482V24.6297M18.4246 18.1482V24.6297M13.332 14.4445H27.2209M16.5728 14.4445L17.0765 12.9334C17.1993 12.5645 17.4352 12.2436 17.7506 12.0163C18.0661 11.789 18.4451 11.6667 18.8339 11.6667H21.7191C22.1082 11.6663 22.4876 11.7884 22.8034 12.0158C23.1192 12.2431 23.3554 12.5642 23.4783 12.9334L23.9802 14.4445" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </mask> <g mask="url(#mask0_1603_426554)"> <rect x="10" y="10" width="20" height="20" fill="#323232"/> </g> <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#EEEDF2"/> </svg>',
      ),
      title: 'delete',
      content: 'support.employee.deleteEntry',
    },
    {
      svg: this.sanitizer.bypassSecurityTrustHtml(
        '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill="white"/> <mask id="mask0_1603_426563" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="10" width="20" height="20"> <path d="M19.9963 24.1667C19.538 24.1667 19.163 24.5417 19.168 25.0001C19.168 25.4584 19.543 25.8334 20.0013 25.8334C20.4596 25.8334 20.8346 25.4584 20.8346 25.0001C20.8346 24.5417 20.4596 24.1667 19.9963 24.1667Z" fill="#281E78" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M19.9963 19.1667C19.538 19.1667 19.163 19.5417 19.168 20.0001C19.168 20.4584 19.543 20.8334 20.0013 20.8334C20.4596 20.8334 20.8346 20.4584 20.8346 20.0001C20.8346 19.5417 20.4596 19.1667 19.9963 19.1667Z" fill="#281E78" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M19.9963 14.1667C19.538 14.1667 19.163 14.5417 19.168 15.0001C19.168 15.4584 19.543 15.8334 20.0013 15.8334C20.4596 15.8334 20.8346 15.4584 20.8346 15.0001C20.8346 14.5417 20.4596 14.1667 19.9963 14.1667Z" fill="#281E78" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </mask> <g mask="url(#mask0_1603_426563)"> <rect x="10" y="10" width="20" height="20" fill="#323232"/> </g> <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#EEEDF2"/> </svg>',
      ),
      title: 'support.employee.pointsMenuSmartphone',
      content: 'support.employee.pointsMenuSmartphoneText',
    },
    {
      svg: this.sanitizer.bypassSecurityTrustHtml(
        '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill="white"/> <mask id="mask0_1603_426574" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="10" width="20" height="20"> <path d="M19.9963 24.1667C19.538 24.1667 19.163 24.5417 19.168 25.0001C19.168 25.4584 19.543 25.8334 20.0013 25.8334C20.4596 25.8334 20.8346 25.4584 20.8346 25.0001C20.8346 24.5417 20.4596 24.1667 19.9963 24.1667Z" fill="#281E78" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M19.9963 19.1667C19.538 19.1667 19.163 19.5417 19.168 20.0001C19.168 20.4584 19.543 20.8334 20.0013 20.8334C20.4596 20.8334 20.8346 20.4584 20.8346 20.0001C20.8346 19.5417 20.4596 19.1667 19.9963 19.1667Z" fill="#281E78" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M19.9963 14.1667C19.538 14.1667 19.163 14.5417 19.168 15.0001C19.168 15.4584 19.543 15.8334 20.0013 15.8334C20.4596 15.8334 20.8346 15.4584 20.8346 15.0001C20.8346 14.5417 20.4596 14.1667 19.9963 14.1667Z" fill="#281E78" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </mask> <g mask="url(#mask0_1603_426574)"> <rect x="10" y="10" width="20" height="20" fill="#323232"/> </g> <rect x="29.332" y="2.6665" width="8" height="8" rx="4" fill="#E6007E"/> <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#EEEDF2"/> </svg>',
      ),
      title: 'support.employee.pointsMenuSmartphone',
      content: 'support.employee.pointsMenuSmartphoneHint',
    },
  ];

  expensesEntries: {
    svg: SafeHtml;
    title: string;
    content: string;
  }[] = [
    {
      svg: this.sanitizer.bypassSecurityTrustHtml(
        '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="40" height="40" rx="4" fill="#281E78"/> <mask id="mask0_1603_426521" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="10" width="20" height="20"> <path d="M20 14.1667V25.8334" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M14.168 20H25.8346" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </mask> <g mask="url(#mask0_1603_426521)"> <rect x="10" y="10" width="20" height="20" fill="white"/> </g> </svg>',
      ),
      title: 'support.employee.add',
      content: 'support.employee.addHoursEntries',
    },
    {
      svg: this.sanitizer.bypassSecurityTrustHtml(
        '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill="white"/> <mask id="mask0_1603_426563" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="10" width="20" height="20"> <path d="M19.9963 24.1667C19.538 24.1667 19.163 24.5417 19.168 25.0001C19.168 25.4584 19.543 25.8334 20.0013 25.8334C20.4596 25.8334 20.8346 25.4584 20.8346 25.0001C20.8346 24.5417 20.4596 24.1667 19.9963 24.1667Z" fill="#281E78" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M19.9963 19.1667C19.538 19.1667 19.163 19.5417 19.168 20.0001C19.168 20.4584 19.543 20.8334 20.0013 20.8334C20.4596 20.8334 20.8346 20.4584 20.8346 20.0001C20.8346 19.5417 20.4596 19.1667 19.9963 19.1667Z" fill="#281E78" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M19.9963 14.1667C19.538 14.1667 19.163 14.5417 19.168 15.0001C19.168 15.4584 19.543 15.8334 20.0013 15.8334C20.4596 15.8334 20.8346 15.4584 20.8346 15.0001C20.8346 14.5417 20.4596 14.1667 19.9963 14.1667Z" fill="#281E78" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </mask> <g mask="url(#mask0_1603_426563)"> <rect x="10" y="10" width="20" height="20" fill="#323232"/> </g> <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#EEEDF2"/> </svg>',
      ),
      title: 'support.employee.pointsMenuSmartphone',
      content: 'support.employee.pointsMenuSmartphoneText',
    },
    {
      svg: this.sanitizer.bypassSecurityTrustHtml(
        '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill="white"/> <mask id="mask0_1603_426574" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="10" width="20" height="20"> <path d="M19.9963 24.1667C19.538 24.1667 19.163 24.5417 19.168 25.0001C19.168 25.4584 19.543 25.8334 20.0013 25.8334C20.4596 25.8334 20.8346 25.4584 20.8346 25.0001C20.8346 24.5417 20.4596 24.1667 19.9963 24.1667Z" fill="#281E78" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M19.9963 19.1667C19.538 19.1667 19.163 19.5417 19.168 20.0001C19.168 20.4584 19.543 20.8334 20.0013 20.8334C20.4596 20.8334 20.8346 20.4584 20.8346 20.0001C20.8346 19.5417 20.4596 19.1667 19.9963 19.1667Z" fill="#281E78" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M19.9963 14.1667C19.538 14.1667 19.163 14.5417 19.168 15.0001C19.168 15.4584 19.543 15.8334 20.0013 15.8334C20.4596 15.8334 20.8346 15.4584 20.8346 15.0001C20.8346 14.5417 20.4596 14.1667 19.9963 14.1667Z" fill="#281E78" stroke="#281E78" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </mask> <g mask="url(#mask0_1603_426574)"> <rect x="10" y="10" width="20" height="20" fill="#323232"/> </g> <rect x="29.332" y="2.6665" width="8" height="8" rx="4" fill="#E6007E"/> <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#EEEDF2"/> </svg>',
      ),
      title: 'support.employee.pointsMenuSmartphone',
      content: 'support.employee.pointsMenuSmartphoneHint',
    },
  ];

  constructor(
    private sheetService: SheetService,
    private responsiveService: ResponsiveService,
    private sanitizer: DomSanitizer,
  ) {}

  async ngAfterViewInit() {
    await this.openSheet();
  }

  async openSheet(): Promise<void> {
    await this.sheetService.open(this.sheetId);
  }

  closeSheet(): void {
    this.sheetClosed.emit(this.sheetId);
  }
}
