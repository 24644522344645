export async function base64Encode(file: File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = (reader.result as string)
        .replace('data:', '')
        .replace(/^.+,/, '');
      resolve(base64String);
    };
    reader.readAsDataURL(file);
  });
}
