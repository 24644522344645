<div class="unijob-card size-none uj-bg-surface-white">
  <div
    class="uj-w-full uj-flex uj-justify-between uj-cursor-pointer uj-p-20 uj-gap-20 uj-items-center"
    (click)="collapseTarget.toggle()">
    <div class="uj-flex uj-gap-8 uj-w-full uj-flex-col md:uj-flex-row">
      <span class="title-xs uj-whitespace-pre">{{
        month.startDate | date: "MMMM y"
      }}</span>
      <span
        class="uj-flex uj-gap-4 uj-justify-between uj-w-full uj-flex-col-reverse md:uj-flex-row">
        <ng-container *ngIf="isCompanyUser; else employeeDayStatus">
          <span
            class="uj-flex uj-gap-4 uj-text-text-accent uj-items-center"
            *ngIf="daysToApprove && daysToApprove > 0">
            <span class="unijob-dot color-pink"></span>
            <span
              [class.text-s-b]="isSmDown$ | async"
              class="text-m-b"
              >{{
                daysToApprove > 1
                  ? ("xDaysToApprove"
                    | i18next: { amount: daysToApprove }
                    | firstUppercase)
                  : ("1DayToApprove" | i18next | firstUppercase)
              }}</span
            >
          </span>
        </ng-container>
        <ng-template #employeeDayStatus>
          <span
            class="uj-flex uj-gap-4 uj-items-center uj-text-success-green-450"
            *ngIf="allDaysAreApproved">
            <unijob-icon
              icon="check"
              size="16"></unijob-icon>
            <span class="text-s-b">
              {{ "allDaysAreApproved" | i18next | firstUppercase }}</span
            >
          </span>
        </ng-template>
        <div class="uj-flex uj-gap-8 uj-items-center md:uj-ml-auto">
          <div
            *ngIf="totalExpenses.length > 0"
            class="uj-flex uj-gap-4 uj-items-center">
            <unijob-icon
              icon="money"
              color="grey"
              size="16"></unijob-icon>
            <span
              class="text-s-b uj-whitespace-pre"
              *ngFor="let expensesTotal of totalExpenses">
              {{ expensesTotal.value + " " + expensesTotal.currency }}</span
            >
          </div>
          <div
            *ngIf="totalHoursDuration"
            class="uj-flex uj-gap-4 uj-items-center">
            <unijob-icon
              icon="clock_stop_watch"
              color="grey"
              size="16"></unijob-icon>
            <span class="text-s-b uj-whitespace-pre"> {{ totalHours }}</span>
          </div>
        </div>
      </span>
    </div>
    <unijob-icon [icon]="isCollapsed ? 'chevron_down' : 'chevron_up'">
    </unijob-icon>
  </div>
  <div
    class="uj-flex uj-flex-col uj-gap-54 uj-px-20"
    [class.uj-pb-20]="!isCollapsed"
    collapse
    [groupID]="'month-list'"
    #collapseTarget="collapse"
    [collapseSpeed]="300"
    [collapsedSize]="0"
    collapseDirection="vertical"
    [initialCollapse]="initialCollapse">
    <ng-container *ngIf="!isCollapsed">
      <ng-container
        *ngFor="let day of month.days; let i = index; trackBy: trackByDate">
        <ng-container>
          <app-open-day-list-item
            [ngClass]="{ 'uj-pt-20 before:uj-top-0': i === 0 }"
            [class.unijob-divider]="!collapseTarget.isCollapsed && i === 0"
            [class.sort-asc]="sortAsc"
            [isEvenListItem]="i % 2 === 1"
            [employment]="employment"
            [isCompanyUser]="isCompanyUser"
            [inactive]="!!(collapseTarget.collapsedSubject | async)"
            [formReferenceArray]="formReferenceArray"
            [day]="day"></app-open-day-list-item>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
