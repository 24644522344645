import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {
  ActivatedRoute,
  Event,
  Router,
  RouterEvent,
  RoutesRecognized,
} from '@angular/router';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { CanLoadPages, UserService } from './models/shared/user/user.service';
import { VerticalDirection } from './models/types';
import { filter, mergeMap } from 'rxjs/operators';
import { LanguageService } from './core/services/language.service';
import { AuthService } from '@auth0/auth0-angular';
import { GenericError } from '@auth0/auth0-spa-js';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import i18next from 'i18next';
import { CompanyEmploymentService } from './models/uniweb/employment/company-employment.service';
import { SelectOption } from '@intemp/unijob-ui/lib/components/select-input/select-input.component';
import { DocumentEventListenerService, SheetService } from '@intemp/unijob-ui';
import { randomId } from './shared/helpers/functions/randomId';
import {
  GlobalSheetsService,
  GlobalSheetTypeEnum,
} from './shared/modules/global-sheets/global-sheets.service';
import { UserFragment } from './graphql/generated';
import { firstValueFrom } from 'rxjs';
import { FormControl } from '@angular/forms';

export const queryParamNotAffix = '_not';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit {
  isBrowser = false;
  activePath?: string;
  navCollapsed = false;
  navToggleEmitter = new EventEmitter();

  userIsLoggedIn = false;
  userIsVerified = false;

  // scrolling up means header is visible
  lastScrollDirection: VerticalDirection = VerticalDirection.UP;
  verticalDirection = VerticalDirection;

  user?: UserFragment;

  canLoadPages?: CanLoadPages;

  selectedLang = new FormControl(i18next.language.toUpperCase());

  languageOptions: SelectOption[] = [
    { name: 'Francais', value: 'FR' },
    { name: 'English', value: 'EN' },
    { name: 'Deutsch', value: 'DE' },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public userService: UserService,
    private documentEventListenerService: DocumentEventListenerService,
    private viewportScroller: ViewportScroller,
    private authService: AuthService,
    private languageService: LanguageService,
    private companiesService: CompanyEmploymentService,
    private sheetService: SheetService,
    private globalSheetsService: GlobalSheetsService,
    private title: Title,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.updateTitle();
    this.router.events
      .pipe(
        filter(
          (e: Event | RouterEvent): e is RoutesRecognized =>
            e instanceof RoutesRecognized,
        ),
      )
      .subscribe((event) => {
        this.activePath = event.urlAfterRedirects;
      });

    this.selectedLang.valueChanges
      .pipe(filter((lang) => lang !== null))
      .subscribe((lang) => this.setLanguage(lang));
  }

  updateTitle() {
    if (this.isBrowser) {
      // check if document hostUrls contains "hub.universaljob.ch" and if it does, set "hub.universaljob.ch" as title
      if (document.location.host.includes('hub.universaljob.ch')) {
        this.title.setTitle('hub.universaljob.ch');
      }
    }
  }

  async ngOnInit(): Promise<void> {
    let isAlreadyLoggedIn = false;
    await new Promise<void>((resolve) => {
      this.authService.user$.subscribe(async (profile) => {
        const isLoggedIn = profile !== null;
        if (isLoggedIn && isLoggedIn !== isAlreadyLoggedIn) {
          isAlreadyLoggedIn = true;
          this.userIsLoggedIn = true;
          this.userService.authUser$.subscribe(async (authUser) => {
            if (authUser && authUser.isVerified) {
              this.userIsVerified = true;
              await this.getUser();
              this.canLoadPages = await this.userService.getLoadablePages();
              this.languageService.getUserLanguage();
              resolve();
            }
          });
        }
      });
    });
    this.authService.error$
      .pipe(
        filter((e) => {
          // debug problem with force reload
          console.log('auth Error:', e);
          return e instanceof GenericError && e?.error === 'login_required';
        }),
        mergeMap(() => {
          return this.router.navigate(['logout']);
        }),
      )
      .subscribe();

    if (this.route.snapshot.queryParams[`profile`]) {
      this.openProfile();
    }
  }

  ngAfterViewInit(): void {
    this.documentEventListenerService.scrollUpEmitter.subscribe(() => {
      if (this.lastScrollDirection === VerticalDirection.DOWN) {
        this.lastScrollDirection = VerticalDirection.UP;
      }
    });
    this.documentEventListenerService.scrollDownEmitter.subscribe(() => {
      if (
        this.lastScrollDirection === VerticalDirection.UP &&
        this.navCollapsed
      ) {
        this.lastScrollDirection = VerticalDirection.DOWN;
      }
    });
  }

  get currentLang(): string {
    return i18next.language.toUpperCase();
  }

  setLanguage(isoCode: string): void {
    if (this.currentLang !== isoCode) {
      this.languageService.setLanguage(isoCode);
    }
  }

  async getUser(): Promise<void> {
    this.user = await this.userService.getUser();
  }

  navToggled(navCollapsed: boolean): void {
    this.lastScrollDirection = VerticalDirection.UP;
    this.navCollapsed = navCollapsed;
  }

  openProfile() {
    // click on header to close context menu,
    const headerElement = document.getElementById('app-header');
    headerElement?.click();

    this.globalSheetsService.openGlobalSheet({
      type: GlobalSheetTypeEnum.PROFILE_EDIT,
      uuid: randomId(),
    });
  }

  async openSupportSheet() {
    const isCompany = await firstValueFrom(this.userService.userIsCompanyUser$);
    this.globalSheetsService.openGlobalSheet({
      type: isCompany
        ? GlobalSheetTypeEnum.COMPANY_SUPPORT
        : GlobalSheetTypeEnum.EMPLOYEE_SUPPORT,
      uuid: randomId(),
    });
  }
}
