import {
  DocumentStatusEnum,
  DocumentTypeEnum,
  StoredDocumentFragment,
} from 'src/app/graphql/generated';

function docIsUnread(document: StoredDocumentFragment): boolean {
  return document.status === DocumentStatusEnum.UNREAD;
}

function docIsRead(document: StoredDocumentFragment): boolean {
  return document.status === DocumentStatusEnum.READ;
}

export function filterDocuments(
  documents: StoredDocumentFragment[],
  unread: boolean,
  type: DocumentTypeEnum | false,
  excludeTypes: DocumentTypeEnum[] = [],
): StoredDocumentFragment[] {
  if (unread) {
    return documents.filter(
      (doc) =>
        docIsUnread(doc) &&
        (!type || doc.type === type) &&
        !excludeTypes.includes(doc.type),
    );
  } else {
    return documents.filter(
      (doc) =>
        docIsRead(doc) &&
        (!type || doc.type === type) &&
        !excludeTypes.includes(doc.type),
    );
  }
}
