import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { lastValueFrom, Subject } from 'rxjs';
import { parseDuration } from '../../../shared/helpers/functions/parseDuration';
import flatpickr from 'flatpickr';
import { dayIsApprovable } from '../../../shared/helpers/functions/dayIsApprovable';
import { getDaysExpensesTotal } from '../../../shared/helpers/functions/getExpensesTotal';
import { EmploymentService } from '../../../models/uniweb/employment/employment.service';
import { getUnixTime, startOfDay } from 'date-fns';
import { getUtcDate } from '../../../shared/helpers/functions/date-helpers/getUtcDate';
import { parseDayDateString } from '../../../shared/helpers/functions/date-helpers/parseDayDateString';
import {
  DayFragment,
  DayStatusEnum,
  EmploymentInput,
  EmploymentFragment,
  HoursEntryFragment,
  ValueCurrency,
  DayInput,
} from 'src/app/graphql/generated';
import { ModalService, ToastService } from '@intemp/unijob-ui';
import { I18NextPipe } from 'angular-i18next';
import { takeUntil } from 'rxjs/operators';
import { randomId } from '../../../shared/helpers/functions/randomId';

@Component({
  selector: 'app-approve-days-modal',
  templateUrl: './approve-days-modal.component.html',
  styleUrls: ['./approve-days-modal.component.scss'],
})
export class ApproveDaysModalComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<void>();

  @Input({ required: true }) employment!: EmploymentFragment;
  @Input({ required: true }) modalId!: string;
  @Input({ required: true }) toBeApprovedDays!: DayFragment[];
  modals: ModalService;

  approveUntilDateControl = new FormControl<Date[] | null>(null, []);
  approveDaysForm = new FormGroup({
    approveUntilDate: this.approveUntilDateControl,
  });

  selectedDays: DayFragment[] = [];

  selectedAbsencesCount?: number;
  selectEmptyDaysWithoutHours?: number;
  selectedExpensesTotals?: ValueCurrency[];
  formattedHoursTotal?: string;
  approveConfirmModalId = randomId();

  @Output() daysSuccessfullyTransferred = new EventEmitter<boolean>();

  constructor(
    private modalService: ModalService,
    private employmentService: EmploymentService,
    private toastService: ToastService,
    private i18nPipe: I18NextPipe,
  ) {
    this.modals = modalService;
  }

  ngOnInit(): void {
    this.approveDaysForm.controls.approveUntilDate.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((val) => {
        this.dateSelectionChange(val);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openApproveModal(): void {
    const daysToApprove = this.toBeApprovedDays.sort(
      (a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf(),
    );
    const lastDayToApproveDate: string | undefined = daysToApprove[0]?.date;
    setTimeout(() => {
      this.approveDaysForm.controls.approveUntilDate.setValue([
        lastDayToApproveDate === undefined
          ? new Date()
          : new Date(lastDayToApproveDate),
      ]);
    });
  }

  openSubmitConfirmation(): void {
    if (this.approveUntilDateControl.value?.length === 0) {
      this.approveUntilDateControl.setErrors({ required: true });
      return;
    }

    const daysToApproveDays = this.selectedDays.filter((day) =>
      dayIsApprovable(day),
    );
    if (daysToApproveDays?.length && daysToApproveDays.length > 0) {
      this.modals.open(this.approveConfirmModalId);
    } else {
      this.toastService.makeToast({
        message: this.i18nPipe.transform('noDataSelected'),
        type: 'WARNING',
      });
    }
  }

  dateSelectionChange(datePickerValues: Date[] | null): void {
    if (datePickerValues) {
      const datePickerValue = datePickerValues[0];
      if (datePickerValue) {
        const to = startOfDay(getUtcDate(datePickerValue));

        const days = this.getDaysUntilDate(to);
        this.selectedDays = days.filter(
          (day): boolean => day.status === DayStatusEnum.OPEN,
        );
        this.calcTotalValues();
      } else {
        this.selectedDays = [];
        this.selectedAbsencesCount = 0;
        this.selectedExpensesTotals = [];
        this.formattedHoursTotal = '';
      }
    }
  }

  calcTotalValues(): void {
    let selectedHoursTotalDuration = 0;
    let selectedAbsencesCount = 0;
    const selectedExpensesTotals = getDaysExpensesTotal(
      this.selectedDays,
      this.employment,
    );
    let emptyHoursDaysCounter = 0;

    this.selectedDays.forEach((day) => {
      // absences
      if (day.absenceUUID) {
        selectedAbsencesCount++;
      }

      // hours
      if (day?.hoursEntries?.length > 0) {
        const hoursEntries = day.hoursEntries.filter(
          (hoursEntry: HoursEntryFragment) => hoursEntry.duration,
        );
        if (hoursEntries?.length > 0) {
          const totalDuration = hoursEntries
            .map((hoursEntry: HoursEntryFragment) => hoursEntry.duration)
            .reduce((a, b) => a + b);
          selectedHoursTotalDuration += totalDuration;
        }
      } else {
        emptyHoursDaysCounter++;
      }

      this.selectEmptyDaysWithoutHours = emptyHoursDaysCounter;
    });
    if (selectedHoursTotalDuration) {
      this.formattedHoursTotal = parseDuration(selectedHoursTotalDuration / 60);
    } else {
      this.formattedHoursTotal = '';
    }
    this.selectedAbsencesCount = selectedAbsencesCount;
    this.selectedExpensesTotals = selectedExpensesTotals;
  }

  datePickerOnDayCreateFunction(): flatpickr.Options.Hook {
    return (selectedDateObject, selectedDateString, flatpickr, dayElement) => {
      if (this.employment?.days) {
        const date = dayElement.dateObj;
        const employmentDay = this.employment.days?.find(
          (day) =>
            getUnixTime(parseDayDateString(day.date)) === getUnixTime(date),
        );
        if (employmentDay && dayIsApprovable(employmentDay)) {
          dayElement.innerHTML +=
            '<span class="day-with-approvable-data"></span>';
        }
      }
    };
  }

  datePickerDayDisabledFunction(): (day: Date) => boolean {
    return (day): boolean => {
      if (this.employment) {
        const dayData = this.employment.days?.find((employmentDay): boolean => {
          return (
            getUnixTime(parseDayDateString(employmentDay.date)) ===
            getUnixTime(startOfDay(day))
          );
        });
        if (getUnixTime(startOfDay(day)) > getUnixTime(new Date())) {
          return true;
        }
        if (
          getUnixTime(startOfDay(day)) <
          getUnixTime(parseDayDateString(this.employment.startDate))
        ) {
          return true;
        }
        if (!dayData) {
          return false;
        }
        return dayData?.status !== DayStatusEnum.OPEN;
      } else {
        return false;
      }
    };
  }

  submitDays(): void {
    this.modals.close(this.approveConfirmModalId);
    this.modals.close(this.modalId);
    const approveUntilDate =
      this.approveDaysForm.controls.approveUntilDate.value?.[0];
    if (approveUntilDate) {
      this.submitDaysMutation(this.selectedDays).then();
    }
  }

  async submitDaysMutation(days: DayFragment[]): Promise<void> {
    const daysDayInput: DayInput[] = days.map((day) => {
      return {
        status: DayStatusEnum.APPROVED,
        date: day.date,
      };
    });
    const inputData: EmploymentInput = {
      uuid: this.employment.uuid,
      days: daysDayInput,
    };
    await lastValueFrom(this.employmentService.updateEmployment(inputData));
  }

  getDaysUntilDate(date: Date): DayFragment[] {
    return this.employment.days.filter(
      (day) => getUnixTime(parseDayDateString(day.date)) <= getUnixTime(date),
    );
  }

  protected readonly parseDuration = parseDuration;
}
