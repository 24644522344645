import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { fromUnixTime, getUnixTime, startOfMonth } from 'date-fns';
import {
  DayFragment,
  EmploymentFragment,
  DayStatusEnum,
} from 'src/app/graphql/generated';
import { Month } from '../../../../models/types';
import { getUtcUnixTime } from '../../../../shared/helpers/functions/date-helpers/getUtcUnixTime';
import { parseDayDateString } from '../../../../shared/helpers/functions/date-helpers/parseDayDateString';
import { DaysFormArray } from '../../employment-types/employment-form';
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'app-months-list',
  templateUrl: './months-list.component.html',
  styleUrls: ['./months-list.component.scss'],
})
export class MonthsListComponent implements OnChanges {
  @Input({ required: true }) days!: DayFragment[];
  @Input({ required: true }) employment!: EmploymentFragment;
  @Input({ required: true }) formReferenceArray!: DaysFormArray;
  @Input() sortAsc = true;

  months: Month[] = [];
  dayStatusEnum = DayStatusEnum;
  activeMonthStartUnix: number | false = false;
  disableCollapseTimeout?: Timeout;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.days) {
      this.assignDaysToMonths();
    }
    if (changes.sortAsc || changes.days) {
      this.sortDays(this.sortAsc);
    }
  }

  sortDays(asc: boolean) {
    this.months.forEach((month: Month): void => {
      if (asc) {
        month.days.sort(
          (a, b): number =>
            getUnixTime(parseDayDateString(a.date)) -
            getUnixTime(parseDayDateString(b.date)),
        );
      } else {
        month.days.sort(
          (a, b): number =>
            getUnixTime(parseDayDateString(b.date)) -
            getUnixTime(parseDayDateString(a.date)),
        );
      }
      month.weeks = [];
    });
  }

  get displayEndDate(): boolean {
    return !!(
      this.employment?.endDate &&
      getUnixTime(parseDayDateString(this.employment?.endDate)) <
        getUnixTime(new Date())
    );
  }

  assignDaysToMonths(): void {
    this.months = [];
    if (this.days) {
      this.days.sort(
        (a, b): number =>
          getUnixTime(parseDayDateString(b.date)) -
          getUnixTime(parseDayDateString(a.date)),
      );
      let iteratedMonthUnix: number | undefined;
      this.days.forEach((day): void => {
        const dayMonthStartUnix = getUtcUnixTime(
          startOfMonth(parseDayDateString(day.date)),
        );
        if (!iteratedMonthUnix || iteratedMonthUnix !== dayMonthStartUnix) {
          iteratedMonthUnix = dayMonthStartUnix;
          this.months.push({
            days: [day],
            weeks: [],
            startUnix: iteratedMonthUnix,
            startDate: fromUnixTime(iteratedMonthUnix),
          });
        } else {
          this.months[this.months.length - 1].days.push(day);
        }
      });
    }
  }

  trackByMonthStartUnix(index: number, month: Month): number {
    return month.startUnix;
  }

  toggleMonthCollapsed(month: Month): void {
    if (!this.formReferenceArray || this.formReferenceArray.valid) {
      if (this.disableCollapseTimeout) {
        return;
      }
      if (month.startUnix === this.activeMonthStartUnix) {
        this.activeMonthStartUnix = false;
      } else {
        this.activeMonthStartUnix = month.startUnix;
      }

      this.disableCollapseTimeout = setTimeout(
        () => delete this.disableCollapseTimeout,
        200,
      );
    }
  }
}
