import { HoursEntryFragment } from 'src/app/graphql/generated';
import { getDayTimeDifferenceSeconds } from './getDayTimeDifferenceSeconds';
import { parseDuration } from './parseDuration';

export function getHoursEntryPause(
  entryIndex: number,
  hourEntryList: HoursEntryFragment[],
): string | undefined {
  const lastEntry = hourEntryList[entryIndex - 1];
  const currentEntry = hourEntryList[entryIndex];
  if (lastEntry?.to && currentEntry?.from) {
    return parseDuration(
      getDayTimeDifferenceSeconds(lastEntry.to, currentEntry.from) / 60,
      'm',
    );
  }
}
