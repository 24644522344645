import { parseDayTime } from '../parseDayTime';
import { parse } from 'date-fns';
import { format } from 'date-fns-tz';
import {
  dateFnsMilitaryTimeFormat,
  dateFnsInputTimeFormat,
} from './date-variables';

export function getDayTimeNumber(dayTime: string): number {
  const parsedDayTime = parseDayTime(dayTime);
  return parseInt(
    format(
      parse(parsedDayTime, dateFnsInputTimeFormat, new Date()),
      dateFnsMilitaryTimeFormat,
    ),
    10,
  );
}
