import { Address } from 'src/app/graphql/generated';

export function getAddressString(
  address: Pick<Partial<Address>, 'street' | 'zip' | 'location'>,
): string {
  return encodeURI(
    (address?.street + ',' + address?.zip + ',' + address?.location).replace(
      new RegExp(' ', 'g'),
      '+',
    ),
  );
}
