<ng-container
  *ngIf="
    userIsVerified &&
      userIsLoggedIn &&
      activePath &&
      !activePath.startsWith('/login') &&
      !activePath.startsWith('/auth') &&
      activePath.indexOf('refreshNav') < 0;
    else plainTemplate
  ">
  <header
    id="app-header"
    class="app-header"
    [class.collapsed]="lastScrollDirection === verticalDirection.DOWN">
    <div class="header-backdrop"></div>
    <button
      unijob-button
      iconOnly="menu_left"
      variant="tertiary"
      size="m"
      theme="flat"
      contentColor="content-color-dark-grey"
      class="collapse-nav-button"
      (click)="navToggleEmitter.emit()"></button>
    <a
      routerLink="/dashboard"
      class="main-logo-wrapper">
      <img
        src="/assets/logo.svg"
        alt="Logo"
        class="main-logo" />
    </a>
    <div class="uj-ml-auto uj-flex uj-items-center">
      <a
        *ngIf="canLoadPages?.SUPPORT"
        unijob-button
        variant="tertiary"
        iconOnly="question_faq_help_circle"
        theme="flat"
        size="s"
        corner="round"
        bgColor="bg-white"
        contentColor="content-color-dark-grey"
        (click)="openSupportSheet()"
        class="support-button">
      </a>
      <ng-container *ngIf="userService.readyUser$ | async as readyUser">
        <app-user-avatar
          [user]="readyUser"
          size="s"
          [contextMenu]="profile"
          [closeOnClick]="false"
          interactive>
          <ng-container slot="tag">
            <span class="unijob-dot tag-icon color-green uj-border"> </span>
          </ng-container>
        </app-user-avatar>
        <ng-template #profile>
          <div
            class="uj-flex uj-flex-col uj-gap-20 uj-p-20 uj-w-[280px] sm:uj-w-[320px]">
            <div class="uj-flex uj-gap-8 uj-items-center">
              <unijob-avatar
                [content]="readyUser.profile.image ? 'image' : 'icon'"
                [icon]="readyUser.profile.image ? undefined : 'user'"
                [imageUrl]="
                  readyUser.profile.image
                    ? (readyUser.profile.image | storedDocumentUrl)
                    : null
                "
                size="m">
              </unijob-avatar>
              <div>
                <span class="text-m-b">
                  {{
                    readyUser.profile.firstName +
                      " " +
                      readyUser.profile.lastName
                  }}
                </span>
              </div>
            </div>
            <div>
              <p class="text-m-b uj-pb-4">{{ "language" | i18next }}</p>
              <unijob-select
                (click)="$event.stopPropagation()"
                [formControl]="selectedLang"
                [options]="languageOptions"
                elevation="box-outline"
                bgColor="bg-white"
                name="languageSelect"
                size="size-m">
              </unijob-select>
            </div>
            <button
              *ngIf="canLoadPages?.PROFILE"
              class="!uj-w-full"
              unijob-button
              variant="outline"
              theme="flat"
              iconLeft="user_circle"
              size="m"
              corner="rounded"
              bgColor="bg-white"
              contentColor="content-color-dark-grey"
              (click)="openProfile()">
              <div class="collapsible-nav-item-content">
                {{ "profile" | i18next | firstUppercase }}
              </div>
            </button>
            <a
              routerLink="/logout"
              unijob-button
              class="!uj-w-full"
              variant="outline"
              theme="flat"
              iconLeft="log_out"
              size="m"
              corner="rounded"
              bgColor="bg-white"
              contentColor="content-color-dark-grey">
              <div class="collapsible-nav-item-content">
                {{ "logout" | i18next | firstUppercase }}
              </div>
            </a>
          </div></ng-template
        >
      </ng-container>
    </div>
  </header>
  <div class="app-body">
    <app-nav
      [navToggle]="navToggleEmitter"
      (navToggled)="navToggled($event)"></app-nav>
    <div class="app-content-wrapper">
      <div class="sticky-bg"></div>
      <div class="app-content">
        <div class="app-content-container">
          <unijob-toast></unijob-toast>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
  <app-scroll-to-top></app-scroll-to-top>
  <app-global-sheets></app-global-sheets>
  <app-version></app-version>
</ng-container>
<ng-template #plainTemplate>
  <div class="plainToastWrapper">
    <unijob-toast></unijob-toast>
  </div>
  <router-outlet></router-outlet>
</ng-template>
