<unijob-modal
  [id]="modalId"
  size="s"
  [preventClosing]="absenceDurationForm.dirty"
  (closePrevented)="failedToClose()"
  (closeClicked)="emitCloseModal()">
  <ng-container slot="header">
    {{ "absence" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    <div
      *ngIf="modalIsOpen"
      [formGroup]="absenceDurationForm">
      <div class="input-wrap">
        <label class="input-label"
          >{{ "timespan" | i18next | firstUppercase
          }}<span class="red-text">*</span>
        </label>
        <unijob-date-picker
          mode="range"
          formControlName="absenceTimespan"
          [placeholder]="'chooseDateRange' | i18next"
          [dayDisabledFunctions]="[datePickerDayDisabledFunction()]">
        </unijob-date-picker>

        <div
          *ngIf="previousTimeSpanControl.value"
          class="uj-relative uj-mt-4 stroke-content">
          <unijob-date-picker
            mode="range"
            [formControl]="previousTimeSpanControl">
          </unijob-date-picker>
        </div>
      </div>
      <div class="input-wrap">
        <label class="input-label"
          >{{ "absence" | i18next | firstUppercase
          }}<span class="red-text">*</span>
        </label>
        <unijob-select
          [options]="absenceOptions"
          [placeholder]="'chooseAbsence' | i18next | firstUppercase"
          elevation="box-outline"
          allowEmptyValue="true"
          [maxDropdownHeight]="(isSmDown$ | async) ? '200px' : '400px'"
          [showInvalidState]="true"
          formControlName="absenceType"></unijob-select>
        <div
          *ngIf="previousTypeControl.value"
          class="uj-relative uj-mt-4 stroke-content">
          <unijob-select
            [options]="absenceOptions"
            elevation="box-outline"
            allowEmptyValue="true"
            [formControl]="previousTypeControl"></unijob-select>
        </div>
      </div>
      <div class="input-wrap">
        <label class="input-label"
          >{{ "remark" | i18next | firstUppercase }}
        </label>
        <unijob-textarea
          placeholder="Z.B am Morgen krank"
          elevation="outline"
          theme="white"
          formControlName="absenceNote"
          name="absenceNote"
          class="uj-w-full"
          [required]="false"></unijob-textarea>

        <div
          *ngIf="previousNoteControl.value"
          class="uj-relative uj-mt-4 stroke-content text-area">
          <unijob-textarea
            [formControl]="previousNoteControl"
            elevation="outline"
            theme="white"
            class="uj-w-full [&_textarea]:!uj-pb-0"></unijob-textarea>
        </div>
      </div>
      <div class="input-wrap">
        <label class="input-label"
          >{{
            (canEditAbsence ? "addAttachment" : "attachment")
              | i18next
              | firstUppercase
          }}
        </label>
        <ng-container *ngIf="loadingDocument; else fileInput">
          <div class="uj-flex uj-justify-center">
            <app-loading-spinner
              class="uj-block"
              position="static"></app-loading-spinner>
          </div>
        </ng-container>
        <ng-template #fileInput>
          <unijob-input-file
            *ngIf="
              canEditAbsence ||
              (!canEditAbsence && documentFilesControl.value?.length) ||
              (canAdjustAbsence && isAdjustment)
            "
            formControlName="documentFiles"
            inputId="file-input-2"
            name="file"
            [compression]="true"
            [maxCompressionSizeMB]="40"
            [maxFileSize]="320000000"
            (ngModelChange)="fileChanged($event)"
            [uploadLabel]="'chooseFile' | i18next | firstUppercase"
            [uploadTitle]="'dropDocumentHere' | i18next | firstUppercase"
            [errorFileTooLargeMessage]="
              'fileToLargeError' | i18next | firstUppercase
            "
            uploadDescription="ex. pdf, doc, max. 40MB"
            [showDelete]="isOwnEntry && allDaysHaveOpenStatus"
            showDownload
            showInvalidState></unijob-input-file>
          <div
            *ngIf="
              !canEditAbsence &&
              !documentFilesControl.value?.length &&
              !isAdjustment &&
              absence?.createdAt
            "
            class="uj-flex uj-p-[10px] uj-rounded uj-gap-8 uj-bg-surface-light uj-border-[1px] uj-border-border">
            <unijob-icon
              icon="file_document_blank"
              class="uj-icon-grey"
              size="20"></unijob-icon>
            <span class="text-m-b">{{
              "noDocumentUploaded" | i18next | firstUppercase
            }}</span>
          </div>
          <div
            *ngIf="previousDocumentFilesControl.value?.length"
            class="uj-relative uj-mt-4 stroke-content">
            <unijob-input-file
              [formControl]="previousDocumentFilesControl"
              inputId="file-input-2"
              name="file"
              showDownload></unijob-input-file>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="absence && absence.createdAt && !isOwnEntry && !isCompanyUser">
        <span class="text-s uj-text-text-warning-1">
          {{ absence.createdAt | date: "dd.MM.yyyy" }} |
          {{ "adjustedBy" | i18next | firstUppercase }}
          {{
            absence?.createdBy?.firstName + " " + absence?.createdBy?.lastName
          }}
        </span>
      </div>
    </div>
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col sm:uj-flex-row uj-w-full uj-gap-8 uj-justify-end">
      <button
        *ngIf="
          absence?.createdAt &&
          (canEditAbsence || (isCompanyUser && !isOwnEntry))
        "
        (click)="showDeletePrompt()"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        size="m"
        corner="rounded"
        iconLeft="trash"
        contentColor="content-color-dark-grey">
        {{ "delete" | i18next | firstUppercase }}
      </button>
      <button
        *ngIf="canAdjustAbsence && !isAdjustment"
        (click)="addAdjustment()"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        size="m"
        btnColor="bg-pink"
        corner="rounded">
        {{ "edit" | i18next | firstUppercase }}
      </button>
      <button
        *ngIf="!allDaysHaveOpenStatus || (!canEditAbsence && !isCompanyUser)"
        (click)="emitCloseModal()"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        [variant]="isCompanyUser && isAdjustment ? 'outline' : 'primary'"
        theme="flat"
        size="m"
        corner="rounded"
        [btnColor]="
          isCompanyUser && isAdjustment ? 'bg-secondary-dark' : 'bg-pink'
        ">
        {{ (isAdjustment ? "cancel" : "close") | i18next | firstUppercase }}
      </button>
      <button
        *ngIf="
          canEditAbsence ||
          (canAdjustAbsence && isAdjustment && allDaysHaveOpenStatus)
        "
        (click)="saveAbsence()"
        [isDisabled]="absenceDurationForm.invalid"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="primary"
        theme="flat"
        size="m"
        corner="rounded"
        btnColor="bg-pink"
        contentColor="content-color-white">
        {{
          (absence?.createdAt || isAdjustment ? "save" : "createAbsence")
            | i18next
            | firstUppercase
        }}
      </button>
    </div>
  </ng-container>
</unijob-modal>

<unijob-modal
  [id]="unsavedChangesModalId"
  size="s">
  <ng-container slot="header">
    {{ "unsavedChangesTitle" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    {{ "unsavedChangesConfirmation" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col sm:uj-flex-row uj-w-full uj-gap-8 uj-justify-end">
      <button
        (click)="discardChanges(unsavedChangesModalId)"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        size="m"
        iconLeft="trash"
        contentColor="content-color-dark-grey"
        corner="rounded">
        {{ "discardChanges" | i18next | firstUppercase }}
      </button>
      <button
        (click)="saveChanges()"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="primary"
        theme="flat"
        size="m"
        corner="rounded"
        btnColor="bg-pink"
        contentColor="content-color-white">
        {{ "save" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</unijob-modal>

<unijob-modal
  [id]="deleteEntryModalId"
  size="s">
  <ng-container slot="header">
    {{ "deleteEntry" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    {{ "entryDeleteHint" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col sm:uj-flex-row uj-w-full uj-gap-8 uj-justify-end">
      <button
        (click)="discardChanges(deleteEntryModalId)"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        size="m"
        contentColor="content-color-dark-grey"
        corner="rounded">
        {{ "cancel" | i18next | firstUppercase }}
      </button>
      <button
        (click)="deleteEntry()"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        iconLeft="trash"
        type="button"
        variant="primary"
        theme="flat"
        size="m"
        corner="rounded"
        btnColor="bg-pink"
        contentColor="content-color-white">
        {{ "delete" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</unijob-modal>
