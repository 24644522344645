import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageMockService {
  constructor() {}

  defaultLanguage = 'de';
  languageWhitelist = ['en', 'de', 'fr'];

  getUserLanguage(): Observable<string> {
    return of('de');
  }

  getActiveLanguage(): string {
    return 'de';
  }

  async setLanguage(): Promise<void> {}
}
