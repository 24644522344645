import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalService, ResponsiveService } from '@intemp/unijob-ui';
import { EmploymentForCompanyListItemFragment } from 'src/app/graphql/generated';
import { EmploymentView } from '../../../models/types';
import { storedDocumentToJsFile } from '../../../shared/helpers/documents/storedDocumentToJsFile';
import { getAddressString } from '../../../shared/helpers/functions/getAddressString';
import { randomId } from '../../../shared/helpers/functions/randomId';
import {
  GlobalSheetTypeEnum,
  GlobalSheetsService,
} from '../../../shared/modules/global-sheets/global-sheets.service';

@Component({
  selector: 'app-employee-list-item',
  templateUrl: './employee-list-item.component.html',
  styleUrls: [
    './employee-list-item.component.scss',
    '../../employments/employment-list-item/employment-list-item.component.scss',
  ],
})
export class EmployeeListItemComponent implements OnInit, OnDestroy {
  @Input({ required: true }) employment!: EmploymentForCompanyListItemFragment;
  @Input() view: EmploymentView = 'TILES';
  profileImageObjectUrl?: string;
  modals: ModalService;

  infoModalId = randomId();
  isSmDown$ = this.responsiveService.isSmDown$;
  isMdDown$ = this.responsiveService.isMdDown$;

  constructor(
    private modalService: ModalService,
    private responsiveService: ResponsiveService,
    private globalSheetsService: GlobalSheetsService,
  ) {
    this.modals = modalService;
  }

  ngOnInit(): void {
    if (!this.view) {
      this.view = 'TILES';
    }
    if (this.employment?.employee?.image) {
      this.profileImageObjectUrl = URL.createObjectURL(
        storedDocumentToJsFile(this.employment?.employee?.image),
      );
    }
  }

  ngOnDestroy(): void {
    if (this.profileImageObjectUrl) {
      URL.revokeObjectURL(this.profileImageObjectUrl);
    }
  }

  editEmployment(): void {
    this.globalSheetsService.openGlobalSheet({
      type: GlobalSheetTypeEnum.EMPLOYEE_EDIT,
      uuid: this.employment.uuid,
    });
  }

  openDetailsModal(event: Event, id: string): void {
    event.stopPropagation();
    this.modalService.open(id);
  }

  closeModal(id: string): void {
    this.modals.close(id);
  }

  openMap() {
    if (!this.employment.employee?.address) return;
    const addressString = getAddressString(this.employment.employee.address);
    window.open(
      'https://www.google.com/maps/search/' + addressString,
      '_blank',
    );
  }

  navigateToUrl(url: string) {
    window.open(url, '_blank');
  }
}
