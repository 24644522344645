import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DayFragment,
  DurationEntryInput,
  EmploymentFragment,
  EmploymentInput,
  EmploymentSubscribeGQL,
  ExpenseEntryInput,
  GetEmploymentsForEmployeeGQL,
  HoursEntryFragment,
  UpdateDurationEntryGQL,
  UpdateEmploymentGQL,
  UpdateExpenseEntryGQL,
} from 'src/app/graphql/generated';
import { getEmploymentInput } from '../../../shared/helpers/functions/employment-input/getEmploymentInput';

@Injectable({
  providedIn: 'root',
})
export class EmploymentService {
  absenceEntryToEdit$ = new Subject<{ entryId: string; dates: string[] }>();
  reportEntryToEdit$ = new Subject<{ entryId: string; dates?: string[] }>();
  expenseEntryToEdit$ = new Subject<{
    entryId: string;
    date?: string;
    isCompanyUser?: boolean;
  }>();

  constructor(
    private GetEmploymentsForEmployeeGQL: GetEmploymentsForEmployeeGQL,
    private EmploymentSubscribeGQL: EmploymentSubscribeGQL,
    private UpdateDurationEntryGQL: UpdateDurationEntryGQL,
    private UpdateEmploymentGQL: UpdateEmploymentGQL,
    private UpdateExpenseEntryGQL: UpdateExpenseEntryGQL,
  ) {}

  getEmployments$() {
    return this.GetEmploymentsForEmployeeGQL.fetch().pipe(
      map((res) => res.data['Employments']),
    );
  }

  updateEntry(
    employment: EmploymentFragment,
    entry: HoursEntryFragment,
    day: DayFragment,
  ) {
    const inputData = getEmploymentInput({
      employmentUuid: employment.uuid,
      days: [day],
      hoursEntries: [entry],
    });
    return this.updateEmployment(inputData);
  }

  updateEmployment(data: EmploymentInput, multipart = false) {
    return this.UpdateEmploymentGQL.mutate(
      { data },
      {
        context: {
          useMultipart: multipart,
        },
      },
    ).pipe(map((res) => res?.data?.updateEmployment));
  }

  updateDurationEntry(data: DurationEntryInput) {
    return this.UpdateDurationEntryGQL.mutate(
      { data },
      { context: { useMultipart: true } },
    );
  }

  updateExpensesEntry(data: ExpenseEntryInput) {
    return this.UpdateExpenseEntryGQL.mutate(
      { data },
      { context: { useMultipart: true } },
    );
  }

  subscribeEmployment$(
    uuid: string,
  ): Observable<EmploymentFragment | undefined> {
    return this.EmploymentSubscribeGQL.subscribe({ uuid }).pipe(
      map((res) => res.data?.employmentSubscribe),
    );
  }
}
