import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, filter, lastValueFrom, map, Subject } from 'rxjs';
import { EmploymentUpdateData } from '../../../models/types';
import { EmploymentService } from '../../../models/uniweb/employment/employment.service';
import { randomId } from '../../../shared/helpers/functions/randomId';

import {
  ModalService,
  ResponsiveService,
  SheetComponent,
  SheetService,
  ToastService,
  TooltipDirective,
} from '@intemp/unijob-ui';
import { I18NextPipe } from 'angular-i18next';
import { getUnixTime, addMonths } from 'date-fns';
import {
  AbsenceDurationEntryFragment,
  EmploymentFragment,
  EmploymentInput,
  ReportDurationEntryFragment,
} from 'src/app/graphql/generated';
import { getEmploymentInput as convertEmploymentUpdateDataToInput } from 'src/app/shared/helpers/functions/employment-input/getEmploymentInput';
import { DocumentService } from '../../../models/shared/document/document.service';
import { parseDayDateString } from '../../../shared/helpers/functions/date-helpers/parseDayDateString';
import { getLastEditTime } from '../../../shared/helpers/functions/getLastEditTime';
import { logCustomError } from '../../../shared/helpers/functions/logError';
import {
  DayFormGroup,
  DaysFormArray,
} from '../employment-types/employment-form';
import {
  createPendingDays,
  mergeIncomingDays,
} from '../../../shared/helpers/functions/createPendingDays';
import { getEmploymentFormChanges } from '../../../shared/helpers/functions/employment-input/getEmploymentFormChanges';

@Component({
  selector: 'app-employment-edit',
  templateUrl: './employment-edit.component.html',
  styleUrls: ['./employment-edit.component.scss'],
})
export class EmploymentEditComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  sheets: SheetService;

  @Input({ required: true }) employmentId!: string;
  @Input({ required: true }) sheetId!: string;

  @Output() sheetClosed = new EventEmitter<string>();
  isSmDown$ = this.responsiveService.isSmDown$;
  isSmUp$ = this.responsiveService.isSmUp$;

  lastEditTime?: string;
  lastEditTimeMobile?: string;

  isLoading = false;
  isSaving = new BehaviorSubject<boolean>(false);

  hasPermissionToEditHours$ = new BehaviorSubject(true);
  hasReportPermission$ = new BehaviorSubject(true);

  unsavedChangesModalId = 'employment-unsaved-changes-modal';

  sortAsc = false;
  employment?: EmploymentFragment;
  reportModalId = randomId();
  absenceModalId = randomId();
  expensesModalId = randomId();
  editReportDurationEntry?: Partial<ReportDurationEntryFragment>;
  editAbsenceDurationEntry?: Partial<AbsenceDurationEntryFragment>;
  editExpensesEntry?: Partial<EmploymentFragment['expenses'][number]> & {
    expenseDate?: string;
  };

  daysFormArray: DaysFormArray = new FormArray<DayFormGroup>([]);
  employmentEditForm = new FormGroup({
    days: this.daysFormArray,
  });
  modals: ModalService;
  destroyed = new Subject<void>();
  @ViewChild(SheetComponent) sheet?: SheetComponent;
  @ViewChild(TooltipDirective) toolTip?: TooltipDirective;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private employmentService: EmploymentService,
    private documentService: DocumentService,
    private toastService: ToastService,
    private modalService: ModalService,
    private sheetService: SheetService,
    public responsiveService: ResponsiveService,
    private i18nPipe: I18NextPipe,
  ) {
    this.modals = modalService;
    this.sheets = sheetService;
    this.route.queryParams.subscribe((params) => {
      if (params.sortAsc !== undefined) {
        this.sortAsc = params.sortAsc === 'true';
      }
    });
  }

  ngOnInit(): void {
    this.subscribeAbsenceEntry();
    this.subscribeReportEntry();
    this.subscribeToExpensesEntry();
  }

  subscribeEmploymentFormChanges() {
    this.employmentEditForm.valueChanges
      .pipe(
        filter(() => this.employmentEditForm.valid),
        map(() => {
          if (this.employment)
            return getEmploymentFormChanges(
              this.employment,
              this.employmentEditForm,
              this.daysFormArray,
            );
        }),
      )
      .subscribe((updateData) => {
        if (updateData) {
          this.triggerAutoSave(updateData);
        }
      });
  }

  triggerAutoSave(updateData: EmploymentUpdateData) {
    if (this.employmentEditForm.valid) {
      const inputData = convertEmploymentUpdateDataToInput(updateData);
      this.updateEmployment(inputData);
    }
  }

  async ngAfterViewInit() {
    if (!this.employmentId) {
      logCustomError('missing employmentId on employment-edit sheet');
    }
    await this.openSheet();
  }

  async openSheet(): Promise<void> {
    this.sheetService.open(this.sheetId);
    this.isLoading = true;
    this.employmentService.subscribeEmployment$(this.employmentId).subscribe({
      next: (newEmployment) => {
        if (newEmployment) {
          this.setEmployment(newEmployment);
          this.setLastEditTime();
        }
      },
      error: (error) => {
        console.error(error);
        this.toastService.makeToast({
          type: 'ERROR',
          message: this.i18nPipe.transform('errorLoadingEmployment'),
        });
      },
    });
    this.isLoading = false;
    this.subscribeEmploymentFormChanges();
  }

  setEmployment(newEmployment: EmploymentFragment): void {
    const currentEmployment = structuredClone(this.employment);
    this.employment = newEmployment;
    if (newEmployment.isTimeTrackingEnabled) {
      const currentDays = mergeIncomingDays(
        newEmployment.days ?? [],
        currentEmployment?.days ?? [],
      );
      this.employment.days = createPendingDays(newEmployment, currentDays);
    } else {
      this.hasPermissionToEditHours$.next(false);
    }

    if (newEmployment.endDate) {
      const endDate = addMonths(new Date(newEmployment.endDate), 1);
      const nowIsAfterEmploymentEnd = new Date() > endDate;
      if (nowIsAfterEmploymentEnd) {
        this.hasPermissionToEditHours$.next(false);
        this.hasReportPermission$.next(false);
      }
    }
  }

  closeSheet(): void {
    this.sheetClosed.emit(this.sheetId);
  }

  subscribeAbsenceEntry(): void {
    this.employmentService.absenceEntryToEdit$.subscribe(
      ({ entryId, dates }) => {
        if (!this.employment) return;
        const absence = this.employment.absences.find(
          (absence): boolean => absence.uuid === entryId,
        );

        let startDate: string | undefined;
        let endDate: string | undefined;

        if (absence?.startDate) startDate = absence.startDate;
        else startDate = dates[0];

        if (absence?.endDate) endDate = absence.endDate;
        else endDate = dates[1];

        //spread to trigger change detection
        this.editAbsenceDurationEntry = {
          ...absence,
          startDate,
          endDate,
        };
        this.modals.open(this.absenceModalId);
      },
    );
  }

  subscribeReportEntry(): void {
    this.employmentService.reportEntryToEdit$.subscribe(({ entryId }) => {
      if (!this.employment) return;
      const report = this.employment.reports.find(
        (report): boolean => report.uuid === entryId,
      );
      //spread to trigger change detection
      this.editReportDurationEntry = {
        ...report,
      };
      this.modals.open(this.reportModalId);
    });
  }

  subscribeToExpensesEntry(): void {
    this.employmentService.expenseEntryToEdit$.subscribe(
      ({ entryId, date }) => {
        if (!this.employment) return;
        const expensesEntry = this.employment.expenses.find(
          (expense) => expense.uuid === entryId,
        );
        const day = this.employment.days.find((day) => day.date === date);

        //spread to trigger change detection
        this.editExpensesEntry = {
          ...expensesEntry,
          expenseDate: day?.date,
        };

        this.modals.open(this.expensesModalId);
      },
    );
  }

  ngOnDestroy(): void {
    this.destroyed.next();
  }

  discardChanges(): void {
    this.modalService.close(this.unsavedChangesModalId);
    this.sheetService.close(this.sheetId);
  }

  failedToClose(): void {
    this.modalService.open(this.unsavedChangesModalId);
  }

  toggleSort(): void {
    this.sortAsc = !this.sortAsc;

    this.router.navigate([], {
      queryParams: { sortAsc: this.sortAsc },
      queryParamsHandling: 'merge',
    });
    this.sortDays();
  }

  sortDays(): void {
    if (!this.employment) return;
    if (this.sortAsc) {
      this.employment.days?.sort(
        (a, b): number =>
          getUnixTime(parseDayDateString(a.date)) -
          getUnixTime(parseDayDateString(b.date)),
      );
    } else {
      this.employment.days?.sort(
        (a, b): number =>
          getUnixTime(parseDayDateString(b.date)) -
          getUnixTime(parseDayDateString(a.date)),
      );
    }
  }

  updateEmployment(inputData: EmploymentInput): void {
    this.employmentEditForm.markAsPristine();
    this.employmentEditForm.markAsUntouched();
    this.isSaving.next(true);
    lastValueFrom(this.employmentService.updateEmployment(inputData))
      .then(async (res) => {
        setTimeout(() => this.isSaving.next(false), 1000); // do not remove, else the loading spinner will not be shown duo to the fast response
      })
      .catch((error) => {
        this.employmentEditForm.markAsDirty();
        this.employmentEditForm.markAsTouched();
        // error is logged and toast made on apollo level
      });
  }

  closeModal(id: string): void {
    this.modals.close(id);
  }

  setLastEditTime(): void {
    if (!this.employment?.updatedAt) {
      return;
    }
    const { lastEditTime, lastEditTimeMobile } = getLastEditTime(
      new Date(this.employment.updatedAt),
    );
    this.lastEditTime = lastEditTime;
    this.lastEditTimeMobile = lastEditTimeMobile;
  }
}
