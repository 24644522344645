import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
  split,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
import { onError } from '@apollo/client/link/error';
import { logCustomError } from '../shared/helpers/functions/logError';
import { AuthService } from '@auth0/auth0-angular';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { firstValueFrom } from 'rxjs';
import { ToastService } from '@intemp/unijob-ui';
import { I18NextPipe, PipeOptions } from 'angular-i18next';
// @ts-ignore
import extractFiles from 'extract-files/extractFiles.mjs';
// @ts-ignore
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

export function getApolloFactory(uri: string, wsUri: string, token: string) {
  return (
    httpLink: HttpLink,
    authService: AuthService,
    toastService: ToastService,
    i18nPipe: I18NextPipe,
  ): ApolloClientOptions<any> => {
    const basic = setContext((operation, context) => ({}));

    const auth = setContext(async (_, { headers }) => {
      // Grab token if there is one in storage and hasn't expired
      // Login required error on next line (caused by AppComponent.ngOnInit -> getUsers, getCompanies)
      // get Token silently here
      const newToken = await firstValueFrom(
        authService.getAccessTokenSilently(),
      );
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${newToken}`,
          'Apollo-Require-Preflight': 'true',
        },
      };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          logCustomError(message);
          if (
            extensions?.translationKey &&
            typeof extensions.translationKey === 'string'
          ) {
            toastService.makeToast({
              type: 'ERROR',
              message: i18nPipe.transform(
                extensions.translationKey,
                extensions.translationMessageOptions as PipeOptions,
              ),
            });
          } else {
            toastService.makeToast({
              type: 'ERROR',
              message: i18nPipe.transform('serverError'),
            });
          }
        });
      }
      if (networkError && networkError.message !== 'Login required') {
        logCustomError(networkError.message);
        toastService.makeToast({
          type: 'ERROR',
          message: i18nPipe.transform('serverError'),
        });
      }
    });

    const linkForWs = new GraphQLWsLink(
      createClient({
        url: wsUri,
        connectionParams: async () => {
          const newToken = await firstValueFrom(
            authService.getAccessTokenSilently(),
          );
          return {
            Authorization: `Bearer ${newToken}`,
          };
        },
      }),
    );

    const linkForHttp = ApolloLink.from([
      errorLink,
      basic,
      auth,
      httpLink.create({
        uri,
        // @ts-ignore
        extractFiles: (body) => extractFiles(body, isExtractableFile),
      }),
    ]);

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      linkForWs,
      linkForHttp,
    );

    const cache = new InMemoryCache({
      addTypename: false,
      typePolicies: {
        User: {
          // In most inventory management systems, a single UPC code uniquely
          // identifies any product.
          keyFields: ['_id'],
        },
        PersonalContactInformation: {
          merge: false,
        },
      },
    });

    return {
      link: splitLink,
      cache,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
        },
        query: {
          fetchPolicy: 'no-cache',
        },
      },
    };
  };
}

@NgModule({
  imports: [ApolloModule],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class UniBaseXGraphQLModule {
  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
    authService: AuthService,
    toastService: ToastService,
    i18nPipe: I18NextPipe,
  ) {
    firstValueFrom(authService.getAccessTokenSilently()).then((token) => {
      apollo.create(
        getApolloFactory(
          environment.gqlApiXUrl,
          environment.gqlApiXWsUrl,
          token,
        )(httpLink, authService, toastService, i18nPipe),
      );
    });
  }
}
