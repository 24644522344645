<div class="uj-flex uj-flex-col uj-gap-8">
  <div class="uj-flex">
    <div
      class="uj-flex uj-relative"
      [class.stroke-content]="strokeContent">
      <div>
        <unijob-input
          [ngModel]="from"
          [strokeDisabled]="strokeContent"
          [disabled]="!canAddAdjustment || isCompanyUser"
          placeholder="0:00"
          elevation="box-outline"
          inputType="text"
          textAlign="text-center"
          fontWeight="font-bold"
          [showClearButton]="false"
          positionInGroup="first"
          bgColor="bg-white"></unijob-input>
      </div>
      <div class="uj-relative">
        <div
          *ngIf="isNightShift"
          class="night-shift-indicator">
          <unijob-icon
            class="night-shift-icon-normal"
            size="20"
            icon="moon"
            color="grey">
          </unijob-icon>
          <unijob-icon
            class="night-shift-icon-small"
            size="16"
            icon="moon"
            color="grey">
          </unijob-icon>
        </div>
        <unijob-input
          [ngModel]="to"
          [strokeDisabled]="strokeContent"
          [disabled]="!canAddAdjustment || isCompanyUser"
          placeholder="0:00"
          elevation="box-outline"
          inputType="text"
          textAlign="text-center"
          fontWeight="font-bold"
          [showClearButton]="false"
          positionInGroup="middle-border-none"
          bgColor="bg-white"></unijob-input>
      </div>
      <div class="uj-flex">
        <unijob-input
          [ngModel]="pause"
          [strokeDisabled]="strokeContent"
          [disabled]="!canAddAdjustment || isCompanyUser"
          placeholder="0m"
          elevation="box-outline"
          inputType="text"
          textAlign="text-center"
          fontWeight="font-bold"
          [showClearButton]="false"
          positionInGroup="middle"
          bgColor="bg-white"></unijob-input>
      </div>
    </div>
    <div class="uj-flex">
      <button
        *ngIf="!(isMobileView$ | async)"
        [ngClass]="entry.note ? 'custom-note-dot' : ''"
        [isDisabled]="!entry?.note"
        [iconOnly]="
          entry?.note?.length
            ? 'document_note_paper_filled'
            : 'document_note_paper_blank'
        "
        (click)="openNoteModal()"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        size="m"
        positionInGroup="middle-border-none"
        contentColor="content-color-black"></button>

      <button
        *ngIf="
          (canAddAdjustment || dayStatus === DayStatusEnum.APPROVED) &&
          !(isMobileView$ | async)
        "
        [isDisabled]="!canAddAdjustment || dayStatus === DayStatusEnum.APPROVED"
        (click)="addAdjustment()"
        iconOnly="edit_pen_1"
        class="!uj-w-full sm:!uj-w-auto"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        positionInGroup="last"
        size="m"></button>

      <button
        *ngIf="
          !isCompanyUser &&
          dayStatus !== DayStatusEnum.APPROVED &&
          !(isMobileView$ | async)
        "
        [isDisabled]="!canAddAdjustment"
        iconOnly="trash"
        class="!uj-w-full sm:!uj-w-auto input-delete"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        size="m"
        positionInGroup="last"
        contentColor="content-color-dark-grey"></button>
      <!-- Mobile-->
      <button
        *ngIf="isMobileView$ | async"
        [ngClass]="entry.note ? 'custom-note-dot' : ''"
        [isDisabled]="!entry?.note"
        iconOnly="menu_dots_vertical"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap input-delete"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        size="m"
        positionInGroup="last-border-none"
        contentColor="content-color-dark-grey"
        [contextMenu]="moreActionsButton"
        contextMenuBgColor="bg-white"></button>
      <ng-template #moreActionsButton>
        <div class="uj-flex uj-flex-col uj-gap-4">
          <button
            [iconLeft]="'visability_eye'"
            (click)="openNoteModal()"
            class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
            unijob-button
            variant="quaternary"
            theme="flat"
            size="m"
            contentColor="content-color-black"
            corner="rounded">
            {{ "note" | i18next | firstUppercase }}
          </button>

          <button
            *ngIf="canAddAdjustment || dayStatus === DayStatusEnum.APPROVED"
            [isDisabled]="
              !canAddAdjustment || dayStatus === DayStatusEnum.APPROVED
            "
            [iconLeft]="'edit_pen_1'"
            (click)="addAdjustment()"
            class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
            unijob-button
            variant="quaternary"
            theme="flat"
            size="m"
            contentColor="content-color-black"
            corner="rounded">
            {{ "edit" | i18next | firstUppercase }}
          </button>
        </div>
      </ng-template>
    </div>
  </div>

  <app-hours-row
    *ngFor="
      let previousVersion of entry.previousVersions;
      let i = index;
      trackBy: trackByUUID
    "
    [entry]="previousVersionTypeCast(previousVersion)"
    [strokeContent]="true"
    [isCompanyUser]="isCompanyUser"
    [dayStatus]="dayStatus"
    class="previous-version"></app-hours-row>
</div>

<div *ngIf="!isOwnEntry || entry.previousVersions?.length">
  <span class="text-s uj-text-text-warning-2">
    {{ entry.createdAt | date: "dd.MM.yyyy" }} |
    {{ "adjustedBy" | i18next | firstUppercase }}
    {{ entry?.createdBy?.firstName + " " + entry?.createdBy?.lastName }}
  </span>
</div>

<unijob-modal
  *ngIf="noteModalId"
  [id]="noteModalId"
  size="s"
  (closeClicked)="closeNoteModal()">
  <ng-container slot="header">
    {{ "note" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    <unijob-textarea
      [clearable]="false"
      disabled="true"
      [placeholder]="'addHoursRowNote' | i18next | firstUppercase"
      elevation="outline"
      theme="white"
      [ngModel]="entry!.note"
      name="hourNote"
      class="uj-w-full"></unijob-textarea>
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col sm:uj-flex-row uj-w-full uj-gap-8 uj-justify-end">
      <button
        (click)="closeNoteModal()"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="primary"
        theme="flat"
        size="m"
        corner="rounded"
        btnColor="bg-pink"
        contentColor="content-color-white">
        {{ "close" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</unijob-modal>
