<div
  class="backdrop"
  (click)="closeMobileNav()"></div>
<button
  unijob-button
  variant="outline"
  theme="elevated"
  iconOnly="chevron_left"
  corner="round"
  class="nav-collapse-button"
  size="s"
  [class.rotate]="navCollapsed"
  (click)="toggleNavCollapsed()"></button>
<nav [class.collapsed]="navCollapsed">
  <a
    *ngIf="!navCollapsed"
    routerLink="/dashboard"
    (click)="closeMobileNav()"
    class="uj-px-12 uj-pb-20 md:uj-hidden">
    <img
      src="/assets/logo.svg"
      alt="Logo"
      class="uj-max-h-[20px]" />
  </a>
  <ul class="primary-nav">
    <li *ngIf="canLoadPages?.DASHBOARD">
      <a
        routerLink="/dashboard"
        routerLinkActive="active"
        (click)="closeMobileNav()">
        <unijob-icon icon="home"></unijob-icon>
        <div class="collapsible-nav-item-content">
          {{ "dashboard" | i18next | firstUppercase }}
        </div>
      </a>
    </li>
    <li *ngIf="canLoadPages?.EMPLOYMENTS">
      <a
        routerLink="/employments"
        routerLinkActive="active"
        (click)="closeMobileNav()">
        <unijob-icon icon="clipboard_empty"></unijob-icon>
        <div class="collapsible-nav-item-content">
          {{ "employments" | i18next | firstUppercase }}
        </div>
      </a>
    </li>
    <li *ngIf="canLoadPages?.EMPLOYEES">
      <a
        routerLink="/employees"
        routerLinkActive="active"
        (click)="closeMobileNav()">
        <unijob-icon icon="users_group"></unijob-icon>
        <div class="collapsible-nav-item-content">
          {{ "employees" | i18next | firstUppercase }}
        </div>
        <span
          *ngIf="companyService.hasDaysToApprove.value"
          class="collapsible-nav-item-content unijob-dot flashing"></span>
      </a>
    </li>
    <li
      *ngIf="
        docStatus?.unread?.all ||
        docStatus?.read?.all ||
        canLoadPages?.DOCUMENTS
      "
      routerLinkActive="active">
      <a
        routerLink="/documents"
        routerLinkActive="active"
        (click)="closeMobileNav()">
        <unijob-icon icon="file_document_blank"></unijob-icon>
        <span
          *ngIf="docStatus?.unread?.all"
          class="collapsed-only-dot unijob-dot flashing"></span>
        <div class="collapsible-nav-item-content">
          {{ "documents" | i18next | firstUppercase }}
        </div>
        <span
          *ngIf="docStatus?.unread?.all"
          class="collapsible-nav-item-content unijob-dot flashing"></span>
      </a>
    </li>
    <li *ngIf="canLoadPages?.CONTACT">
      <a
        routerLink="/contact"
        routerLinkActive="active"
        (click)="closeMobileNav()">
        <unijob-icon icon="phone"></unijob-icon>
        <div class="collapsible-nav-item-content">
          {{ "contact" | i18next | firstUppercase }}
        </div>
      </a>
    </li>
    <li
      *ngIf="canLoadPages?.BENEFITS"
      (click)="updateUser()">
      <a
        routerLink="/benefits"
        routerLinkActive="active"
        (click)="closeMobileNav()">
        <unijob-icon icon="discount"></unijob-icon>
        <span
          *ngIf="hasUnseenBenefits"
          class="collapsed-only-dot unijob-dot flashing"></span>
        <div class="collapsible-nav-item-content">
          {{ "promos" | i18next | firstUppercase }}
        </div>
        <span
          *ngIf="hasUnseenBenefits"
          class="unijob-dot flashing"></span>
      </a>
    </li>
    <li *ngIf="canLoadPages?.EXPORT">
      <a
        routerLink="/export"
        routerLinkActive="active"
        (click)="closeMobileNav()">
        <unijob-icon icon="download_arrow"></unijob-icon>
        <div class="collapsible-nav-item-content">
          {{ "export" | i18next | firstUppercase }}
        </div>
      </a>
    </li>
  </ul>
  <div
    class="build-infos"
    *ngIf="!navCollapsed && buildInfo"
    [tooltipTemplate]="buildInfoTooltip">
    {{ mainBuildInfo }}
    <ng-container *ngIf="buildInfo.env !== 'prod'">
      <span class="env-tag">{{ buildInfo.env }}</span>
    </ng-container>
  </div>
  <ng-template #buildInfoTooltip>
    <div class="build-info-tooltip">
      <p>Commit: {{ buildInfo.commit }}</p>
      <p>Tag: {{ buildInfo.tag }}</p>
      <p>Branch: {{ buildInfo.branch }}</p>
      <p>Env: {{ buildInfo.env }}</p>
    </div>
  </ng-template>
</nav>
