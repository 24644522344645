import { NgModule } from '@angular/core';
import { SharedDefaultModule } from '../../shared/modules/shared-default/shared-default.module';

import { RouterLink } from '@angular/router';
import {
  ButtonModule,
  CollapseModule,
  ContextMenuModule,
  DatePickerModule,
  InfoBoxModule,
  InputFileModule,
  InputTextareaModule,
  InputTextModule,
  ModalModule,
  SheetModule,
  TextButtonModule,
  TooltipModule,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { SharedFormsModule } from '../../shared/modules/shared-forms/shared-forms.module';
import { EmployeeListItemComponent } from '../employees/employee-list-item/employee-list-item.component';
import { EmploymentOverviewComponent } from '../employees/employment-overview/employment-overview.component';
import { AbsenceDurationModalComponent } from './absence-duration-modal/absence-duration-modal.component';
import { ApprovedDayComponent } from './employment-edit/approved-day/approved-day.component';
import { EmploymentEditComponent } from './employment-edit/employment-edit.component';
import { MonthListItemComponent } from './employment-edit/months-list/month-list-item/month-list-item.component';
import { MonthsListComponent } from './employment-edit/months-list/months-list.component';
import { DayInfoComponent } from './employment-edit/open-day/day-info/day-info.component';
import { EditHoursRowComponent } from './employment-edit/open-day/edit-hours-row/edit-hours-row.component';
import { OpenDayComponent } from './employment-edit/open-day/open-day.component';
import { HoursRowComponent } from './employment-edit/transferred-day/hours-row/hours-row.component';
import { EmploymentListItemComponent } from './employment-list-item/employment-list-item.component';
import { EmploymentStatisticsComponent } from './employment-statistics/employment-statistics.component';
import { ExpensesModalComponent } from './expenses-modal/expenses-modal.component';
import { ReportDurationModalComponent } from './report-duration-modal/report-duration-modal.component';
import { ApproveDaysModalComponent } from './approve-days-modal/approve-days-modal.component';

@NgModule({
  declarations: [
    OpenDayComponent,
    EditHoursRowComponent,
    HoursRowComponent,
    EmploymentStatisticsComponent,
    ReportDurationModalComponent,
    MonthsListComponent,
    DayInfoComponent,
    AbsenceDurationModalComponent,
    ExpensesModalComponent,
    ApproveDaysModalComponent,
    MonthListItemComponent,
    EmployeeListItemComponent,
    EmploymentOverviewComponent,
    EmploymentEditComponent,
    EmploymentListItemComponent,
    ApprovedDayComponent,
  ],
  imports: [
    SharedDefaultModule,
    SharedFormsModule,
    TooltipModule,
    ModalModule,
    ButtonModule,
    CollapseModule,
    InputTextareaModule,
    UjSelectInputModule,
    SheetModule,
    RouterLink,
    InputTextModule,
    DatePickerModule,
    InputFileModule,
    InfoBoxModule,
    ContextMenuModule,
    TextButtonModule,
  ],
  exports: [
    ApprovedDayComponent,
    EmploymentStatisticsComponent,
    MonthsListComponent,
    ReportDurationModalComponent,
    AbsenceDurationModalComponent,
    ExpensesModalComponent,
    SharedDefaultModule,
    ApproveDaysModalComponent,
    SharedFormsModule,
    EmployeeListItemComponent,
    EmploymentOverviewComponent,
    EmploymentEditComponent,
    EmploymentListItemComponent,
    UjSelectInputModule,
  ],
})
export class EmploymentBaseModule {}
