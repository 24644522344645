import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AvatarSize } from '@intemp/unijob-ui/lib/components/avatar/avatar.component';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { storedDocumentToJsFile } from '../../helpers/documents/storedDocumentToJsFile';
import { UserFragment } from 'src/app/graphql/generated';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent {
  objectUrl?: string;

  @Input() set user(user: UserFragment | undefined) {
    if (!user) return;
    this._user = user;
    if (user.profile?.image) {
      this.objectUrl = URL.createObjectURL(
        storedDocumentToJsFile(user.profile.image),
      );
    }
  }
  get user(): UserFragment | undefined {
    return this._user;
  }
  _user?: UserFragment;
  @Input() onlyFirstName = false;
  @Input() size: AvatarSize = 'm';

  @Input()
  get interactive() {
    return this._interactive;
  }
  set interactive(value: BooleanInput) {
    this._interactive = coerceBooleanProperty(value);
  }
  _interactive = false;
}
