import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalSheetsComponent } from './global-sheets.component';
import { EmploymentBaseModule } from '../../../pages/employments/employments.base.module';
import { SupportModule } from '../../../pages/support/support.module';
import { ProfileModule } from '../../../pages/profile/profile.module';

@NgModule({
  declarations: [GlobalSheetsComponent],
  exports: [GlobalSheetsComponent],
  imports: [CommonModule, EmploymentBaseModule, SupportModule, ProfileModule],
})
export class GlobalSheetsModule {}
