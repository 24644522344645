import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ModalService,
  ResponsiveService,
  SheetComponent,
  SheetService,
  ToastService,
} from '@intemp/unijob-ui';
import { I18NextPipe } from 'angular-i18next';
import { BehaviorSubject, filter, map, lastValueFrom } from 'rxjs';
import {
  AbsenceDurationEntryFragment,
  DayFragment,
  EmploymentFragment,
  EmploymentInput,
  ReportDurationEntryFragment,
} from 'src/app/graphql/generated';
import { getEmploymentInput } from 'src/app/shared/helpers/functions/employment-input/getEmploymentInput';
import { getDaysToApprove } from 'src/app/shared/helpers/functions/getDaysToApprove';
import { DocumentService } from '../../../models/shared/document/document.service';
import { EmploymentUpdateData } from '../../../models/types';
import { CompanyEmploymentService } from '../../../models/uniweb/employment/company-employment.service';
import { EmploymentService } from '../../../models/uniweb/employment/employment.service';
import { getLastEditTime } from '../../../shared/helpers/functions/getLastEditTime';
import { logCustomError } from '../../../shared/helpers/functions/logError';
import { randomId } from '../../../shared/helpers/functions/randomId';
import {
  DayFormGroup,
  DaysFormArray,
  EmploymentEditForm,
} from '../../employments/employment-types/employment-form';
import {
  createPendingDays,
  mergeIncomingDays,
} from '../../../shared/helpers/functions/createPendingDays';
import { getEmploymentFormChanges } from '../../../shared/helpers/functions/employment-input/getEmploymentFormChanges';
import { addMonths } from 'date-fns';

@Component({
  selector: 'app-employment-overview',
  templateUrl: './employment-overview.component.html',
  styleUrls: ['./employment-overview.component.scss'],
})
export class EmploymentOverviewComponent implements OnInit, AfterViewInit {
  sheets: SheetService;
  @Input({ required: true }) employmentId!: string;
  @Input({ required: true }) sheetId!: string;
  @Output() sheetClosed = new EventEmitter<string>();

  isSmDown$ = this.responsiveService.isSmDown$;
  isLoading = false;

  modals: ModalService;
  employment?: EmploymentFragment;
  toBeApprovedDays: DayFragment[] = [];
  sortAsc = false;

  editReportDurationEntry?: Partial<ReportDurationEntryFragment>;
  editAbsenceDurationEntry?: Partial<AbsenceDurationEntryFragment>;
  editExpensesEntry?: Partial<EmploymentFragment['expenses'][number]> & {
    expenseDate?: string;
  };

  isSmUp$ = this.responsiveService.isSmUp$;

  hasPermissionToEdit$ = new BehaviorSubject(true);

  approveModalId = randomId();
  expensesModalId = randomId();
  reportModalId = randomId();
  absenceModalId = randomId();

  @ViewChild(SheetComponent) sheet?: SheetComponent;

  daysFormArray: DaysFormArray = new FormArray<DayFormGroup>([]);
  employmentEditForm: EmploymentEditForm = new FormGroup({
    days: this.daysFormArray,
  });

  lastEditTime?: string;
  lastEditTimeMobile?: string;

  isSaving = new BehaviorSubject<boolean>(false);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private employmentService: EmploymentService,
    private documentService: DocumentService,
    private companyService: CompanyEmploymentService,
    private modalService: ModalService,
    private sheetService: SheetService,
    public responsiveService: ResponsiveService,
    private i18nPipe: I18NextPipe,
  ) {
    this.modals = modalService;
    this.sheets = sheetService;
    this.route.queryParams.subscribe((params) => {
      if (params.sortAsc !== undefined) {
        this.sortAsc = params.sortAsc === 'true';
      }
    });
  }

  ngOnInit(): void {
    this.subscribeAbsenceEntry();
    this.subscribeReportEntry();
    this.subscribeToExpensesEntry();
  }

  subscribeEmploymentFormChanges() {
    this.employmentEditForm.valueChanges
      .pipe(
        filter(() => this.employmentEditForm.valid),
        map(() => {
          if (this.employment)
            return getEmploymentFormChanges(
              this.employment,
              this.employmentEditForm,
              this.daysFormArray,
            );
        }),
      )
      .subscribe((updateData) => {
        if (updateData) {
          this.triggerAutoSave(updateData);
        }
      });
  }

  triggerAutoSave(updateData: EmploymentUpdateData) {
    if (this.employmentEditForm.valid) {
      const inputData = getEmploymentInput(updateData);
      this.updateEmployment(inputData);
    }
  }

  subscribeAbsenceEntry(): void {
    this.employmentService.absenceEntryToEdit$.subscribe(
      ({ entryId, dates }) => {
        if (!this.employment) return;
        const absence = this.employment.absences.find(
          (absence): boolean => absence.uuid === entryId,
        );
        //spread to trigger change detection
        let startDate: string | undefined;
        let endDate: string | undefined;

        if (absence?.startDate) startDate = absence.startDate;
        else startDate = dates[0];

        if (absence?.endDate) endDate = absence.endDate;
        else endDate = dates[1];

        //spread to trigger change detection
        this.editAbsenceDurationEntry = {
          ...absence,
          startDate,
          endDate,
        };
        this.modals.open(this.absenceModalId);
      },
    );
  }

  subscribeReportEntry(): void {
    this.employmentService.reportEntryToEdit$.subscribe(
      ({ entryId, dates }) => {
        if (!this.employment) return;
        const report = this.employment.reports.find(
          (report): boolean => report.uuid === entryId,
        );
        //spread to trigger change detection
        this.editReportDurationEntry = {
          ...report,
        };
        this.modals.open(this.reportModalId);
      },
    );
  }

  subscribeToExpensesEntry(): void {
    this.employmentService.expenseEntryToEdit$.subscribe(
      ({ entryId, date }) => {
        if (!this.employment) return;
        const expensesEntry = this.employment.expenses.find(
          (expense) => expense.uuid === entryId,
        );
        const day = this.employment.days.find((day) => day.date === date);

        //spread to trigger change detection
        this.editExpensesEntry = {
          ...expensesEntry,
          expenseDate: day?.date,
        };

        this.modals.open(this.expensesModalId);
      },
    );
  }

  async ngAfterViewInit() {
    if (!this.employmentId) {
      logCustomError('missing employeeId on employment-overview sheet');
    }
    await this.openSheet();
  }

  async openSheet(): Promise<void> {
    this.sheetService.open(this.sheetId);
    this.isLoading = true;
    this.employmentService.subscribeEmployment$(this.employmentId).subscribe({
      next: (newEmployment) => {
        if (newEmployment) {
          this.setEmployment(newEmployment);
          this.setLastEditTime();
        }
      },
      error: (error) => {
        console.error(error);
        this.toastService.makeToast({
          type: 'ERROR',
          message: this.i18nPipe.transform('errorLoadingEmployment'),
        });
      },
    });
    this.isLoading = false;
    this.subscribeEmploymentFormChanges();
  }

  setEmployment(newEmployment: EmploymentFragment): void {
    const currentEmployment = structuredClone(this.employment);
    this.employment = newEmployment;
    if (newEmployment.isTimeTrackingEnabled) {
      const currentDays = mergeIncomingDays(
        newEmployment.days ?? [],
        currentEmployment?.days ?? [],
      );
      this.employment.days = createPendingDays(newEmployment, currentDays);
    }

    if (newEmployment.endDate) {
      const endDate = addMonths(new Date(newEmployment.endDate), 1);
      const nowIsAfterEmploymentEnd = new Date() > endDate;
      if (nowIsAfterEmploymentEnd) {
        this.hasPermissionToEdit$.next(false);
      }
    }

    this.toBeApprovedDays = getDaysToApprove(this.employment.days);

    this.companyService.hasDaysToApprove.next(this.toBeApprovedDays.length > 0);
    this.companyService.updateHasDaysToApprove.next({
      uuid: this.employmentId,
      hasDaysToApprove: this.toBeApprovedDays.length > 0,
      daysToApproveCount: this.toBeApprovedDays.length,
    });
  }

  closeSheet(): void {
    this.sheetClosed.emit(this.sheetId);
  }

  updateEmployment(inputData: EmploymentInput): void {
    this.employmentEditForm.markAsPristine();
    this.employmentEditForm.markAsUntouched();
    this.isSaving.next(true);
    lastValueFrom(this.employmentService.updateEmployment(inputData))
      .then(async (res: any) => {
        setTimeout(() => this.isSaving.next(false), 1000); //do not remove, else the loading spinner will not be shown duo to the fast response
      })
      .catch((error) => {
        this.employmentEditForm.markAsDirty();
        this.employmentEditForm.markAsTouched();
        // error is logged and toast made on apollo level
      });
  }

  toggleSort(): void {
    this.sortAsc = !this.sortAsc;
    this.router.navigate([], {
      queryParams: { sortAsc: this.sortAsc },
      queryParamsHandling: 'merge',
    });
  }

  setLastEditTime(): void {
    if (!this.employment?.updatedAt) {
      return;
    }
    const { lastEditTime, lastEditTimeMobile } = getLastEditTime(
      new Date(this.employment.updatedAt),
    );
    this.lastEditTime = lastEditTime;
    this.lastEditTimeMobile = lastEditTimeMobile;
  }

  closeModal(id: string): void {
    this.modals.close(id);
  }
}
