import { CurrencyEnum, ValueCurrency } from 'src/app/graphql/generated';

export function parseValueCurrency(valueCurrency: string): ValueCurrency {
  valueCurrency = valueCurrency.replace(new RegExp(' ', 'g'), '');
  valueCurrency = valueCurrency.toUpperCase();
  valueCurrency = valueCurrency.replace(',', '.');
  let currency: CurrencyEnum;
  let value = parseFloat(
    valueCurrency.replace(new RegExp('[^0-9.,]', 'g'), ''),
  );

  if (isNaN(value)) {
    value = 0;
  }
  if (
    valueCurrency.indexOf('EUR') !== -1 ||
    valueCurrency.indexOf('EURO') !== -1 ||
    valueCurrency.indexOf('€') !== -1 ||
    valueCurrency.indexOf('E') !== -1 ||
    valueCurrency.indexOf('EU') !== -1
  ) {
    currency = CurrencyEnum.EUR;
  } else if (
    valueCurrency.indexOf('USD') !== -1 ||
    valueCurrency.indexOf('$') !== -1 ||
    valueCurrency.indexOf('dollar') !== -1 ||
    valueCurrency.indexOf('us dollar') !== -1
  ) {
    currency = CurrencyEnum.USD;
  } else {
    currency = CurrencyEnum.CHF;
  }
  return {
    value,
    currency,
  };
}
