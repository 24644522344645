import { Environment } from './environment-type';

export const environment: Environment = {
  environment: 'DEMO',
  production: true,
  auth0ApiUrl: 'authdemo.universaljob.ch',
  auth0ClientId: 'nFYwMWna4tsLZMhiqMtU2WI5JGoKnhJa',
  audience: 'https://demo.api.my.universaljob.ch',
  gqlApiXUrl: 'https://demo.api.my.universaljob.ch/graphql',
  gqlApiXWsUrl: 'wss://demo.api.my.universaljob.ch/graphql',
  universaljobHost: 'https://demo.universaljob.ch',
  ownHost: 'https://demo.my.universaljob.ch',
};
