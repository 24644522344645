<div class="text-xs-b uj-text-text-grey">
  {{ ("isoWeek" | i18next | firstUppercase) + " " + isoWeek }}
</div>
<div
  *ngIf="day"
  class="uj-flex uj-gap-4">
  <div class="title-xs">{{ day.date | date: "E" | uppercase }}</div>
  <div class="text-l">{{ day.date | date: "mediumDate" }}</div>
  <div class="uj-flex uj-items-center uj-cursor-auto">
    <unijob-icon
      *ngIf="day.status === dayStatusEnum.APPROVED"
      icon="check"
      class="uj-text-icon-success"
      size="16"
      [tooltip]="'approved' | i18next"></unijob-icon>
    <unijob-icon
      *ngIf="day.status === dayStatusEnum.HAS_REPORT"
      icon="file_document_blank"
      class="uj-icon-grey"
      size="16"
      [tooltip]="'reportState' | i18next"></unijob-icon>
    <unijob-icon
      *ngIf="entryWasAdjusted && day.status === dayStatusEnum.OPEN"
      icon="edit_pen_1"
      class="uj-text-icon-warning-2"
      size="16"
      [tooltip]="'entryWasAdjusted' | i18next"></unijob-icon>

    <span
      *ngIf="hoursEntryAreNotEmpty"
      [tooltip]="'approveDay' | i18next"
      class="unijob-dot color-pink"></span>
  </div>
</div>
