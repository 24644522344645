import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-scrollable-form-error',
  templateUrl: './scrollable-form-error.component.html',
  styleUrls: ['./scrollable-form-error.component.scss'],
})
export class ScrollableFormErrorComponent {
  @Input({ required: true }) targetFormControl!: AbstractControl | FormGroup;

  constructor() {}
}
