import { NgModule } from '@angular/core';
import { SharedDefaultModule } from '../../shared/modules/shared-default/shared-default.module';

import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedFormsModule } from '../../shared/modules/shared-forms/shared-forms.module';
import {
  AvatarModule,
  ButtonModule,
  CheckboxModule,
  IconModule,
  InputFileModule,
  InputTextModule,
  ModalModule,
  SheetModule,
  TextButtonModule,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { EmploymentBaseModule } from '../employments/employments.base.module';

@NgModule({
  declarations: [ProfileComponent],
  imports: [
    SheetModule,
    SharedDefaultModule,
    ProfileRoutingModule,
    SharedFormsModule,
    ReactiveFormsModule,
    ButtonModule,
    AvatarModule,
    InputTextModule,
    IconModule,
    CheckboxModule,
    UjSelectInputModule,
    EmploymentBaseModule,
    ModalModule,
    InputFileModule,
    TextButtonModule,
  ],
  exports: [ProfileComponent],
})
export class ProfileModule {}
