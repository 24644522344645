<unijob-sheet
  [id]="sheetId"
  [size]="'l'"
  [sideClose]="!(isSmDown$ | async)"
  [preventClosing]="!!employmentEditForm?.dirty"
  (closePrevented)="failedToClose()"
  (sheetClosed)="closeSheet()">
  <ng-container slot="header">
    <ng-container *ngIf="!isLoading && employment; else noInput">
      <div
        class="uj-flex uj-justify-between uj-items-center uj-w-full uj-gap-8">
        <div class="uj-flex-2 uj-truncate uj-w-0 uj-flex-1">
          <h1 class="title-m uj-truncate">
            {{ employment.company?.contact?.name }}
          </h1>
        </div>
        <div class="uj-flex uj-items-center uj-justify-end">
          <span
            class="text-m uj-px-8 uj-whitespace-pre"
            *ngIf="!(isSmDown$ | async)"
            >{{ "daySort" | i18next | firstUppercase }}:</span
          >
          <button
            unijob-button
            (click)="toggleSort()"
            [iconOnly]="sortAsc ? 'sort_ascending' : 'sort_descending'"
            type="button"
            variant="outline"
            theme="flat"
            size="m"
            corner="rounded"
            contentColor="content-color-dark-grey"></button>
        </div>
      </div>
      <div
        *ngIf="lastEditTime"
        class="uj-flex uj-gap-4">
        <div class="uj-flex uj-items-center">
          <span
            *ngIf="!(isSaving | async)"
            class="unijob-tag tag-icon color-green size-xs">
            <unijob-icon
              size="8"
              icon="check"></unijob-icon>
          </span>
          <unijob-icon
            *ngIf="isSaving | async"
            class="uj-animate-spin uj-text-success-green-450"
            size="12"
            icon="loading_spin"></unijob-icon>
        </div>
        <span class="text-s uj-hidden sm:uj-block">
          {{ lastEditTime }}
        </span>
        <span class="text-s uj-block sm:uj-hidden">
          {{ lastEditTimeMobile }}
        </span>
      </div>
    </ng-container>
  </ng-container>
  <ng-container slot="content">
    <ng-container *ngIf="!isLoading && employment; else noInput">
      <form [formGroup]="employmentEditForm">
        <div
          class="uj-grid uj-grid-cols-1 uj-gap-20 md:uj-grid-cols-[1fr_195px]">
          <div class="uj-order-2 md:uj-order-1">
            <app-months-list
              [days]="employment.days"
              [sortAsc]="sortAsc"
              [employment]="employment"
              [formReferenceArray]="daysFormArray"></app-months-list>
          </div>
          <div
            [ngStyle]="{
              'max-height': !(isSmDown$ | async) ? 'calc(100vh - 135px)' : null,
              'min-height': !(isSmDown$ | async) ? 'calc(100vh - 135px)' : null
            }"
            class="uj-order-1 md:uj-order-2 uj-relative md:uj-sticky md:uj-top-0">
            <div class="uj-flex uj-flex-col uj-gap-20 uj-h-full">
              <div
                *ngIf="
                  (hasReportPermission$ | async) ||
                  (hasPermissionToEditHours$ | async)
                "
                class="uj-flex uj-justify-between sm:uj-justify-start uj-flex-row md:uj-flex-col uj-gap-8">
                <button
                  *ngIf="hasReportPermission$ | async"
                  (click)="modals.open(this.reportModalId)"
                  iconLeft="upload_cloud"
                  class="!uj-w-full sm:!uj-w-auto md:!uj-justify-start sm:uj-flex-1 md:uj-flex-none"
                  unijob-button
                  type="button"
                  variant="primary"
                  theme="flat"
                  size="m"
                  corner="rounded"
                  btnColor="bg-violet"
                  contentColor="content-color-white">
                  {{ "report" | i18next | firstUppercase }}
                </button>
                <ng-container *ngIf="hasPermissionToEditHours$ | async">
                  <button
                    *ngIf="isSmUp$ | async"
                    (click)="modals.open(this.expensesModalId)"
                    iconLeft="money"
                    class="!uj-w-full sm:!uj-w-auto md:!uj-justify-start sm:uj-flex-1 md:uj-flex-none"
                    unijob-button
                    type="button"
                    variant="outline"
                    theme="flat"
                    size="m"
                    corner="rounded">
                    {{ "expenses" | i18next | firstUppercase }}
                  </button>

                  <button
                    *ngIf="isSmUp$ | async"
                    (click)="modals.open(this.absenceModalId)"
                    iconLeft="travel_airplane_holiday"
                    class="!uj-w-full sm:!uj-w-auto md:!uj-justify-start sm:uj-flex-1 md:uj-flex-none"
                    unijob-button
                    type="button"
                    variant="outline"
                    theme="flat"
                    size="m"
                    corner="rounded">
                    {{ "absence" | i18next | firstUppercase }}
                  </button>
                  <!-- More button-->
                  <button
                    *ngIf="!(isSmUp$ | async)"
                    [contextMenu]="moreMobileButton"
                    horizontalAlignment="center"
                    contextMenuBgColor="bg-white"
                    unijob-button
                    type="button"
                    variant="primary"
                    theme="flat"
                    size="m"
                    corner="rounded"
                    iconOnly="menu_dots_vertical"
                    btnColor="bg-violet"
                    contentColor="content-color-white"></button>
                  <ng-template #moreMobileButton>
                    <div class="uj-flex uj-flex-col uj-gap-4">
                      <button
                        (click)="modals.open(this.expensesModalId)"
                        iconLeft="money"
                        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
                        unijob-button
                        type="button"
                        variant="quaternary"
                        theme="flat"
                        size="m"
                        contentColor="content-color-black"
                        corner="rounded">
                        {{ "expenses" | i18next | firstUppercase }}
                      </button>

                      <button
                        (click)="modals.open(this.absenceModalId)"
                        iconLeft="travel_airplane_holiday"
                        class="!uj-w-full sm:!uj-w-auto"
                        unijob-button
                        type="button"
                        variant="quaternary"
                        theme="flat"
                        size="m"
                        contentColor="content-color-black"
                        corner="rounded">
                        {{ "absence" | i18next | firstUppercase }}
                      </button>
                    </div>
                  </ng-template>
                </ng-container>
              </div>
              <app-employment-statistics
                *ngIf="employment"
                [employment]="employment"></app-employment-statistics>
            </div>
          </div>
        </div>

        <app-report-duration-modal
          *ngIf="reportModalId && employment"
          [modalId]="reportModalId"
          (modalClosed)="closeModal(this.reportModalId)"
          [employment]="employment"
          [report]="editReportDurationEntry"></app-report-duration-modal>

        <app-absence-duration-modal
          *ngIf="absenceModalId && employment"
          [modalId]="absenceModalId"
          (modalClosed)="closeModal(this.absenceModalId)"
          [employment]="employment"
          [absence]="editAbsenceDurationEntry"></app-absence-duration-modal>

        <app-expenses-modal
          *ngIf="expensesModalId && employment"
          [modalId]="expensesModalId"
          (modalClosed)="closeModal(this.expensesModalId)"
          [employment]="employment"
          [expensesEntry]="editExpensesEntry"></app-expenses-modal>
      </form>
    </ng-container>
    <ng-template #noInput>
      <app-loading-spinner></app-loading-spinner>
    </ng-template>
  </ng-container>
</unijob-sheet>
