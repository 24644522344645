<div
  (click)="editEmployment()"
  class="unijob-card interactable uj-flex"
  [ngClass]="{
    'uj-flex-row size-sm': view === 'LIST',
    'uj-flex-col size-md': view === 'TILES',
    'uj-gap-[57px]': view === 'TILES' && !employment.hasDaysToApprove,
    'uj-gap-[32px]': view === 'TILES' && employment.hasDaysToApprove
  }"
  [class]="view.toLowerCase() + '-view'">
  <div
    *ngIf="employment && employment.employee"
    [ngClass]="
      view === 'LIST'
        ? 'uj-flex-row uj-flex-1 uj-gap-12 '
        : 'uj-flex-col uj-gap-8'
    "
    class="uj-flex uj-justify-start uj-items-center">
    <unijob-avatar
      *ngIf="employment.employee.image; else lettersAvatar"
      interactive="false"
      content="image"
      [imageUrl]="employment.employee.image | storedDocumentUrl"
      [size]="view === 'TILES' ? 'm' : 's'"
      backgroundColor="grey">
    </unijob-avatar>
    <ng-template #lettersAvatar>
      <unijob-avatar
        interactive="false"
        content="letters"
        [letters]="employment.employee.firstName | slice: 0 : 1"
        [size]="view === 'TILES' ? 'm' : 's'"
        backgroundColor="grey">
      </unijob-avatar>
    </ng-template>
    <div
      [ngClass]="
        view === 'LIST'
          ? 'uj-flex-col sm:uj-flex-row uj-gap-4 sm:uj-gap-12'
          : 'uj-flex-col uj-gap-8 uj-w-full'
      "
      class="uj-flex uj-w-0 uj-max-w-fit uj-flex-1">
      <div
        [class.uj-text-center]="view === 'TILES'"
        [class.uj-text-left]="view === 'LIST'"
        class="title-s uj-text-text uj-truncate"
        [attr.title]="employment.company?.contact?.name">
        {{ employment?.employee?.lastName }}
        {{ employment?.employee?.firstName }}
      </div>
      <span
        class="uj-flex uj-gap-4 uj-text-text-accent uj-items-center uj-truncate"
        *ngIf="employment.hasDaysToApprove">
        <unijob-icon
          icon="clock_check"
          size="16"></unijob-icon>
        <span
          [class.text-s-b]="isSmDown$ | async"
          class="text-m-b uj-truncate"
          >{{
            employment.daysToApproveCount && employment.daysToApproveCount > 1
              ? ("xDaysToApprove"
                | i18next: { amount: employment.daysToApproveCount }
                | firstUppercase)
              : ("1DayToApprove" | i18next | firstUppercase)
          }}</span
        >
      </span>
    </div>
  </div>
  <div
    [ngClass]="view === 'LIST' ? 'uj-flex-row' : 'uj-flex-col uj-gap-8'"
    class="uj-flex uj-justify-center uj-items-center">
    <button
      unijob-button
      [class.uj-mb-12]="view === 'TILES'"
      [class.uj-mr-8]="view === 'LIST'"
      type="button"
      variant="quaternary"
      class="open-info-modal-button"
      size="m"
      [iconLeft]="view === 'LIST' ? undefined : 'info_circle'"
      [iconOnly]="view === 'LIST' ? 'info_circle' : undefined"
      contentColor="content-color-violet"
      (click)="openDetailsModal($event, infoModalId)">
      {{ "showDetails" | i18next | firstUppercase }}
    </button>
    <button
      unijob-button
      type="button"
      variant="primary"
      theme="flat"
      size="m"
      corner="rounded"
      iconLeft="clock_stop_watch"
      [iconOnly]="
        view === 'LIST' && (isSmDown$ | async) ? 'clock_stop_watch' : undefined
      "
      btnColor="bg-violet"
      class="uj-justify-center">
      <span class="hours-button-text">
        {{ "manageEntries" | i18next | firstUppercase }}
      </span>
    </button>
  </div>
</div>

<unijob-modal
  [id]="infoModalId"
  size="s">
  <ng-container slot="header">
    <span>
      {{ employment.employee?.firstName }} {{ employment.employee?.lastName }}
    </span>
  </ng-container>
  <ng-container slot="content">
    <div class="uj-flex uj-flex-col uj-gap-4">
      <div class="title-xxs">
        {{ "address" | i18next | firstUppercase }}
      </div>
      <div
        class="uj-flex uj-flex-col"
        *ngIf="employment.employee && employment.employee.address">
        <span>{{ employment.employee.address.street }}</span>
        <span>{{ employment.employee.address.zip }}</span>
        <span>{{ employment.employee.address.location }}</span>
      </div>
    </div>
    <div class="uj-flex uj-flex-col uj-gap-4 uj-mt-20">
      <button
        (click)="openMap()"
        unijob-button
        type="button"
        variant="outline"
        iconLeft="map"
        theme="flat"
        size="m"
        corner="rounded"
        bgColor="bg-white"
        contentColor="content-color-violet"
        class="map-button !uj-w-full">
        {{ "showMap" | i18next | firstUppercase }}
      </button>
      <button
        *ngIf="employment?.employee?.phone"
        (click)="navigateToUrl('tel:' + employment?.employee?.phone)"
        class="!uj-w-full"
        unijob-button
        type="button"
        variant="outline"
        iconLeft="phone"
        theme="flat"
        size="m"
        corner="rounded"
        bgColor="bg-white"
        contentColor="content-color-violet">
        {{ "call" | i18next | firstUppercase }}
      </button>

      <button
        *ngIf="employment?.employee?.email"
        (click)="navigateToUrl('mailto:' + employment?.employee?.email)"
        class="!uj-w-full"
        unijob-button
        type="button"
        variant="outline"
        iconLeft="mail"
        theme="flat"
        size="m"
        corner="rounded"
        bgColor="bg-white"
        contentColor="content-color-violet">
        {{ "write" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
  <ng-container slot="footer">
    <button
      unijob-button
      type="button"
      variant="primary"
      theme="flat"
      size="m"
      corner="rounded"
      btnColor="bg-pink"
      class="modal-close-button"
      (click)="closeModal(infoModalId)">
      {{ "close" | i18next | firstUppercase }}
    </button>
  </ng-container>
</unijob-modal>
