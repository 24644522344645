<div class="uj-flex uj-gap-12">
  <span
    class="unijob-divider size-xs border-color-grey uj-pt-12"
    *ngIf="dayIsEmpty; else showEntries">
    <div class="caption color-grey">
      <span class="text-s">{{ "noEntries" | i18next | firstUppercase }}</span>
    </div>
  </span>
  <ng-template #showEntries>
    <div
      class="uj-flex-1 uj-flex uj-flex-col uj-gap-8 sm:uj-pr-[52px]"
      *ngIf="!day?.reportUUID; else showReport">
      <div *ngIf="day.hoursEntries.length > 0">
        <ng-container
          *ngFor="let entry of sortedHoursEntries.hoursEntry; let i = index">
          <ng-container *ngIf="sortedHoursEntries.pauseEntry[i]">
            <div class="uj-pb-8">
              <span class="unijob-divider size-xs border-color-grey">
                <div class="caption color-grey">
                  <unijob-icon
                    size="16"
                    icon="coffee"></unijob-icon>
                  <span class="text-xs"
                    >{{ "pause" | i18next | firstUppercase }}
                    {{ sortedHoursEntries.pauseEntry[i] }}</span
                  >
                </div>
              </span>
            </div>
          </ng-container>
          <div class="uj-flex uj-pb-8">
            <app-hours-row
              [entry]="entry"
              [isCompanyUser]="isCompanyUser"
              [dayStatus]="day.status"
              [canAddAdjustment]="
                isCompanyUser && day.status !== dayStatusEnum.OPEN
              "></app-hours-row>
          </div>
        </ng-container>
      </div>
      <div class="uj-flex uj-flex-col uj-gap-8">
        <div
          *ngIf="day.absenceUUID"
          class="uj-pt-12">
          <div class="uj-flex">
            <button
              (click)="editAbsence(day.absenceUUID)"
              class="!uj-w-full flex-1"
              unijob-button
              type="button"
              variant="outline"
              theme="flat"
              size="m"
              corner="rounded"
              iconLeft="travel_airplane_holiday">
              {{ "absence" | i18next | firstUppercase }}
            </button>
          </div>
        </div>
        <div
          *ngIf="day.expenseUUID"
          class="uj-pt-12">
          <div class="uj-flex">
            <button
              (click)="editExpense(day.expenseUUID)"
              class="!uj-w-full flex-1"
              unijob-button
              type="button"
              variant="outline"
              theme="flat"
              size="m"
              corner="rounded"
              [positionInGroup]="
                day.status === dayStatusEnum.OPEN && isCompanyUser
                  ? 'first'
                  : 'none'
              "
              iconLeft="money">
              {{ "expenses" | i18next | firstUppercase }}
            </button>
            <button
              *ngIf="day.expenseUUID && isCompanyUser && day.status === dayStatusEnum.OPEN"
              (click)="editExpense(day.expenseUUID)"
              unijob-button
              iconOnly="edit_pen_1"
              class="uj-w-auto uj-whitespace-nowrap"
              type="button"
              variant="outline"
              theme="flat"
              corner="rounded"
              positionInGroup="last"
              size="m"></button>
          </div>
        </div>
      </div></div
  ></ng-template>

  <ng-template #showReport>
    <div
      *ngIf="day && day.reportUUID"
      class="uj-flex-1 uj-flex uj-flex-col uj-pt-8 sm:uj-pr-[52px]">
      <button
        (click)="editReport(day.reportUUID)"
        class="!uj-w-full uj-flex-1"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        size="m"
        corner="rounded"
        iconLeft="file_document_text">
        {{ "displayReport" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-template>
</div>
