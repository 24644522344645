import { logCustomError } from './logError';

export type DefaultDurationFormat = 'm' | 'h' | 'auto';
// takes a string or number in any format and tries to parse a format (like 5h or 7h 30m)
// The defaultFormat option defines if durationInput without any unit should be read in minutes or hours
export function parseDuration(
  durationInput: string | number,
  defaultFormat: DefaultDurationFormat = 'm',
): string {
  if (!durationInput) return '';
  if (typeof durationInput !== 'string') {
    durationInput = durationInput.toString();
  }
  if (durationInput === '0') {
    return '0h';
  }

  let negativeNumberPrefix;
  const originalInput = durationInput;

  if (durationInput[0] === '-') {
    negativeNumberPrefix = '-';
    durationInput = durationInput.slice(1, durationInput.length);
  } else {
    negativeNumberPrefix = '';
  }

  let hours;
  let minutes;

  durationInput = durationInput.replace(' ', '');

  // "-" is alias for :
  durationInput = durationInput.replace('-', ':');

  // remove unexpected characters
  durationInput = durationInput.replace(new RegExp('([^0-9.,:mh])', 'g'), '');

  // replace , with .
  durationInput = durationInput.replace(',', '.');

  // remove multiple decimal separators
  durationInput = durationInput.replace(
    new RegExp('([.,].*?)[,.].*', 'g'),
    '$1',
  );

  // remove multiple hour indicators
  durationInput = durationInput.replace(
    new RegExp('([.,h:].*?)[.,h:].*', 'g'),
    '$1',
  );

  // remove multiple minute indicators
  durationInput = durationInput.replace(
    new RegExp('([m:,.].*?)[m:,.].*', 'g'),
    '$1',
  );

  // contains ":"  (12:30 => 12h 30m)
  if (durationInput.indexOf(':') !== -1) {
    const separatorIndex = durationInput.indexOf(':');
    hours = parseInt(durationInput.slice(0, separatorIndex), 10);
    minutes = parseInt(
      durationInput.slice(separatorIndex + 1, durationInput.length),
      10,
    );
    return negativeNumberPrefix + makeReadableDurationString(hours, minutes);
  }

  // contains "."  (2.5 => 2h 30m)
  if (durationInput.indexOf('.') !== -1) {
    const separatorIndex = durationInput.indexOf('.');
    hours = parseInt(durationInput.slice(0, separatorIndex), 10);
    minutes =
      60 *
      parseFloat(
        '0.' + durationInput.slice(separatorIndex + 1, durationInput.length),
      );
    return negativeNumberPrefix + makeReadableDurationString(hours, minutes);
  }

  // has h as separator (3h 30/3h 30m)
  if (durationInput.indexOf('h') !== -1) {
    // remove m since h is the separator
    durationInput = durationInput.replace('m', '');

    const separatorIndex = durationInput.indexOf('h');
    hours = parseInt(durationInput.slice(0, separatorIndex), 10);
    minutes = parseInt(
      durationInput.slice(separatorIndex + 1, durationInput.length),
      10,
    );
    return negativeNumberPrefix + makeReadableDurationString(hours, minutes);
  }

  // defines minutes only
  if (durationInput.indexOf('m') !== -1 || defaultFormat === 'm') {
    durationInput = durationInput.replace('m', '');
    const minuteVal = parseInt(durationInput, 10);
    hours = Math.floor(minuteVal / 60);
    minutes = Math.floor(minuteVal % 60);
    return negativeNumberPrefix + makeReadableDurationString(hours, minutes);
  }

  if (defaultFormat === 'h') {
    return (
      negativeNumberPrefix + makeReadableDurationString(durationInput, '0')
    );
  }
  if (defaultFormat === 'auto') {
    if (parseInt(durationInput, 10) >= 5) {
      return (
        negativeNumberPrefix + makeReadableDurationString('0', durationInput)
      );
    } else {
      return (
        negativeNumberPrefix + makeReadableDurationString(durationInput, '0')
      );
    }
  }
  logCustomError('could not parse timeInput' + originalInput);
  return negativeNumberPrefix + durationInput;
}

function makeReadableDurationString(
  hours: string | number,
  minutes: string | number,
): string {
  const hoursInt = parseInt(hours.toString(), 10);
  const minutesInt = parseInt(minutes.toString(), 10);
  let readableString = '';
  if (hoursInt > 0) {
    readableString += hours + 'h';
    if (minutesInt > 0) {
      readableString += ' ';
    }
  }
  if (minutesInt > 0) {
    readableString += minutes + 'm';
  }
  return readableString;
}
