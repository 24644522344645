import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAvatarComponent } from './user-avatar.component';
import { AvatarModule } from '@intemp/unijob-ui';
import { SharedDefaultModule } from '../shared-default/shared-default.module';

@NgModule({
  declarations: [UserAvatarComponent],
  imports: [CommonModule, AvatarModule, SharedDefaultModule],
  exports: [UserAvatarComponent],
})
export class UserAvatarModule {}
