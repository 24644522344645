import { User } from '@auth0/auth0-spa-js';

export class AuthUserModel {
  user: User | null;

  constructor(decodedUserToken: User) {
    this.user = adaptUser(decodedUserToken);
  }

  get userId(): string {
    return this.user?.userId;
  }

  get isVerified(): boolean {
    return !!this.userId;
  }

  get emailVerified(): boolean {
    return !!this.user?.email_verified;
  }
}

function adaptUser(item: any): User | null {
  if (!item) {
    return null;
  }

  let namespace = 'https://universaljob.ch/';
  if (!item.hasOwnProperty(namespace + 'email')) {
    namespace = '';
  }
  item.userId = item[namespace + 'userId'];
  item.email = item[namespace + 'email'];
  item.email_verified = item[namespace + 'email_verified'];

  return item;
}
