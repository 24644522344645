<div class="uj-flex uj-flex-col uj-gap-8">
  <div class="uj-flex uj-flex-col uj-gap-2">
    <div
      class="uj-flex"
      [ngClass]="{
        'uj-outline-border-error':
          editEntryFormGroup.invalid ||
          (fromControl.invalid && toControl.invalid && pauseControl.invalid),
        'uj-outline-system-success':
          (fromControl.valid &&
            fromControl.dirty &&
            toControl.valid &&
            toControl.dirty &&
            pauseControl.valid &&
            editEntryFormGroup.valid) ||
          (pauseControl.valid &&
            pauseControl.dirty &&
            editEntryFormGroup.valid),
        'uj-outline uj-outline-1 uj-outline-offset-2 uj-rounded-sm uj-mb-2 uj-mt-2':
          !oneOfTheInputsIsFocused &&
          ((fromControl.valid &&
            fromControl.dirty &&
            toControl.valid &&
            toControl.dirty) ||
            (pauseControl.valid &&
              editEntryFormGroup.controls.pause.dirty &&
              editEntryFormGroup.valid) ||
            editEntryFormGroup.invalid ||
            (fromControl.invalid && toControl.invalid && pauseControl.invalid))
      }">
      <div>
        <unijob-input
          [ngModel]="fromInput$ | async"
          placeholder="0:00"
          elevation="box-outline"
          inputType="text"
          textAlign="text-center"
          fontWeight="font-bold"
          (focusin)="setFocused()"
          (focusout)="setUnfocused()"
          (change)="updateInput('from', $event)"
          [showClearButton]="false"
          [showInvalidState]="
            editEntryFormGroup.errors?.message?.key === 'missingFromTime'
          "
          positionInGroup="first"
          bgColor="bg-white"></unijob-input>
      </div>
      <div class="uj-relative">
        <div
          *ngIf="isNightShift"
          class="night-shift-indicator">
          <unijob-icon
            class="night-shift-icon-normal"
            size="20"
            icon="moon"
            color="grey">
          </unijob-icon>
          <unijob-icon
            class="night-shift-icon-small"
            size="16"
            icon="moon"
            color="grey">
          </unijob-icon>
        </div>
        <unijob-input
          [ngModel]="toInput$ | async"
          placeholder="0:00"
          elevation="box-outline"
          inputType="text"
          textAlign="text-center"
          fontWeight="font-bold"
          (focusin)="setFocused()"
          (focusout)="setUnfocused()"
          (change)="updateInput('to', $event)"
          [showClearButton]="false"
          [showInvalidState]="
            !(fromControl.invalid && toControl.invalid && pauseControl.invalid)
          "
          positionInGroup="middle-border-none"
          bgColor="bg-white"></unijob-input>
      </div>
      <div class="uj-flex">
        <unijob-input
          [ngModel]="pauseInput$ | async"
          placeholder="0m"
          elevation="box-outline"
          inputType="text"
          textAlign="text-center"
          fontWeight="font-bold"
          (focusin)="setFocused()"
          (focusout)="setUnfocused()"
          (change)="updateInput('pause', $event)"
          [showClearButton]="false"
          [showInvalidState]="
            !(fromControl.invalid && toControl.invalid && pauseControl.invalid)
          "
          positionInGroup="middle"
          bgColor="bg-white"></unijob-input>
      </div>
      <div class="uj-flex">
        <button
          *ngIf="!(isMobileView$ | async)"
          [isDisabled]="isDisabled || canAddAdjustment"
          (click)="openNoteModal()"
          [ngClass]="entry.note ? 'custom-note-dot' : ''"
          [iconOnly]="
            editEntryFormGroup.controls.note.value
              ? 'document_note_paper_filled'
              : 'document_note_paper_blank'
          "
          class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
          unijob-button
          type="button"
          variant="outline"
          theme="flat"
          size="m"
          positionInGroup="middle-border-none"
          contentColor="content-color-dark-grey"></button>
        <button
          *ngIf="!canAddAdjustment && !(isMobileView$ | async)"
          [isDisabled]="(entriesAreEmpty$ | async) || isDisabled"
          (click)="deleteEntry()"
          iconOnly="trash"
          class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap input-delete"
          unijob-button
          type="button"
          variant="outline"
          theme="flat"
          size="m"
          positionInGroup="last"
          contentColor="content-color-dark-grey"></button>

        <!-- Mobile-->
        <button
          *ngIf="isMobileView$ | async"
          iconOnly="menu_dots_vertical"
          class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap input-delete"
          [ngClass]="entry.note ? 'custom-note-dot' : ''"
          unijob-button
          type="button"
          variant="outline"
          theme="flat"
          size="m"
          positionInGroup="last-border-none"
          contentColor="content-color-dark-grey"
          [contextMenu]="moreActionsButton"
          contextMenuBgColor="bg-white"></button>
        <ng-template #moreActionsButton>
          <div class="uj-flex uj-flex-col uj-gap-4">
            <button
              [iconLeft]="
                entry.note
                  ? 'document_note_paper_filled'
                  : 'document_note_paper_blank'
              "
              (click)="openNoteModal()"
              class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
              unijob-button
              variant="quaternary"
              theme="flat"
              size="m"
              contentColor="content-color-black"
              fontWeight="regular"
              corner="rounded">
              {{
                (entry.note ? "editNote" : "addNote") | i18next | firstUppercase
              }}
              <span
                *ngIf="entry.note"
                class="unijob-dot tag-icon color-pink uj-border uj-self-center uj-ml-8">
              </span>
            </button>
            <button
              [iconLeft]="'trash'"
              [isDisabled]="(entriesAreEmpty$ | async) || isDisabled"
              (click)="deleteEntry()"
              class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
              unijob-button
              variant="quaternary"
              theme="flat"
              size="m"
              fontWeight="regular"
              contentColor="content-color-black"
              corner="rounded">
              {{ "deleteEntry" | i18next | firstUppercase }}
            </button>
          </div>
        </ng-template>
      </div>
    </div>
    <div
      *ngIf="
        !oneOfTheInputsIsFocused &&
        (editEntryFormGroup.errors ||
          fromControl.errors ||
          toControl.errors ||
          pauseControl.errors)
      "
      class="uj-flex uj-gap-4 uj-items-center uj-text-icon-error">
      <unijob-icon
        icon="alert_triangle"
        size="12"></unijob-icon>
      <div class="text-s">
        {{
          editEntryFormGroup.errors?.message?.key ||
            fromControl.errors?.message?.key ||
            toControl.errors?.message?.key ||
            pauseControl.errors?.message?.key
            | i18next
            | firstUppercase
        }}
      </div>
    </div>
    <unijob-modal
      [id]="noteModalId"
      size="s"
      (closeClicked)="modals.close(noteModalId)">
      <ng-container slot="header">
        {{ "note" | i18next | firstUppercase }}
      </ng-container>
      <ng-container slot="content">
        <div class="input-wrap">
          <unijob-textarea
            [clearable]="false"
            [placeholder]="'addHoursRowNote' | i18next | firstUppercase"
            elevation="outline"
            theme="white"
            [ngModel]="noteInput$ | async"
            name="hourNote"
            class="uj-w-full"
            (change)="updateInput('note', $event)"
            [required]="false"></unijob-textarea>
        </div>
      </ng-container>
      <ng-container slot="footer">
        <div
          class="uj-flex uj-flex-col sm:uj-flex-row uj-w-full uj-gap-8 uj-justify-end">
          <button
            (click)="modals.close(noteModalId)"
            class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
            unijob-button
            type="button"
            variant="primary"
            theme="flat"
            size="m"
            corner="rounded"
            btnColor="bg-pink"
            contentColor="content-color-white">
            {{ "close" | i18next | firstUppercase }}
          </button>
        </div>
      </ng-container>
    </unijob-modal>
  </div>
  <app-hours-row
    *ngFor="
      let previousVersion of entry.previousVersions;
      let i = index;
      trackBy: trackByUUID
    "
    [entry]="previousVersionTypeCast(previousVersion)"
    [strokeContent]="true"></app-hours-row>
</div>

<unijob-modal
  [id]="deleteModalId"
  size="s"
  (closeClicked)="modals.close(deleteModalId)">
  <ng-container slot="header">
    {{ "entryDeleteConfirmation" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    <span> {{ "entryDeleteHint" | i18next | firstUppercase }}</span>
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col sm:uj-flex-row uj-w-full uj-gap-8 uj-justify-end">
      <button
        (click)="modals.close(deleteModalId)"
        class="!uj-w-full sm:!uj-w-auto"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        size="m"
        corner="rounded"
        contentColor="content-color-dark-grey">
        {{ "cancel" | i18next | firstUppercase }}
      </button>
      <button
        (click)="removeEntry.emit(); modals.close(deleteModalId)"
        class="!uj-w-full sm:!uj-w-auto"
        unijob-button
        type="button"
        variant="primary"
        theme="flat"
        size="m"
        iconLeft="trash"
        corner="rounded"
        btnColor="bg-pink"
        contentColor="content-color-white">
        {{ "delete" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</unijob-modal>
