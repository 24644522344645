import {
  DayFragment,
  EmploymentFragment,
  HoursEntryFragment,
} from '../graphql/generated';
import { DocumentTypeEnum } from 'src/app/graphql/generated';

export enum VerticalDirection {
  UP = 'UP',
  DOWN = 'DOWN',
}

export interface AuthUser {
  id: string;
  expires: number;
  email: string;
  emailVerified?: boolean;
  userId?: string;
  isVerified: boolean;
}

export type EmploymentView = 'LIST' | 'TILES';

export interface Toast {
  id?: string;
  message: string;
  messageOptions?: { [key: string]: string };
  type: 'ERROR' | 'INFO' | 'SUCCESS';
  duration?: number;
}

export interface EmploymentUpdateData {
  employmentUuid: EmploymentFragment['uuid'];
  days?: DayFragment[];
  hoursEntries?: HoursEntryFragment[];
}

// keys will be url path in browser
export enum DocumentPageTypesEnum {
  paySlips = DocumentTypeEnum.PAY_SLIP,
  zwischenverdienste = DocumentTypeEnum.ZWISCHENVERDIENST,
  statements = DocumentTypeEnum.EMPLOYER_STATEMENT,
  wageStatements = DocumentTypeEnum.WAGE_STATEMENT,
  others = DocumentTypeEnum.OTHER,
}

export const pageTypeEnumMap = {
  paySlips: DocumentTypeEnum.PAY_SLIP,
  zwischenverdienste: DocumentTypeEnum.ZWISCHENVERDIENST,
  statements: DocumentTypeEnum.EMPLOYER_STATEMENT,
  wageStatements: DocumentTypeEnum.WAGE_STATEMENT,
  others: DocumentTypeEnum.OTHER,
};

export type Month = {
  days: DayFragment[];
  weeks: {
    isoWeek: number;
    days: DayFragment[];
  }[];
  startUnix: number;
  startDate: Date;
};

export interface Benefit {
  _id: string;
  logo: string;
  title: string;
  description: string;
  link: string;
  cta: string;
}
