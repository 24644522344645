import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CompanyUserSupportComponent } from './company-user-support/company-user-support.component';
import { EmployeeSupportComponent } from './employee-support/employee-support.component';
import { SharedDefaultModule } from '../../shared/modules/shared-default/shared-default.module';
import { CollapseModule, InfoBoxModule, SheetModule } from '@intemp/unijob-ui';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [CompanyUserSupportComponent, EmployeeSupportComponent],
  imports: [
    CommonModule,
    SharedDefaultModule,
    SheetModule,
    CollapseModule,
    InfoBoxModule,
    NgOptimizedImage,
    RouterLink,
  ],
  exports: [CompanyUserSupportComponent, EmployeeSupportComponent],
})
export class SupportModule {}
