import { militaryTimeToDate } from './date-helpers/militaryTimeToDate';
import { addDays, getUnixTime } from 'date-fns';

// gets time difference in unix (seconds)
// if "to" is earlier than "from" it assumes "to" to be the next day
export function getDayTimeDifferenceSeconds(
  from: number | string,
  to: number | string,
): number {
  if (from !== undefined && to !== undefined) {
    const fromDate = militaryTimeToDate(from);
    let toDate = militaryTimeToDate(to);
    if (getUnixTime(fromDate) > getUnixTime(toDate)) {
      toDate = addDays(toDate, 1);
    }
    return getUnixTime(toDate) - getUnixTime(fromDate);
  }
  return 0;
}
