import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { randomNumberId } from '../../helpers/functions/randomId';
import { logCustomError } from '../../helpers/functions/logError';
import { QueryParamsService } from '../../../core/services/query-params.service';
import { getObjectKeys } from '../../helpers/functions/getObjectKeys';

export enum GlobalSheetTypeEnum {
  VACANCY_EDIT = 'vacancy-edit',
  EMPLOYMENT_EDIT = 'employment-edit',
  EMPLOYEE_EDIT = 'employee-edit',
  EMPLOYEE_SUPPORT = 'employee-support',
  COMPANY_SUPPORT = 'company-support',
  PROFILE_EDIT = 'profile-edit',
}

export type GlobalSheetParams = {
  type: GlobalSheetTypeEnum;
  uuid: string;
  action?: string;
};

export type GlobalSheetOptions = GlobalSheetParams & {
  sheetId: string;
};

@Injectable({
  providedIn: 'root',
})
export class GlobalSheetsService {
  queryParams?: ParamMap;

  openSheets: GlobalSheetOptions[] = [];
  openSheetsSubject = new BehaviorSubject<GlobalSheetOptions[]>([]);
  openSheets$ = this.openSheetsSubject.asObservable();
  // characters which don't need encoding are:
  // 0-9, a-z, A-Z and "-", ".", "_", "~"
  // partially reserved characters (they may be freely used in some parts of uri):
  // ! * ' ( ) ; : @ & = + $ , / ? % # [ ]
  // use non-encoded characters to make the url shorter e.g. "=" in queryParams is translated to "%253D" which is long

  sheetsParamName = 'sheets';
  propertySeparator = '__';
  sheetItemSeparator = ',';
  assignmentCharacter = '~';

  constructor(
    private route: ActivatedRoute,
    private queryParamsService: QueryParamsService,
  ) {
    this.route.queryParamMap.subscribe(async (params) => {
      this.queryParams = params;
      const sheetsParam = params.get(this.sheetsParamName);
      if (sheetsParam) {
        this.openSheets = this.translateParamsToOpenSheets(sheetsParam);
      } else {
        this.openSheets = [];
      }
      this.openSheetsSubject.next(this.openSheets);
    });
  }

  translateParamsToOpenSheets(sheetsParam: string): GlobalSheetOptions[] {
    return sheetsParam
      .split(this.sheetItemSeparator)
      .map((sheetString, index) => {
        const sheetStringParts = decodeURIComponent(sheetString)
          .split(this.propertySeparator)
          .map((keyValuePair) => {
            const splitKeyValue = keyValuePair.split(this.assignmentCharacter);
            return {
              key: splitKeyValue[0],
              value: splitKeyValue[1],
            };
          });
        const openSheetObj: any = {};
        sheetStringParts.forEach((sheetStringPart) => {
          openSheetObj[sheetStringPart.key] = sheetStringPart.value;
        });
        openSheetObj.sheetId =
          this.openSheets[index]?.sheetId || this.getNewSheetId();
        return openSheetObj as GlobalSheetOptions;
      });
  }

  openGlobalSheet(options: GlobalSheetParams): void {
    this.openSheets.push({
      type: options.type,
      uuid: options.uuid,
      sheetId: this.getNewSheetId(),
      action: options.action,
    });
    this.updateSheetQueryParams();
  }

  getNewSheetId(): string {
    return randomNumberId(9999).toString();
  }

  removeGlobalSheet(sheetId: string): void {
    const previousLength = this.openSheets.length;
    this.openSheets = this.openSheets.filter(
      (openSheet) => openSheet.sheetId !== sheetId,
    );
    if (this.openSheets.length < previousLength) {
      this.updateSheetQueryParams();
    } else {
      logCustomError('sheet could not be removed');
    }
  }

  updateSheetQueryParams(): void {
    this.queryParamsService
      .mergeQueryParams({
        [this.sheetsParamName]:
          this.translateSheetOptionsToQueryParams(this.openSheets) || null,
      })
      .then();
  }

  translateSheetOptionsToQueryParams(options: GlobalSheetOptions[]): string {
    return options
      .map((sheet) => {
        return getObjectKeys(sheet)
          .filter((key) => key !== 'sheetId')
          .map((key) => {
            return encodeURIComponent(
              `${key}${this.assignmentCharacter}${sheet[key]}`,
            );
          })
          .join(this.propertySeparator);
      })
      .join(this.sheetItemSeparator);
  }

  updateParam<K extends keyof Omit<GlobalSheetOptions, 'sheetId'>>(
    optionsOrSheetId: GlobalSheetParams | string,
    name: K,
    value: GlobalSheetOptions[K] | null,
  ): void {
    let sheet: GlobalSheetOptions | undefined = undefined;
    if (typeof optionsOrSheetId === 'string') {
      sheet = this.openSheets.find(
        (sheet) => sheet.sheetId === optionsOrSheetId,
      );
    } else {
      sheet = this.openSheets.find(
        (sheet) =>
          sheet.type === optionsOrSheetId.type &&
          sheet.uuid === optionsOrSheetId.uuid,
      );
    }
    if (!sheet) {
      logCustomError('sheet could not be found');
      return;
    }

    if (value === null) {
      delete sheet[name];
    } else {
      sheet[name] = value;
    }

    this.updateSheetQueryParams();
  }
}
