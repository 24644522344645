import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';

export type BuildInfo = {
  commit: string;
  tag: string;
  branch: string;
  env: string;
};

@Injectable({
  providedIn: 'root',
})
export class BuildInfoService {
  constructor(private http: HttpClient) {}

  getBuildInfo(): Observable<BuildInfo> {
    return (
      this.http.get('/assets/build-infos.json') as Observable<BuildInfo>
    ).pipe(shareReplay(1));
  }
}
