import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DayFragment,
  EmploymentFragment,
  DayStatusEnum,
} from 'src/app/graphql/generated';
import { EmploymentService } from 'src/app/models/uniweb/employment/employment.service';
import { HoursEntryWithPause } from '../open-day/open-day.component';

@Component({
  selector: 'app-approved-day-list-item',
  templateUrl: './approved-day.component.html',
})
export class ApprovedDayComponent {
  @Input({ required: true }) day!: DayFragment;
  @Input({ required: true }) employment!: EmploymentFragment;
  @Input({ required: true }) dayIsEmpty = false;

  @Input({ required: false }) isCompanyUser = false;
  @Input({ required: false }) sortedHoursEntries!: HoursEntryWithPause;

  @Output() emitTotalHoursChanges = new EventEmitter<boolean>();

  constructor(private employmentService: EmploymentService) {}

  editReport(reportId: string): void {
    this.employmentService.reportEntryToEdit$.next({
      entryId: reportId,
      dates: [this.day.date],
    });
  }

  editAbsence(absenceId: string): void {
    this.employmentService.absenceEntryToEdit$.next({
      entryId: absenceId,
      dates: [this.day.date, this.day.date],
    });
  }

  editExpense(expenseId: string): void {
    this.employmentService.expenseEntryToEdit$.next({
      entryId: expenseId,
      date: this.day.date,
      isCompanyUser: this.isCompanyUser,
    });
  }

  public dayStatusEnum = DayStatusEnum;
}
