import { Component, Input, OnInit } from '@angular/core';
import { EmploymentView } from '../../../models/types';
import { getAddressString } from '../../../shared/helpers/functions/getAddressString';
import {
  ModalService,
  ResponsiveService,
  SheetService,
} from '@intemp/unijob-ui';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GlobalSheetsService,
  GlobalSheetTypeEnum,
} from '../../../shared/modules/global-sheets/global-sheets.service';
import { randomId } from '../../../shared/helpers/functions/randomId';
import { EmploymentForEmployeeListItemFragment } from 'src/app/graphql/generated';

@Component({
  selector: 'app-employment-list-item',
  templateUrl: './employment-list-item.component.html',
  styleUrls: ['./employment-list-item.component.scss'],
})
export class EmploymentListItemComponent implements OnInit {
  @Input({ required: true }) employment!: EmploymentForEmployeeListItemFragment;
  @Input() view: EmploymentView = 'TILES';

  infoModalId = randomId();

  isSmDown$ = this.responsiveService.isSmDown$;

  modals: ModalService;
  sheets: SheetService;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private sheetService: SheetService,
    private responsiveService: ResponsiveService,
    private globalSheetsService: GlobalSheetsService,
  ) {
    this.modals = modalService;
    this.sheets = sheetService;
  }

  ngOnInit(): void {}

  editEmployment(): void {
    this.globalSheetsService.openGlobalSheet({
      type: GlobalSheetTypeEnum.EMPLOYMENT_EDIT,
      uuid: this.employment.uuid,
    });
  }

  openDetailsModal(event: Event, id: string): void {
    event.stopPropagation();
    this.modalService.open(id);
  }

  openMap() {
    if (!this.employment.company) return;
    const addressString = getAddressString(
      this.employment.company.contact.address,
    );
    window.open(
      'https://www.google.com/maps/search/' + addressString,
      '_blank',
    );
  }
}
