import { AbsenceEnum } from '../../../../graphql/generated';
import { SelectOption } from '@intemp/unijob-ui/lib/components/select-input/select-input.component';
import { I18NextPipe } from 'angular-i18next';

export function getAbsenceOptions(
  i18n: I18NextPipe,
): SelectOption<AbsenceEnum>[] {
  return [
    {
      value: AbsenceEnum.SICKNESS,
      name: i18n.transform('absencesEnum.SICKNESS'),
    },
    {
      value: AbsenceEnum.ACCIDENT,
      name: i18n.transform('absencesEnum.ACCIDENT'),
    },
    {
      value: AbsenceEnum.VACATION_PAID,
      name: i18n.transform('absencesEnum.VACATION_PAID'),
    },
    {
      value: AbsenceEnum.VACATION_UNPAID,
      name: i18n.transform('absencesEnum.VACATION_UNPAID'),
    },
    {
      value: AbsenceEnum.MILITARY_OR_CIVIL_SERVICE,
      name: i18n.transform('absencesEnum.MILITARY_OR_CIVIL_SERVICE'),
    },
    {
      value: AbsenceEnum.MILITARY_RECRUITMENT_OR_INSPECTION,
      name: i18n.transform('absencesEnum.MILITARY_RECRUITMENT_OR_INSPECTION'),
    },
    {
      value: AbsenceEnum.MATERNITY_LEAVE,
      name: i18n.transform('absencesEnum.MATERNITY_LEAVE'),
    },
    {
      value: AbsenceEnum.WEDDING,
      name: i18n.transform('absencesEnum.WEDDING'),
    },
    {
      value: AbsenceEnum.BIRTH_OR_WEDDING_OF_CHILD,
      name: i18n.transform('absencesEnum.BIRTH_OR_WEDDING_OF_CHILD'),
    },
    {
      value: AbsenceEnum.DEATH_IN_HOUSEHOLD,
      name: i18n.transform('absencesEnum.DEATH_IN_HOUSEHOLD'),
    },
    {
      value: AbsenceEnum.DEATH_OF_CLOSE_RELATIVE,
      name: i18n.transform('absencesEnum.DEATH_OF_CLOSE_RELATIVE'),
    },
    {
      value: AbsenceEnum.RESIDENCE_MOVING,
      name: i18n.transform('absencesEnum.RESIDENCE_MOVING'),
    },
    {
      value: AbsenceEnum.CARING_FOR_SICK_CHILD,
      name: i18n.transform('absencesEnum.CARING_FOR_SICK_CHILD'),
    },
    {
      value: AbsenceEnum.FULFILLMENT_OF_LEGAL_OBLIGATIONS,
      name: i18n.transform('absencesEnum.FULFILLMENT_OF_LEGAL_OBLIGATIONS'),
    },
  ];
}
