import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ModalService, ResponsiveService } from '@intemp/unijob-ui';
import { format } from 'date-fns';
import { HoursEntryFragment, DayStatusEnum } from 'src/app/graphql/generated';
import { dateFnsInputTimeFormat } from '../../../../../shared/helpers/functions/date-helpers/date-variables';
import { militaryTimeToDate } from '../../../../../shared/helpers/functions/date-helpers/militaryTimeToDate';
import { parseDuration } from '../../../../../shared/helpers/functions/parseDuration';
import { randomId } from '../../../../../shared/helpers/functions/randomId';

@Component({
  selector: 'app-hours-row',
  templateUrl: './hours-row.component.html',
  styleUrls: [
    '../../open-day/edit-hours-row/hours-row-shared.scss',
    './hours-row.component.scss',
  ],
})
export class HoursRowComponent implements OnChanges {
  @Input({ required: true }) entry!: HoursEntryFragment;
  @Input() canAddAdjustment = false;
  @Input() strokeContent = false;
  @Input() isCompanyUser = false;
  @Input() dayStatus?: DayStatusEnum;
  @Input() isOwnEntry = true;
  modals: ModalService;
  from = '';
  to = '';
  pause = '';
  duration = '';
  isNightShift = false;

  noteModalId = randomId();

  isMobileView$ = this.responsiveService.isSmDown$;

  constructor(
    private modalService: ModalService,
    private responsiveService: ResponsiveService,
  ) {
    this.modals = modalService;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entry.currentValue) {
      const { from, to, pause, duration } = changes.entry.currentValue;
      this.from = format(militaryTimeToDate(from), dateFnsInputTimeFormat);
      this.to = format(militaryTimeToDate(to), dateFnsInputTimeFormat);
      this.pause = parseDuration((pause || 0) / 60, 'm');
      this.duration = parseDuration(duration / 60, 'm');
      this.checkNightShift();
    }
  }

  checkNightShift(): void {
    this.isNightShift = !!(
      this.entry.from &&
      this.entry.to &&
      this.entry.from > this.entry.to
    );
  }

  async openNoteModal(): Promise<void> {
    this.modals.open(this.noteModalId);
  }

  closeNoteModal(): void {
    this.modals.close(this.noteModalId);
  }

  //previousVersion has same fields as HoursEntryFragment
  previousVersionTypeCast<T>(item: T) {
    return item as HoursEntryFragment;
  }

  addAdjustment(): void {
    const previousVersion = Object.assign({}, this.entry);
    delete (previousVersion as Partial<HoursEntryFragment>).previousVersions;

    if (!this.entry?.previousVersions) {
      this.entry.previousVersions = [];
    }
    this.entry.previousVersions.push(previousVersion);
    this.entry.uuid = randomId();
    /** @ts-expect-error "special case for non saved items" */
    delete this.entry.createdAt;
  }

  trackByUUID(index: number, item: any) {
    return item.uuid;
  }

  protected readonly DayStatusEnum = DayStatusEnum;
}
