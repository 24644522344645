import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'addSpacesToPhone' })
export class AddSpacesToPhonePipe implements PipeTransform {
  transform(phone: string): string {
    if (!phone) {
      return phone;
    }
    const originalPhone = phone;
    phone = phone.replace(new RegExp(' ', 'g'), '');
    if (phone.length > 12 || phone.length < 10) {
      return originalPhone;
    }
    phone.split(' ').join('');
    const phoneParts = [];

    if (phone[0] === '0' && phone[1] === '0') {
      phoneParts.push(phone.slice(0, 4));
      phoneParts.push(phone.slice(4, 6));
      phoneParts.push(phone.slice(6, 9));
      phoneParts.push(phone.slice(9, 11));
      phoneParts.push(phone.slice(11, phone.length));
    } else if (phone[0] === '0' && phone[1] !== '0') {
      phoneParts.push(phone.slice(0, 3));
      phoneParts.push(phone.slice(3, 6));
      phoneParts.push(phone.slice(6, 8));
      phoneParts.push(phone.slice(8, 10));
      if ( phone.length > 10 ) {
        phoneParts.push( phone.slice( 10, phone.length ) );
      }
    } else {
      phoneParts.push(phone.slice(0, 3));
      phoneParts.push(phone.slice(3, 5));
      phoneParts.push(phone.slice(5, 8));
      phoneParts.push(phone.slice(8, 10));
      phoneParts.push(phone.slice(10, 12));
      phoneParts.push(phone.slice(12, phone.length));
    }
    return phoneParts.join(' ');
  }
}
