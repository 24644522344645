import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbsenceDurationEntryFragment,
  DayStatusEnum,
  DocumentStatusEnum,
  DocumentTypeEnum,
  DurationEntryInput,
  DurationEntryTypeEnum,
  EmploymentFragment,
  StoredDocument,
} from 'src/app/graphql/generated';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { randomId } from '../../../shared/helpers/functions/randomId';
import { storedDocumentToJsFile } from '../../../shared/helpers/documents/storedDocumentToJsFile';
import { UserService } from '../../../models/shared/user/user.service';
import { dayHasData } from '../../../shared/helpers/functions/dayIsApprovable';
import { EmploymentService } from '../../../models/uniweb/employment/employment.service';
import { getUtcDate } from '../../../shared/helpers/functions/date-helpers/getUtcDate';
import { getUnixTime, startOfDay, addMonths } from 'date-fns';
import { parseDayDateString } from '../../../shared/helpers/functions/date-helpers/parseDayDateString';
import {
  ModalService,
  ResponsiveService,
  ToastService,
} from '@intemp/unijob-ui';
import { lastValueFrom } from 'rxjs';
import { DocumentService } from '../../../models/shared/document/document.service';
import { I18NextPipe } from 'angular-i18next';
import { ModalComponent } from '@intemp/unijob-ui/lib/components/modal/modal.component';
import { validateAllFormFields } from '../../../shared/helpers/functions/validateAllFormFields';

@Component({
  selector: 'app-report-duration-modal',
  templateUrl: './report-duration-modal.component.html',
  styleUrls: ['./report-duration-modal.component.scss'],
})
export class ReportDurationModalComponent implements OnChanges, AfterViewInit {
  @Input({ required: true }) modalId!: string;
  @Input({ required: true }) employment!: EmploymentFragment;
  @Input() report: Partial<AbsenceDurationEntryFragment> | undefined;

  @Output() modalClosed = new EventEmitter();

  hasNewFile = false;

  modals: ModalService;
  modal?: ModalComponent;
  modalIsOpen = false;
  loading = false;

  reportDurationForm = new FormGroup({
    reportTimespan: new FormControl<Date[] | undefined>(undefined, [
      Validators.required,
    ]),
    reportDocument: new FormControl<File[] | undefined>(undefined, [
      Validators.required,
    ]),
  });
  storedDocument: StoredDocument | null = null;

  unsavedChangesModalId = 'report-unsaved-changes-modal';

  isDisabled = false;

  constructor(
    private employmentService: EmploymentService,
    private toastService: ToastService,
    private modalService: ModalService,
    public userService: UserService,
    private responsiveService: ResponsiveService,
    private documentService: DocumentService,
    private i18nPipe: I18NextPipe,
  ) {
    this.modals = modalService;
  }

  ngAfterViewInit() {
    if (!this.modal) {
      this.modal = this.modals.modals.find(
        (modal) => modal.id === this.modalId,
      );
    }
    this.modal?.isOpen.subscribe((isOpen) => {
      this.modalIsOpen = isOpen;
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const isFirstChange = changes.report?.firstChange;
    const currentValue = changes.report?.currentValue;
    if (isFirstChange) {
      this.reset();
    } else if (!isFirstChange && currentValue) {
      this.prepareModal(currentValue);
    }
  }

  prepareModal(report: Partial<AbsenceDurationEntryFragment>) {
    this.patchForm(report);

    if (this.report?.createdAt) {
      this.reportDurationForm.disable();
    }
  }

  patchForm(report: Partial<AbsenceDurationEntryFragment>) {
    if (!report) return;
    if (report.startDate && report.endDate) {
      const startDate = startOfDay(new Date(report.startDate));
      const endDate = startOfDay(new Date(report.endDate));
      setTimeout(() => {
        this.reportDurationForm.controls.reportTimespan.setValue([
          startDate,
          endDate,
        ]);
      });
      if (report.document?.uuid) {
        this.loading = true;
        this.documentService
          .getStoredDocument(report.document.uuid)
          .then((document) => {
            if (!document) return;
            report.document = document;
            if (document?.data) {
              const file = storedDocumentToJsFile(document);
              this.reportDurationForm.controls.reportDocument.setValue([file]);
            }
          })
          .catch((e) => {
            console.error(e);
            this.toastService.makeToast({
              type: 'ERROR',
              message: this.i18nPipe.transform('couldNotLoadDocument'),
            });
          })
          .finally(() => (this.loading = false));
      }
    }
  }

  datePickerDayDisabledFunction(): (day: any) => boolean {
    return ((dayDate: Date): boolean => {
      const dayData = this.employment.days?.find((employmentDay): boolean => {
        return (
          getUnixTime(parseDayDateString(employmentDay.date)) ===
          getUnixTime(startOfDay(dayDate))
        );
      });

      if (dayData?.reportUUID) {
        if (this.report?.uuid && dayData?.reportUUID === this.report.uuid) {
          // dont disable currently selected days
          return false;
        } else {
          // disable other days with reports
          return true;
        }
      }

      const afterToday =
        getUnixTime(startOfDay(dayDate)) > getUnixTime(startOfDay(new Date()));

      if (afterToday) return true;

      const dayIsBeforeEmploymentStart =
        getUnixTime(startOfDay(dayDate)) <
        getUnixTime(parseDayDateString(this.employment?.startDate));

      if (dayIsBeforeEmploymentStart) return true;

      if (this.employment.endDate) {
        const endDate = addMonths(new Date(this.employment.endDate), 1);
        const dayIsAfterEmploymentEnd = dayDate > endDate;
        if (dayIsAfterEmploymentEnd) return true;
      }

      // day does not exist yet
      if (!dayData) {
        return false;
      }

      // disable days with Entries
      if (dayHasData(dayData)) {
        return true;
      }
      return dayData.status !== DayStatusEnum.OPEN;
    }).bind(this);
  }

  fileChanged(files: File[]): void {
    if (!files?.length) {
      this.storedDocument = null;
      return;
    } else {
      let uuid = this.report?.document?.uuid;
      if (!uuid) {
        uuid = randomId();
        this.hasNewFile = true;
      }
      const file = files[0];
      this.storedDocument = {
        uuid: uuid,
        fileName: file.name,
        mimeType: file.type,
        type: DocumentTypeEnum.HOURS_REPORT,
        status: DocumentStatusEnum.READ,
        _id: null,
        companyId: null,
        createdAt: null,
        data: null,
        deletedAt: null,
        description: null,
        notifiedAt: null,
        personalNumber: null,
        title: null,
        translatedDocumentId: null,
      };
    }
  }

  async saveReport(): Promise<void> {
    const reportTimeSpanInput =
      this.reportDurationForm.controls.reportTimespan.value;

    if (this.reportDurationForm.invalid || !this.report?.uuid) return;

    if (
      !reportTimeSpanInput ||
      !this.storedDocument ||
      !this.employment ||
      !this.report
    )
      return;

    this.loading = true;
    const storedDocument = this.storedDocument;

    const from = getUtcDate(startOfDay(reportTimeSpanInput[0]));
    const to = getUtcDate(startOfDay(reportTimeSpanInput[1]));

    const reportUUID = this.report.uuid;

    if (!this.report?.createdAt) {
      this.report.createdAt = new Date().toISOString();
      this.report.startDate = from.toISOString();
      this.report.endDate = to.toISOString();
    }
    const data: DurationEntryInput = {
      uuid: reportUUID,
      startDate: from.toISOString(),
      endDate: to.toISOString(),
      employmentId: this.employment.uuid,
      type: DurationEntryTypeEnum.REPORT,
    };

    if (this.hasNewFile) {
      data.file = {
        uuid: storedDocument.uuid,
        file: this.reportDurationForm.controls.reportDocument.value?.[0],
        documentType: storedDocument.type,
      };
    }
    try {
      await lastValueFrom(this.employmentService.updateDurationEntry(data));
      this.closeModal();
      this.toastService.makeToast({
        message: this.i18nPipe.transform('uploadReportSuccessful'),
        type: 'SUCCESS',
      });
    } catch (e) {
      // error is logged and toast made on apollo level
    }
    this.loading = false;
  }

  reset(): void {
    this.report = {
      uuid: randomId(),
    };
    this.storedDocument = null;
    this.isDisabled = false;
    this.hasNewFile = false;

    this.reportDurationForm.reset({});
    this.reportDurationForm.enable();
  }

  closeModal(): void {
    this.reset();
    this.modalClosed.emit();
  }

  discardChanges(): void {
    this.modalService.close(this.unsavedChangesModalId);
    this.closeModal();
  }

  failedToClose(): void {
    this.modalService.open(this.unsavedChangesModalId);
  }

  async saveChanges(): Promise<void> {
    this.modalService.close(this.unsavedChangesModalId);

    validateAllFormFields(this.reportDurationForm);
    if (this.reportDurationForm.invalid) {
      setTimeout(() => {
        this.toastService.makeToast({
          type: 'ERROR',
          message: this.i18nPipe.transform('pleaseFillAllTheRequiredFields'),
        });
      }, 500);
    } else {
      await this.saveReport();
    }
  }
}
