import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CollapseDirective, ResponsiveService } from '@intemp/unijob-ui';
import { getDaysToApprove } from 'src/app/shared/helpers/functions/getDaysToApprove';
import {
  DayFragment,
  DayStatusEnum,
  EmploymentFragment,
  ValueCurrency,
} from 'src/app/graphql/generated';
import { UserService } from '../../../../../models/shared/user/user.service';
import { Month } from '../../../../../models/types';
import { dayIsApprovable } from '../../../../../shared/helpers/functions/dayIsApprovable';
import { getDaysExpensesTotal } from '../../../../../shared/helpers/functions/getExpensesTotal';
import { parseDuration } from '../../../../../shared/helpers/functions/parseDuration';
import { DaysFormArray } from '../../../employment-types/employment-form';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-month-list-item',
  templateUrl: './month-list-item.component.html',
  styleUrls: ['./month-list-item.component.scss'],
})
export class MonthListItemComponent implements AfterViewInit, OnChanges {
  dayStatusEnum = DayStatusEnum;
  @Input({ required: true }) month!: Month;
  @Input({ required: true })
  employment!: EmploymentFragment;
  @Input({ required: true }) formReferenceArray!: DaysFormArray;
  @Input() sortAsc = true;
  @Input() initialCollapse = false;

  @Output() collapseToggled: EventEmitter<Month> = new EventEmitter();

  @ViewChild(CollapseDirective) collapse?: CollapseDirective;

  isCollapsed = true;
  isCompanyUser = false;
  daysToTransfer?: number;
  daysToApprove?: number;
  totalHoursDuration?: number;
  hasExpenses?: boolean;
  totalExpenses: ValueCurrency[] = [];
  totalHours?: string;
  allDaysAreApproved = false;

  isSmDown$ = this.responsiveService.isSmDown$;

  constructor(
    public userService: UserService,
    private responsiveService: ResponsiveService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.month) {
      this.calculateTotals();
    }
  }

  async ngOnInit(): Promise<void> {
    this.isCompanyUser = await firstValueFrom(
      this.userService.userIsCompanyUser$,
    );
  }

  async ngAfterViewInit(): Promise<void> {
    setTimeout(() => {
      this.collapse?.collapsedSubject.subscribe((isCollapsed) => {
        this.isCollapsed = isCollapsed;
      });
    });
  }

  calculateTotals(): void {
    this.daysToTransfer = this.month?.days?.filter((day) => {
      return dayIsApprovable(day);
    }).length;
    this.daysToApprove = getDaysToApprove(this.month.days).length;
    this.totalHoursDuration = this.getTotalHoursDuration();
    this.hasExpenses = this.getHasExpenses();
    this.totalExpenses = getDaysExpensesTotal(this.month.days, this.employment);
    this.totalHours = parseDuration(this.totalHoursDuration / 60, 'm');

    this.allDaysAreApproved = this.month.days.every((day) => {
      return day.status === DayStatusEnum.APPROVED;
    });
  }

  getTotalHoursDuration(): number {
    let totalDuration = 0;
    if (this.month?.days) {
      this.month.days.forEach((day) => {
        day.hoursEntries
          .filter((entry) => !entry.deletedAt)
          .forEach((hoursEntry) => {
            if (hoursEntry.duration) {
              totalDuration += hoursEntry.duration;
            }
          });
      });
      return totalDuration;
    }
    return 0;
  }

  getHasExpenses(): boolean {
    return this.month.days.filter((day) => day.expenseUUID).length > 0;
  }

  trackByDate(index: number, item: DayFragment) {
    return item.date;
  }
}
