import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  computed,
  signal,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalService, SheetService, ToastService } from '@intemp/unijob-ui';
import { SelectOption } from '@intemp/unijob-ui/lib/components/select-input/select-input.component';
import { I18NextPipe } from 'angular-i18next';
import Croppie from 'croppie';
import { BehaviorSubject } from 'rxjs';
import { getObjectKeys } from 'src/app/shared/helpers/functions/getObjectKeys';
import { UserService } from '../../models/shared/user/user.service';
import {
  Address,
  BankingInformation,
  DocumentStatusEnum,
  DocumentTypeEnum,
  StoredDocument,
  StoredDocumentInput,
  UserInput,
  UserFragment,
  BankingInformationInput,
  StoredDocumentWithDataFragment,
} from 'src/app/graphql/generated';
import { base64Encode } from '../../shared/helpers/functions/base64Encode';
import { compressFile } from '../../shared/helpers/functions/compressFile';
import { randomId } from '../../shared/helpers/functions/randomId';
import { AddSpacesToIbanPipe } from '../../shared/helpers/pipes/addSpacesToIban.pipe';
import { AddSpacesToPhonePipe } from '../../shared/helpers/pipes/addSpacesToPhone.pipe';
import { customValidators } from '../../shared/modules/shared-forms/customValidators';
import { StoredDocumentUrlPipe } from '../../shared/helpers/documents/stored-document-url.pipe';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input({ required: true }) sheetId!: string;
  @Output() sheetClosed = new EventEmitter<string>();
  user = signal<UserFragment | undefined>(undefined);
  modals: ModalService;

  uploadingProfileImage = false;
  croppie: any;
  croppieMimeType = 'image/jpeg';
  croppieMimeTypeEnding = 'jpeg';
  newFileType?: DocumentTypeEnum | false;
  profileImageForm: FormGroup = new FormGroup({
    image: new FormControl(''),
  });
  fileToCrop?: File;
  fileToCropObjectUrl?: string;

  unsavedChangesModalId = 'profile-unsaved-changes-modal';

  personalDocuments: File[] = [];
  uploadedPersonalDocuments: StoredDocument[] = [];
  personalDocumentsError = false;
  cropImageModalId = 'crop-profile-image-modal';

  documentTypeOptions: SelectOption[] = [
    {
      value: DocumentTypeEnum.AHV,
      name: this.i18nPipe.transform('documentTypesEnum.AHV'),
    },
    {
      value: DocumentTypeEnum.FOREIGNER_PERMIT,
      name: this.i18nPipe.transform('documentTypesEnum.FOREIGNER_PERMIT'),
    },
    {
      value: DocumentTypeEnum.FAMILY_ALLOWANCES,
      name: this.i18nPipe.transform('documentTypesEnum.FAMILY_ALLOWANCES'),
    },
    {
      value: DocumentTypeEnum.DEPT_ENFORCEMENT_REGISTER_REPORT,
      name: this.i18nPipe.transform(
        'documentTypesEnum.DEPT_ENFORCEMENT_REGISTER_REPORT',
      ),
    },
    {
      value: DocumentTypeEnum.CRIMINAL_RECORD,
      name: this.i18nPipe.transform('documentTypesEnum.CRIMINAL_RECORD'),
    },
    {
      value: DocumentTypeEnum.OTHER,
      name: this.i18nPipe.transform('documentTypesEnum.OTHER'),
    },
  ];

  firstNameControl = new FormControl('', [customValidators.required]);
  lastNameControl = new FormControl('', [customValidators.required]);
  phoneControl = new FormControl('', [customValidators.required]);
  emailControl = new FormControl('', [
    customValidators.email,
    customValidators.required,
  ]);
  personalDocumentsControl = new FormControl<File[]>([]);
  streetControl = new FormControl('', customValidators.required);
  zipControl = new FormControl('', [
    customValidators.required,
    customValidators.minLength(4),
  ]);
  locationControl = new FormControl('', customValidators.required);
  countryControl = new FormControl('', customValidators.required);
  ibanControl = new FormControl('', [
    customValidators.required,
    customValidators.validateIban,
  ]);
  deviatingRecipientControl = new FormControl<boolean>(false);
  recipientFirstNameControl = new FormControl('');
  recipientLastNameControl = new FormControl('');
  recipientStreetControl = new FormControl('');
  recipientZipControl = new FormControl('');
  recipientLocationControl = new FormControl('');
  recipientCountryControl = new FormControl('');

  addressForm = new FormGroup({
    street: this.streetControl,
    zip: this.zipControl,
    location: this.locationControl,
    country: this.countryControl,
  });

  recipientForm = new FormGroup({
    deviatingRecipient: this.deviatingRecipientControl,
    firstName: this.recipientFirstNameControl,
    lastName: this.recipientLastNameControl,
    street: this.recipientStreetControl,
    zip: this.recipientZipControl,
    location: this.recipientLocationControl,
    country: this.recipientCountryControl,
  });

  bankingInformationForm = new FormGroup({
    iban: this.ibanControl,
    recipient: this.recipientForm,
  });

  profileForm = new FormGroup({
    firstName: this.firstNameControl,
    lastName: this.lastNameControl,
    phone: this.phoneControl,
    email: this.emailControl,
    personalDocuments: this.personalDocumentsControl,
    address: this.addressForm,
    bankingInformation: this.bankingInformationForm,
  });

  formHasChanges = new BehaviorSubject<boolean>(false);

  storedDocuments = computed(() => {
    const user = this.user();
    if (!user || !user.profile?.storedDocuments) return [];
    const sortedDocuments = user.profile.storedDocuments.sort((a, b) => {
      if (!a.createdAt || !b.createdAt) return 0;
      return b.createdAt.localeCompare(a.createdAt);
    });
    return sortedDocuments;
  });

  constructor(
    private userService: UserService,
    private addSpacesToPhone: AddSpacesToPhonePipe,
    private addSpacesToIban: AddSpacesToIbanPipe,
    private toastService: ToastService,
    private modalService: ModalService,
    private i18nPipe: I18NextPipe,
    private sheetService: SheetService,
    public storedDocumentUrl: StoredDocumentUrlPipe,
  ) {
    this.modals = modalService;
    this.profileForm.controls.email.disable();
    this.profileForm.controls.personalDocuments.disable();
    this.profileForm.controls.personalDocuments.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.uploadPersonalDocuments(value);
        }
      },
    );
  }

  async ngOnInit(): Promise<void> {
    await this.getUser();
    this.profileForm.valueChanges.subscribe(() => {
      this.checkForChanges();
    });

    this.deviatingRecipientControl.valueChanges.subscribe((value) => {
      this.setRecipientFieldsRequired(value ?? false);
    });
  }

  ngAfterViewInit(): void {
    this.sheetService.open(this.sheetId);
  }

  async getUser(): Promise<void> {
    await this.userService.getUser(true).then((user) => {
      this.user.set(user);
      this.patchForm(user);
    });
  }

  patchForm(user: UserFragment) {
    if (!user) return;
    this.profileForm.patchValue(
      {
        firstName: user.profile.firstName,
        lastName: user.profile.lastName,
        phone: this.addSpacesToPhone.transform(user.profile.phone ?? ''),
        email: user.profile.email,
        address: {
          street: user.profile.address?.street,
          zip: user.profile.address?.zip,
          location: user.profile.address?.location,
          country: user.profile.address?.country,
        },
        bankingInformation: {
          iban: this.addSpacesToIban.transform(
            user.profile.bankingInformation?.iban ?? '',
          ),
          recipient: {
            deviatingRecipient: user.profile.bankingInformation?.recipient
              ? true
              : false,
            firstName: user.profile.bankingInformation?.recipient?.firstName,
            lastName: user.profile.bankingInformation?.recipient?.lastName,
            street: user.profile.bankingInformation?.recipient?.address.street,
            zip: user.profile.bankingInformation?.recipient?.address.zip,
            location:
              user.profile.bankingInformation?.recipient?.address.location,
            country:
              user.profile.bankingInformation?.recipient?.address.country,
          },
        },
      },
      { emitEvent: false },
    );
    this.profileForm.markAsPristine();
  }

  checkDirtyAndValueChange = (
    control: FormControl,
    value: any,
    removeSpaces = false,
  ) => {
    if (removeSpaces) {
      return (
        control.dirty &&
        control.value.replace(/\s/g, '') !== value.replace(/\s/g, '')
      );
    } else {
      return control.dirty && control.value !== value;
    }
  };

  checkForChanges() {
    const checkProfile = (
      control: FormControl,
      field: keyof UserFragment['profile'],
      removeSpaces = false,
    ) =>
      this.checkDirtyAndValueChange(
        control,
        this.user()?.profile?.[field],
        removeSpaces,
      );

    const checkAddress = (control: FormControl, field: keyof Address) =>
      this.checkDirtyAndValueChange(
        control,
        this.user()?.profile.address?.[field],
      );

    const checkRecipientAddress = (
      control: FormControl,
      field: keyof Address,
    ) =>
      this.checkDirtyAndValueChange(
        control,
        this.user()?.profile.bankingInformation?.recipient?.address?.[field],
      );

    const checkBankingInfo = (
      control: FormControl,
      field: keyof BankingInformation,
      removeSpaces = false,
    ) =>
      this.checkDirtyAndValueChange(
        control,
        this.user()?.profile.bankingInformation?.[field],
        removeSpaces,
      );

    const recipientAdded =
      this.deviatingRecipientControl.value &&
      !this.user()?.profile.bankingInformation?.recipient;
    const recipientRemoved =
      !this.deviatingRecipientControl.value &&
      this.user()?.profile.bankingInformation?.recipient;
    const hasRecipient = this.user()?.profile.bankingInformation?.recipient;

    const hasRecipientChanges =
      recipientRemoved ||
      recipientAdded ||
      (hasRecipient &&
        (this.checkDirtyAndValueChange(
          this.recipientFirstNameControl,
          'firstName',
        ) ||
          this.checkDirtyAndValueChange(
            this.recipientLastNameControl,
            'lastName',
          ) ||
          checkRecipientAddress(this.recipientStreetControl, 'street') ||
          checkRecipientAddress(this.recipientCountryControl, 'country') ||
          checkRecipientAddress(this.recipientZipControl, 'zip') ||
          checkRecipientAddress(this.recipientLocationControl, 'location')));

    const hasChanges =
      checkProfile(this.firstNameControl, 'firstName') ||
      checkProfile(this.lastNameControl, 'lastName') ||
      checkProfile(this.phoneControl, 'phone', true) ||
      checkProfile(this.emailControl, 'email') ||
      checkAddress(this.streetControl, 'street') ||
      checkAddress(this.zipControl, 'zip') ||
      checkAddress(this.locationControl, 'location') ||
      checkAddress(this.countryControl, 'country') ||
      checkBankingInfo(this.ibanControl, 'iban', true) ||
      hasRecipientChanges;

    this.formHasChanges.next(!!hasChanges);
  }

  onSubmit(): void {
    if (!this.profileForm.valid) {
      this.toastService.makeToast({
        message: this.i18nPipe.transform('fieldsRequired'),
        type: 'ERROR',
      });
      return;
    }

    const userInput: UserInput = {
      profile: {
        firstName: this.firstNameControl.value,
        lastName: this.lastNameControl.value,
        phone: this.phoneControl.value?.replace(/\s/g, ''),
        address: {
          street: this.streetControl.value,
          zip: this.zipControl.value,
          location: this.locationControl.value,
          country: this.countryControl.value,
        },
      },
    };
    if (userInput.profile && this.ibanControl.value) {
      const bankingInformation: BankingInformationInput = {
        iban: this.ibanControl.value.replace(/\s/g, ''),
      };
      if (this.deviatingRecipientControl.value) {
        bankingInformation.recipient = {
          firstName: this.recipientFirstNameControl.value,
          lastName: this.recipientLastNameControl.value,
          address: {
            street: this.recipientStreetControl.value,
            zip: this.recipientZipControl.value,
            location: this.recipientLocationControl.value,
            country: this.recipientCountryControl.value,
          },
        };
      } else {
        bankingInformation.recipient = null;
      }
      userInput.profile.bankingInformation = bankingInformation;
    }

    this.userService.updateUser$(userInput).subscribe(async () => {
      this.toastService.makeToast({
        message: this.i18nPipe.transform('saved'),
        type: 'SUCCESS',
      });
      await this.getUser();
      this.profileForm.markAsPristine();
      this.profileForm.markAsUntouched();
      this.formHasChanges.next(false);
    });
  }

  closeSheet(): void {
    this.sheetClosed.emit(this.sheetId);
  }

  async ngOnDestroy() {
    if (this.fileToCropObjectUrl) {
      URL.revokeObjectURL(this.fileToCropObjectUrl);
    }
    this.sheetService.close(this.sheetId);
  }

  async removeProfileImage(): Promise<void> {
    const user = this.user();
    if (!user?.profile?.image) {
      console.error('no Image to remove');
      return;
    }
    this.resetProfileImageFormControl();
    this.userService
      .removeProfileImage$(user?.profile.image.uuid)
      .subscribe(() => {
        if (user.profile.image) {
          user.profile.image = null;
        }
        this.toastService.makeToast({
          type: 'SUCCESS',
          message: this.i18nPipe.transform('profileImageRemoved'),
        });
      });
  }

  openCropperModal(event: Event): void {
    const files = (<HTMLInputElement>event.target).files;
    if (!files?.length) {
      return;
    }
    this.fileToCrop = files[0];
    if (this.fileToCropObjectUrl) {
      URL.revokeObjectURL(this.fileToCropObjectUrl);
    }
    this.fileToCropObjectUrl = URL.createObjectURL(this.fileToCrop);

    this.modals.open(this.cropImageModalId);

    // croppie needs to be visible when initialized
    setTimeout(() => {
      if (!this.croppie) {
        const croppieElement = document.getElementById('customCroppie');
        if (!croppieElement) {
          console.error('could element with id croppieElement in DOM');
          return;
        }
        this.croppie = new Croppie(croppieElement, {
          viewport: {
            type: 'circle',
            width: 146,
            height: 146,
          },
          enableOrientation: true,
        });

        const inputRangeSlider = this.croppie.element.children[1].querySelector(
          'input[type="range"]',
        );
        inputRangeSlider.classList.add('slider');

        this.croppie.element.addEventListener('update', () => {
          const value = parseFloat(inputRangeSlider.value);
          const percentage =
            ((value - inputRangeSlider.min) /
              (inputRangeSlider.max - inputRangeSlider.min)) *
            100;
          inputRangeSlider.style.background = `linear-gradient(90deg, rgb(230, 0, 126) ${percentage}%, rgb(224, 224, 224) ${percentage}% )`;
        });
      }
      this.croppie.bind({
        url: this.fileToCropObjectUrl,
      });
    }, 100);
  }

  rotateCroppingImageLeft(): void {
    if (this.croppie) {
      this.croppie.rotate(90);
    }
  }

  resetProfileImageFormControl(): void {
    this.profileImageForm.controls.image.setValue('');
  }

  async saveCroppedImage(): Promise<void> {
    if (!this.fileToCrop) {
      console.error('missing file to crop');
      return;
    }
    this.modals.close(this.cropImageModalId);
    const resultBlob: Blob = this.croppie.result({
      type: 'blob',
      circle: false,
      size: 'original',
    });
    const newFileName =
      this.fileToCrop.name.substr(0, this.fileToCrop.name.lastIndexOf('.')) +
      '.' +
      this.croppieMimeTypeEnding;
    const resultFile = new File([await resultBlob], newFileName, {
      type: this.croppieMimeType,
    });
    await this.uploadProfileImage(resultFile).then();
  }

  async uploadProfileImage(file: File): Promise<void> {
    const user = this.user();
    if (!user) return;
    this.uploadingProfileImage = true;
    file = await compressFile(file);
    const preservedImageValue = user.profile.image;
    try {
      const storedDocument: StoredDocumentInput = {
        uuid: randomId(),
        fileName: file.name,
        type: DocumentTypeEnum.PROFILE_IMAGE,
        data: await base64Encode(file),
        status: DocumentStatusEnum.READ,
        mimeType: file.type,
      };
      user.profile.image = {
        ...storedDocument,
        createdAt: new Date().toISOString(),
        translatedDocumentId: null,
      };
      await this.userService.uploadProfileImage$(storedDocument).toPromise();
      this.uploadingProfileImage = false;
      this.toastService.makeToast({
        type: 'SUCCESS',
        message: this.i18nPipe.transform('profileImageSaved'),
      });
    } catch (error) {
      // error is logged and toast made on apollo level
      user.profile.image = preservedImageValue;
      this.uploadingProfileImage = false;
    }
  }

  setRecipientFieldsRequired(required: boolean): void {
    if (required) {
      this.recipientFirstNameControl.setValidators(customValidators.required);
      this.recipientLastNameControl.setValidators(customValidators.required);
      this.recipientStreetControl.setValidators(customValidators.required);
      this.recipientZipControl.setValidators(customValidators.required);
      this.recipientLocationControl.setValidators(customValidators.required);
      this.recipientCountryControl.setValidators(customValidators.required);
    } else {
      const controls = getObjectKeys(this.recipientForm.controls);
      for (const key of controls) {
        const control = this.recipientForm.controls[key];
        control.clearValidators();
        control.setErrors(null);
      }
    }
  }

  async uploadPersonalDocuments(files: File[]): Promise<void> {
    if (!files) {
      return;
    }
    if (files.length > this.uploadedPersonalDocuments.length) {
      const fileNameMap = this.uploadedPersonalDocuments.map(
        (file) => file.fileName,
      );
      let file = files[0];
      for (let i = 0; i < files.length; i++) {
        file = files[i];
        if (!fileNameMap.includes(file.name) && this.newFileType) {
          const storedDocument: StoredDocumentInput = {
            uuid: randomId(),
            fileName: file.name,
            type: this.newFileType,
            data: await base64Encode(file),
            status: DocumentStatusEnum.READ,
            mimeType: file.type,
          };
          this.userService.uploadProfileDocument(storedDocument).subscribe({
            next: async (res) => {
              this.newFileType = undefined;
              this.filetypeSelected(this.newFileType);
              this.user.update((user) => {
                if (!user) return;
                user?.profile.storedDocuments?.push({
                  ...storedDocument,
                  createdAt: new Date().toISOString(),
                  translatedDocumentId: null,
                });
                return { ...user };
              });
              this.uploadedPersonalDocuments.push({
                ...storedDocument,
                companyId: storedDocument.companyId || 0,
                description: storedDocument.description || '',
                personalNumber: storedDocument.personalNumber || 0,
                title: storedDocument.title || '',
                _id: null,
                createdAt: null,
                deletedAt: null,
                notifiedAt: null,
                translatedDocumentId: null,
              });
              if (res?.data?.uploadUserDocument) {
                this.toastService.makeToast({
                  type: 'SUCCESS',
                  message: this.i18nPipe.transform('fileUploadComplete'),
                });
              }
            },
            error: () => {
              this.personalDocumentsError = true;
              this.newFileType = undefined;
              this.filetypeSelected(this.newFileType);
            },
          });
        }
      }
      this.personalDocumentsControl.setValue([]);
    } else {
      const fileNameMap = files.map((file) => file.name);
      for (let i = 0; i < this.uploadedPersonalDocuments.length; i++) {
        if (!fileNameMap.includes(this.uploadedPersonalDocuments[i].fileName)) {
          this.deleteFile(this.uploadedPersonalDocuments[i].uuid);
          this.uploadedPersonalDocuments.splice(i, 1);
          i--;
        }
      }
    }
  }

  deleteFile(uuid: string): void {
    const fileIndex =
      this.user()?.profile.storedDocuments?.findIndex(
        (storedDocument) => storedDocument.uuid === uuid,
      ) || -1;

    this.userService.removeFile(uuid).subscribe({
      next: async (res) => {
        this.user.update((user) => {
          if (!user) return;
          user.profile.storedDocuments?.splice(fileIndex, 1);
          return { ...user };
        });
        if (res?.data?.removeUserDocument) {
          this.toastService.makeToast({
            type: 'SUCCESS',
            message: this.i18nPipe.transform('fileRemoved'),
          });
        }
      },
      error: () => {
        console.error('error deleting file');
        this.toastService.makeToast({
          type: 'ERROR',
          message: this.i18nPipe.transform('generalError'),
        });
      },
    });
  }
  filetypeSelected(value?: DocumentTypeEnum | false): void {
    if (value) {
      this.profileForm.controls.personalDocuments.enable();
    } else {
      this.profileForm.controls.personalDocuments.disable();
    }
  }

  discardChanges(): void {
    this.modalService.close(this.unsavedChangesModalId);
    this.sheetService.close(this.sheetId);
  }

  failedToClose(): void {
    this.modalService.open(this.unsavedChangesModalId);
  }

  saveAndClose(): void {
    if (this.profileForm.invalid) {
      setTimeout(() => {
        this.toastService.makeToast({
          type: 'ERROR',
          message: this.i18nPipe.transform('pleaseResolveErrorsInOrderToSave'),
        });
      }, 500);
    } else {
      this.onSubmit();
      this.modalService.close(this.unsavedChangesModalId);
    }
  }

  openDocument(doc: StoredDocumentWithDataFragment) {
    window.open(this.storedDocumentUrl.transform(doc), '_blank');
  }
}
