import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { parseDayTime } from '../../helpers/functions/parseDayTime';
import { parseDuration } from '../../helpers/functions/parseDuration';

const IBAN = require('iban');
export const CustomValidatorFunctions = {
  isValidDayTime: (value?: string) => {
    if (!value || value === '') {
      return false;
    }
    const parsedValue = parseDayTime(value.toString());
    const separatorIndex = parsedValue.indexOf(':');
    const hours = parseInt(parsedValue.slice(0, separatorIndex), 10);
    const minutes = parseInt(
      parsedValue.slice(separatorIndex + 1, parsedValue.length),
      10,
    );

    const hoursValid = hours <= 23 && hours >= 0;
    const minutesValid = minutes <= 59 && minutes >= 0;
    return hoursValid && minutesValid;
  },
  isValidDuration: (value?: string) => {
    if (!value) {
      return true;
    }
    let parsedValue = parseDuration(value);

    const separatorIndex = parsedValue.indexOf('h');
    parsedValue = parsedValue.replace('m', '');
    const minutesString = parsedValue.slice(
      separatorIndex + 1,
      parsedValue.length,
    );
    if (minutesString) {
      const minutes = parseInt(
        parsedValue.slice(separatorIndex + 1, parsedValue.length),
        10,
      );
      return minutes <= 59;
    } else {
      return true;
    }
  },
};
export const customValidators = {
  validateIban: (control: AbstractControl) => {
    if (control.value && control.enabled) {
      if (!IBAN.isValid(control.value)) {
        return {
          message: {
            key: 'invalidIBAN',
          },
          onlyOneSeparatorAllowed: true,
        };
      }
    }
    return null;
  },
  isDayTime: (control: AbstractControl): ValidationErrors | null => {
    if (control.value && control.enabled) {
      if (!CustomValidatorFunctions.isValidDayTime(control.value)) {
        return {
          message: {
            key: 'invalidTime',
          },
          onlyOneSeparatorAllowed: true,
        };
      }
    }
    return null;
  },
  isTimeDuration: (control: AbstractControl): ValidationErrors | null => {
    if (control.value && control.enabled) {
      if (!CustomValidatorFunctions.isValidDuration(control.value)) {
        return {
          message: {
            key: 'invalidTime',
          },
          onlyOneSeparatorAllowed: true,
        };
      }
    }
    return null;
  },
  required: (control: AbstractControl): ValidationErrors | null => {
    if (Validators.required(control)) {
      return {
        message: {
          key: 'fieldRequired',
        },
        missingRequiredField: true,
      };
    }
    return null;
  },
  minLength: (min: number): any => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        control.value &&
        control.value.toString().split(' ').join('').length < min
      ) {
        return {
          message: {
            key: 'minLength',
            options: { min },
          },
          notEnoughCharacters: true,
        };
      }
      return null;
    };
  },
  maxLength: (max: number): any => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        control.value &&
        control.value.toString().split(' ').join('').length > max
      ) {
        return {
          message: {
            key: 'maxLength',
            options: { max },
          },
          exceedingMaxLength: true,
        };
      }
      return null;
    };
  },
  email: (control: AbstractControl): ValidationErrors | null => {
    if (Validators.email(control)) {
      return {
        message: {
          key: 'invalidEmail',
        },
        invalidEmail: true,
      };
    }
    return null;
  },

  isEmpty: (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    return !value || value.length === 0 ? { isEmpty: true } : null;
  },

  bothDatesAreRequired: (
    control: AbstractControl<Date[] | undefined>,
  ): ValidationErrors | null => {
    const dates = control.value;
    if (dates && dates?.length < 2) {
      return {
        message: {
          key: 'bothDatesAreRequired',
        },
      };
    }
    return null;
  },
};
