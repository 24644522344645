import { StoredDocumentWithDataFragment } from 'src/app/graphql/generated';
import { StoredDocument } from 'src/app/graphql/generated';

export function storedDocumentToJsFile(
  storedDocument: StoredDocument | StoredDocumentWithDataFragment,
): File {
  if (!storedDocument.data) {
    throw new Error('storedDocument.data is missing to create Js-File');
  }
  const binary = atob(storedDocument.data.replace(/\s/g, ''));
  const length = binary.length;
  const buffer = new ArrayBuffer(length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < length; i++) {
    view[i] = binary.charCodeAt(i);
  }
  return new File([view], storedDocument.fileName, {
    type: storedDocument?.mimeType,
  });
}
