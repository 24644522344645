import { EmploymentFragment } from 'src/app/graphql/generated';
import { Employment, ExpensesEntry } from 'src/app/graphql/generated';

export function getExpensesEntry(
  employment: EmploymentFragment,
  expensesUuid: string,
) {
  const expense = employment.expenses.find(
    (expense) => expense.uuid === expensesUuid,
  );
  if (!expense) {
    throw new Error(
      `Expenses with uuid ${expensesUuid} not found on Employment`,
    );
  }
  return expense;
}
