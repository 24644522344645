import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AddSpacesToPhonePipe } from '../../helpers/pipes/addSpacesToPhone.pipe';
import { AddSpacesToIbanPipe } from '../../helpers/pipes/addSpacesToIban.pipe';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { I18NextModule } from 'angular-i18next';
import { StoredDocumentUrlPipe } from '../../helpers/documents/stored-document-url.pipe';
import { LoggedOutWrapperComponent } from '../../../pages/login/logged-out-wrapper/logged-out-wrapper.component';
import { FirstUpperCasePipe } from '../../helpers/pipes/firstUpperCase.pipe';
import { MatIconModule } from '@angular/material/icon';
import { AvatarModule, ButtonModule, IconModule } from '@intemp/unijob-ui';

@NgModule({
  declarations: [
    AddSpacesToPhonePipe,
    AddSpacesToIbanPipe,
    FirstUpperCasePipe,
    LoadingSpinnerComponent,
    StoredDocumentUrlPipe,
    LoggedOutWrapperComponent,
  ],
  imports: [CommonModule, I18NextModule, MatIconModule],
  exports: [
    CommonModule,
    AddSpacesToPhonePipe,
    AddSpacesToIbanPipe,
    FirstUpperCasePipe,
    LoadingSpinnerComponent,
    CommonModule,
    I18NextModule,
    StoredDocumentUrlPipe,
    LoggedOutWrapperComponent,
    AvatarModule,
    ButtonModule,
    IconModule,
  ],
  providers: [
    AddSpacesToPhonePipe,
    AddSpacesToIbanPipe,
    FirstUpperCasePipe,
    DatePipe,
    StoredDocumentUrlPipe,
  ],
})
export class SharedDefaultModule {}
