import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GetEmploymentsForCompanyGQL,
  GetHoursExportGQL,
  QueryGetHoursExportArgs,
} from 'src/app/graphql/generated';
import { base64ToArrayBuffer } from '../../../shared/helpers/functions/base64ToBlob';

@Injectable({
  providedIn: 'root',
})
export class CompanyEmploymentService {
  hasDaysToApprove = new BehaviorSubject<boolean>(false);

  updateHasDaysToApprove = new BehaviorSubject<
    | { uuid: string; hasDaysToApprove: boolean; daysToApproveCount: number }
    | undefined
  >(undefined);

  constructor(
    private GetEmploymentsForCompanyGQL: GetEmploymentsForCompanyGQL,
    private GetHoursExportGQL: GetHoursExportGQL,
  ) {}

  async exportHoursReport(
    variables: QueryGetHoursExportArgs,
  ): Promise<ArrayBuffer> {
    return firstValueFrom(
      this.GetHoursExportGQL.fetch(variables).pipe(
        map((res) => {
          return base64ToArrayBuffer(res.data['getHoursExport']);
        }),
      ),
    );
  }

  getEmployments$() {
    return this.GetEmploymentsForCompanyGQL.fetch(
      {},
      { fetchPolicy: 'no-cache' },
    ).pipe(
      map((res) =>
        res.data.Employments
          // only return employments with employees
          ?.filter((e) => !!e.employee),
      ),
      shareReplay(1),
    );
  }
}
