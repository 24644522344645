import {
  DaysFormArray,
  EmploymentEditForm,
} from '../../../../pages/employments/employment-types/employment-form';
import { EmploymentUpdateData } from '../../../../models/types';
import { EmploymentFragment } from '../../../../graphql/generated';

export function getEmploymentFormChanges(
  employment: EmploymentFragment,
  employmentEditForm: EmploymentEditForm,
  daysFormArray: DaysFormArray,
): EmploymentUpdateData | false {
  let hasChanges = false;
  const updateData: EmploymentUpdateData = {
    employmentUuid: employment.uuid,
    days: [],
    hoursEntries: [],
  };
  if (employmentEditForm.invalid) return false;

  for (const dayFormGroup of daysFormArray.controls) {
    if (!dayFormGroup?.dirty) {
      continue;
    }
    const dayData = employment.days.find(
      (item): boolean => item.date === dayFormGroup.controls.date.value,
    );
    if (dayData) {
      hasChanges = true;
      updateData.days?.push(dayData);

      for (const hoursEntryFormGroup of dayFormGroup.controls.hoursEntries
        .controls) {
        if (hoursEntryFormGroup?.dirty) {
          const hoursEntryData = dayData.hoursEntries.find(
            (item) => item.uuid === hoursEntryFormGroup.controls.uuid.value,
          );

          if (!hoursEntryData) {
            continue;
          }

          if (hoursEntryFormGroup.controls.from.disabled) {
            hoursEntryData.from = null;
          }
          if (hoursEntryFormGroup.controls.to.disabled) {
            hoursEntryData.to = null;
          }
          if (!hoursEntryData.pause) {
            hoursEntryData.pause = null;
          }
          updateData.hoursEntries?.push(hoursEntryData);
        }
      }
    }
  }
  if (hasChanges) {
    return updateData;
  } else {
    return false;
  }
}
