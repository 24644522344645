import { hourEntryHasData } from './employment-input/hourEntryHasData';
import { DayFragment } from '../../../graphql/generated';

export function dayIsEmpty(day: DayFragment): boolean {
  const hourEntriesAreEmpty = day.hoursEntries.every(
    (entry) => !hourEntryHasData(entry),
  );
  return (
    hourEntriesAreEmpty &&
    !day.absenceUUID &&
    !day.expenseUUID &&
    !day.reportUUID
  );
}
