import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../models/shared/user/user.service';
import { ActivatedRoute } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import i18next from 'i18next';

@Component({
  selector: 'app-page-error',
  templateUrl: './error.component.html',
  styleUrls: ['../page-not-found/page-not-found.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy {
  title = 'pageNotFound';
  errorDescription = '';
  destroyed$ = new Subject<void>();
  constructor(public userService: UserService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams
      .pipe(
        takeUntil(this.destroyed$),
        filter((params) => {
          return params['error_description'];
        }),
      )
      .subscribe(async (params) => {
        if (params['lang']) {
          const lang = params['lang'].slice(0, 2);
          if (lang !== i18next.language) await i18next.changeLanguage(lang);
        }

        if (params['error_description'] === 'Access expired.') {
          this.title = 'error_description.accessExpired';
          this.errorDescription = 'error_description.accessExpiredHint';
        } else if (
          params['error_description'] === 'This URL can be used only once'
        ) {
          this.title = 'error_description.linkOnlyValidOnce';
          this.errorDescription = 'error_description.linkOnlyValidOnceHint';
        } else if (params['error_description']) {
          this.title = params['error_description'];
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
