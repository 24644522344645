import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-logged-out-wrapper',
  templateUrl: './logged-out-wrapper.component.html',
  styleUrls: ['./logged-out-wrapper.component.scss'],
})
export class LoggedOutWrapperComponent {
  @Input() type: 'form-box' | 'text-content' = 'text-content';

  constructor() {}
}
