import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  EmploymentFragment,
  ValueCurrency,
} from 'src/app/graphql/generated';
import { CollapseDirective } from '@intemp/unijob-ui';
import { getExpensesTotal } from '../../../shared/helpers/functions/getExpensesTotal';
import { parseDuration } from '../../../shared/helpers/functions/parseDuration';

@Component({
  selector: 'app-employment-statistics',
  templateUrl: './employment-statistics.component.html',
  styleUrls: ['./employment-statistics.component.scss'],
})
export class EmploymentStatisticsComponent implements AfterViewInit, OnChanges {
  @Input({ required: true }) employment!: EmploymentFragment;
  @ViewChild(CollapseDirective) collapse?: CollapseDirective;

  isCollapsed = false;
  totalExpenses: ValueCurrency[] = [];
  constructor() {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.collapse?.collapsedSubject.subscribe((isCollapsed) => {
        this.isCollapsed = isCollapsed;
      });
    });
  }

  parseDuration(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    if (minutes === 0) {
      return '0h';
    }
    return parseDuration(minutes, 'm');
  }

  calculateExpensesTotal() {
    if (this.employment.statistics?.expenses) {
      this.totalExpenses = getExpensesTotal(
        this.employment.statistics.expenses,
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.employment) {
      this.calculateExpensesTotal();
    }
  }
}
