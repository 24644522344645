import { Component, inject, OnInit } from '@angular/core';
import { UserService } from '../../models/shared/user/user.service';
import { BuildInfoService } from '../../core/services/build-infos.service';
import { combineLatest } from 'rxjs';
import { ModalModule, ModalService } from '@intemp/unijob-ui';
import { I18NextModule } from 'angular-i18next';
import { EmploymentBaseModule } from '../../pages/employments/employments.base.module';
import { RouterLink } from '@angular/router';
import semver from 'semver/preload';
import { clickUpLink } from '../constants/clickup-link';

@Component({
  selector: 'app-version',
  standalone: true,
  templateUrl: './app-version.component.html',
  imports: [ModalModule, I18NextModule, EmploymentBaseModule, RouterLink],
})
export class AppVersionComponent implements OnInit {
  userService = inject(UserService);
  buildInfoService = inject(BuildInfoService);
  modalService = inject(ModalService);

  link = clickUpLink;

  currentVersion = '';
  modalId = 'app-version-modal';

  ngOnInit() {
    combineLatest([
      this.userService.readyUser$,
      this.buildInfoService.getBuildInfo(),
    ]).subscribe(([user, buildInfo]) => {
      const buildTag = buildInfo.tag;
      this.currentVersion = semver.clean(buildTag) ?? '';

      const isMajorUpdate = this.isMajorUpdate(this.currentVersion);
      if (!isMajorUpdate) return;

      const lastSeenVersion = user.lastSeenVersion;

      if (this.currentVersion !== lastSeenVersion) {
        this.modalService.open(this.modalId);
      } else {
        this.modalService.close(this.modalId);
      }
    });
  }

  isMajorUpdate(currentVersion: string) {
    if (!currentVersion) {
      return false;
    }
    const minor = semver.minor(currentVersion);
    const patch = semver.patch(currentVersion);
    return !(minor !== 0 || patch !== 0);
  }

  updateLastSeenVersion() {
    if (!this.currentVersion) return;
    this.userService
      .updateUser$({
        lastSeenVersion: this.currentVersion,
      })
      .subscribe();
  }
}
