import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ModalComponent,
  ModalService,
  ToastService,
  ResponsiveService,
} from '@intemp/unijob-ui';
import { SelectOption } from '@intemp/unijob-ui/lib/components/select-input/select-input.component';
import { I18NextPipe } from 'angular-i18next';
import { getUnixTime, startOfDay, addMonths } from 'date-fns';
import { firstValueFrom } from 'rxjs';
import {
  AbsenceDurationEntryFragment,
  AbsenceEnum,
  DayStatusEnum,
  DocumentStatusEnum,
  DocumentTypeEnum,
  DurationEntryInput,
  DurationEntryTypeEnum,
  EmploymentFragment,
  StoredDocument,
} from 'src/app/graphql/generated';
import { DocumentService } from '../../../models/shared/document/document.service';
import { EmploymentService } from '../../../models/uniweb/employment/employment.service';
import { storedDocumentToJsFile } from '../../../shared/helpers/documents/storedDocumentToJsFile';
import { getUtcDate } from '../../../shared/helpers/functions/date-helpers/getUtcDate';
import { parseDayDateString } from '../../../shared/helpers/functions/date-helpers/parseDayDateString';
import { randomId } from '../../../shared/helpers/functions/randomId';
import { validateAllFormFields } from '../../../shared/helpers/functions/validateAllFormFields';
import { UserService } from '../../../models/shared/user/user.service';
import { getAbsenceOptions } from '../../../shared/helpers/functions/employment-input/getAbsenceOptions';
import { customValidators } from '../../../shared/modules/shared-forms/customValidators';

@Component({
  selector: 'app-absence-duration-modal',
  templateUrl: './absence-duration-modal.component.html',
})
export class AbsenceDurationModalComponent implements OnChanges, AfterViewInit {
  @Input({ required: true }) modalId!: string;
  @Input({ required: true }) employment!: EmploymentFragment;
  @Input() absence: Partial<AbsenceDurationEntryFragment> | undefined;
  @Input() isCompanyUser = false;

  @Output() modalClosed = new EventEmitter();

  allDaysHaveOpenStatus = true;

  timeSpanControl = new FormControl<Date[] | undefined>(undefined, [
    customValidators.bothDatesAreRequired,
  ]);
  typeControl = new FormControl<AbsenceEnum | undefined>(undefined, [
    Validators.required,
  ]);
  noteControl = new FormControl<string | undefined>(undefined);
  documentFilesControl = new FormControl<File[] | null>([]);

  modals: ModalService;
  modal?: ModalComponent;
  modalIsOpen = false;
  loadingDocument = false;
  absenceDurationForm = new FormGroup({
    absenceTimespan: this.timeSpanControl,
    absenceType: this.typeControl,
    absenceNote: this.noteControl,
    documentFiles: this.documentFilesControl,
  });
  storedDocument: StoredDocument | null = null;

  absenceOptions: SelectOption[] = getAbsenceOptions(this.i18nPipe);
  isNewEntry = true;
  loggedInUserEmail: string | undefined;
  canEditAbsence = true;
  isOwnEntry = true;
  canAdjustAbsence = false;
  isAdjustment = false;
  unsavedChangesModalId = 'absences-unsaved-changes-modal' + randomId();

  deleteEntryModalId = 'delete-entry-modal' + randomId();

  //previousVersion
  previousTimeSpanControl = new FormControl<Date[] | undefined>(undefined);
  previousTypeControl = new FormControl<AbsenceEnum | undefined>(undefined);
  previousNoteControl = new FormControl<string | undefined>(undefined);
  previousDocumentFilesControl = new FormControl<File[] | null>(null);
  previousAbsenceDurationForm = new FormGroup({
    previousAbsenceTimespan: this.previousTimeSpanControl,
    previousAbsenceType: this.previousTypeControl,
    previousAbsenceNote: this.previousNoteControl,
    previousDocumentFiles: this.previousDocumentFilesControl,
  });

  isSmDown$ = this.responsiveService.isSmDown$;

  constructor(
    private employmentService: EmploymentService,
    private toastService: ToastService,
    private modalService: ModalService,
    private i18nPipe: I18NextPipe,
    private documentService: DocumentService,
    private i18Pipe: I18NextPipe,
    private userService: UserService,
    private responsiveService: ResponsiveService,
  ) {
    this.modals = modalService;
    this.userService.user$?.subscribe((user) => {
      if (user) {
        this.loggedInUserEmail = user.profile.email;
      }
    });
  }

  ngAfterViewInit(): void {
    if (!this.modal) {
      this.modal = this.modals.modals.find(
        (modal) => modal.id === this.modalId,
      );
    }
    this.modal?.isOpen.subscribe((isOpen) => {
      this.modalIsOpen = isOpen;
    });
  }

  fileChanged(files: File[]): void {
    if (!files?.length) {
      this.storedDocument = null;
      return;
    } else {
      let uuid = this.absence?.document?.uuid;
      if (!uuid) {
        uuid = randomId();
      }
      const file = files[0];
      this.storedDocument = {
        uuid: uuid,
        fileName: file.name,
        mimeType: file.type,
        type: DocumentTypeEnum.OTHER,
        status: DocumentStatusEnum.READ,
        _id: null,
        companyId: null,
        createdAt: null,
        data: null,
        deletedAt: null,
        description: null,
        notifiedAt: null,
        personalNumber: null,
        title: null,
        translatedDocumentId: null,
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isFirstChange = changes.absence?.firstChange;
    const currentValue = changes.absence?.currentValue;
    if (isFirstChange) {
      this.reset();
    } else if (!isFirstChange && currentValue) {
      this.prepareModal(currentValue);
    }
  }

  prepareModal(absence: Partial<AbsenceDurationEntryFragment>) {
    this.patchForm(absence);
    this.prepareDay();
    this.setPermissions();
    this.disableOrEnableForm(this.absenceDurationForm, this.canEditAbsence);

    if (absence.previousVersion) {
      this.patchPreviousForm(absence.previousVersion);
      this.disableOrEnableForm(this.previousAbsenceDurationForm, false);
    }
  }

  patchForm(absence: Partial<AbsenceDurationEntryFragment>) {
    if (absence.createdAt) {
      this.isNewEntry = false;
    }
    if (absence.startDate && absence.endDate) {
      const startDate = startOfDay(new Date(absence.startDate));
      const endDate = startOfDay(new Date(absence.endDate));
      this.timeSpanControl.setValue([startDate, endDate]);
    }

    this.typeControl.setValue(absence.kind ?? null);
    this.noteControl.setValue(absence.note);

    if (absence.document?.uuid) {
      this.loadingDocument = true;
      this.patchDocumentControl(absence.document.uuid)
        .then((file) => {
          if (file) {
            this.documentFilesControl.setValue([file]);
          }
        })
        .finally(() => (this.loadingDocument = false));
    }
  }

  patchPreviousForm(
    previousVersion: AbsenceDurationEntryFragment['previousVersion'],
  ) {
    if (!previousVersion) return;
    if (previousVersion.startDate && previousVersion.endDate) {
      const startDate = startOfDay(new Date(previousVersion.startDate));
      const endDate = startOfDay(new Date(previousVersion.endDate));
      this.previousTimeSpanControl.setValue([startDate, endDate]);
    }

    this.previousTypeControl.setValue(previousVersion.kind ?? null);
    this.previousNoteControl.setValue(previousVersion.note);

    if (previousVersion.document?.uuid) {
      this.loadingDocument = true;
      this.patchDocumentControl(previousVersion.document.uuid)
        .then((file) => {
          if (file) {
            this.previousDocumentFilesControl.setValue([file]);
          }
        })
        .finally(() => (this.loadingDocument = false));
    }
  }

  async patchDocumentControl(documentUuid: string): Promise<File | undefined> {
    const storedDocument =
      await this.documentService.getStoredDocument(documentUuid);
    if (!storedDocument) return;
    return storedDocumentToJsFile(storedDocument);
  }

  prepareDay() {
    const formDates = this.timeSpanControl.value;
    if (!formDates || formDates.length < 2) return;
    const startDate = formDates[0];
    const endDate = formDates[1];

    this.allDaysHaveOpenStatus = this.oneOfDaysStatusIsNotOpen([
      startDate,
      endDate,
    ]);
  }

  setPermissions() {
    this.isOwnEntry = this.loggedInUserEmail === this.absence?.createdBy?.email;

    this.canEditAbsence =
      this.isNewEntry || (this.isOwnEntry && this.allDaysHaveOpenStatus);

    this.canAdjustAbsence =
      this.isCompanyUser &&
      !this.isOwnEntry &&
      this.absence?.createdAt !== undefined;
  }

  disableOrEnableForm(formGroup: FormGroup, enableControl: boolean): void {
    if (enableControl) {
      formGroup.enable();
    } else {
      formGroup.disable();
    }
  }

  addAdjustment(): void {
    this.isAdjustment = true;

    const previousVersion = structuredClone(
      this.absence as AbsenceDurationEntryFragment,
    ); // type assertion because absence is not partial in this state

    if (this.absence && !this.absence.previousVersion) {
      delete (previousVersion as Partial<AbsenceDurationEntryFragment>)
        .previousVersion;
      // add previous version to the absence
      this.absence.previousVersion = previousVersion;
      // remove document from the current absence to allow for a new one
      this.absence.document = null;
    }

    this.previousAbsenceDurationForm.patchValue({
      previousAbsenceTimespan: [
        new Date(previousVersion.startDate),
        new Date(previousVersion.endDate),
      ],
      previousAbsenceType: previousVersion.kind,
      previousAbsenceNote: previousVersion.note,
      previousDocumentFiles: this.documentFilesControl.value,
    });

    this.documentFilesControl.setValue([]);

    this.disableOrEnableForm(this.absenceDurationForm, true);
    this.disableOrEnableForm(this.previousAbsenceDurationForm, false);
  }

  oneOfDaysStatusIsNotOpen(dates: Date[]): boolean {
    const unixDates = dates.map((date) => getUnixTime(startOfDay(date)));
    const days = this.employment?.days?.filter((employmentDay): boolean => {
      const dayUnix = getUnixTime(parseDayDateString(employmentDay.date));
      return dayUnix >= unixDates[0] && dayUnix <= unixDates[1];
    });
    return !days?.some((day) => day.status !== DayStatusEnum.OPEN);
  }

  datePickerDayDisabledFunction(): (day: any) => boolean {
    return (dayDate: Date): boolean => {
      if (!this.employment) return false;
      const dayData = this.employment?.days?.find((employmentDay): boolean => {
        return (
          getUnixTime(parseDayDateString(employmentDay.date)) ===
          getUnixTime(startOfDay(dayDate))
        );
      });
      if (dayData?.absenceUUID) {
        // dont disable selected days
        if (this.absence?.uuid && dayData?.absenceUUID === this.absence.uuid) {
          return false;
        }

        // disable other days with absences
        return true;
      }
      const afterToday =
        getUnixTime(startOfDay(dayDate)) > getUnixTime(startOfDay(new Date()));

      if (afterToday) return true;

      const dayIsBeforeEmploymentStart =
        getUnixTime(startOfDay(dayDate)) <
        getUnixTime(parseDayDateString(this.employment?.startDate));

      if (dayIsBeforeEmploymentStart) return true;

      if (this.employment.endDate) {
        const endDate = addMonths(new Date(this.employment.endDate), 1);
        const dayIsAfterEmploymentEnd = dayDate > endDate;
        if (dayIsAfterEmploymentEnd) return true;
      }

      // day does not exist yet
      if (!dayData) {
        return false;
      }

      return dayData.status !== DayStatusEnum.OPEN;
    };
  }

  deleteEntry() {
    if (
      !this.employment ||
      !this.absence ||
      !this.absence.uuid ||
      !this.absence.startDate ||
      !this.absence.endDate
    )
      return;
    const data: DurationEntryInput = {
      employmentId: this.employment.uuid,
      type: DurationEntryTypeEnum.ABSENCE,
      uuid: this.absence.uuid,
      delete: true,
      startDate: this.absence.startDate,
      endDate: this.absence.endDate,
    };

    firstValueFrom(this.employmentService.updateDurationEntry(data)).then(
      (): void => {
        this.toastService.makeToast({
          type: 'SUCCESS',
          message: this.i18Pipe.transform('entryDeleted'),
        });
        this.modalService.close(this.deleteEntryModalId);
        this.emitCloseModal();
      },
    );
  }

  adjustmentAreIdentical(): boolean {
    const absenceTimeSpanInput = this.timeSpanControl.value;
    if (!absenceTimeSpanInput) return false;

    const from = getUtcDate(startOfDay(absenceTimeSpanInput[0]));
    const to = getUtcDate(startOfDay(absenceTimeSpanInput[1]));

    const previousStartDate = this.absence?.previousVersion?.startDate;
    const previousEndDate = this.absence?.previousVersion?.endDate;

    const note = this.noteControl.value;
    const previousNote = this.absence?.previousVersion?.note;

    const kind = this.typeControl.value;
    const previousKind = this.absence?.previousVersion?.kind;

    const document = this.storedDocument?.uuid;
    const previousDocument = this.absence?.previousVersion?.document?.uuid;

    if (
      from.toISOString() === previousStartDate &&
      to.toISOString() === previousEndDate &&
      note === previousNote &&
      kind === previousKind &&
      document === previousDocument
    ) {
      return true;
    }

    return false;
  }

  async saveAbsence() {
    validateAllFormFields(this.absenceDurationForm);
    const absenceTimeSpanInput = this.timeSpanControl.value;
    if (this.absenceDurationForm.invalid) return;
    if (!absenceTimeSpanInput || !this.employment || !this.absence) return;

    const fromDate = absenceTimeSpanInput[0];
    const toDate = absenceTimeSpanInput[1];

    const from = getUtcDate(startOfDay(fromDate));
    const to = getUtcDate(startOfDay(toDate));

    if (this.isAdjustment) {
      const areIdentical = this.adjustmentAreIdentical();
      if (areIdentical) {
        this.toastService.makeToast({
          type: 'WARNING',
          message: this.i18Pipe.transform('noChangesMade'),
        });
        this.emitCloseModal();
        return;
      }
    }

    const absenceUUID = this.absence?.uuid ? this.absence.uuid : randomId();

    if (!this.absence?.createdAt) {
      this.absence.createdAt = new Date().toISOString();
      this.absence.startDate = from.toISOString();
      this.absence.endDate = to.toISOString();
      this.absence.kind = this.typeControl.value;
      this.absence.note = this.noteControl.value;
    }
    const data: DurationEntryInput = {
      uuid: absenceUUID,
      startDate: from.toISOString(),
      endDate: to.toISOString(),
      kind: this.typeControl.value,
      note: this.noteControl.value === '' ? undefined : this.noteControl.value,
      employmentId: this.employment.uuid,
      type: DurationEntryTypeEnum.ABSENCE,
    };
    if (this.storedDocument?.uuid) {
      data.file = {
        uuid: this.storedDocument?.uuid,
        file: this.documentFilesControl.value?.[0],
        documentType: this.storedDocument.type,
      };
    }

    if (this.absence.document && !this.documentFilesControl.value?.length) {
      data.deleteDocument = true;
    }

    if (this.absence.previousVersion) {
      data.previousVersion = {
        type: DurationEntryTypeEnum.ABSENCE,
        uuid: this.absence.previousVersion.uuid,
        startDate: this.absence.previousVersion.startDate,
        endDate: this.absence.previousVersion.endDate,
        kind: this.absence.previousVersion.kind,
        note: this.absence.previousVersion.note,
        document: this.absence.previousVersion.document?.uuid,
        createdAt: this.absence.previousVersion.createdAt,
        createdBy: this.absence.previousVersion.createdBy?.email,
      };
    }

    await this.updateDurationEntry(data);
  }

  async updateDurationEntry(data: DurationEntryInput) {
    await firstValueFrom(this.employmentService.updateDurationEntry(data)).then(
      () => {
        this.toastService.makeToast({
          message: this.i18Pipe.transform(
            this.isNewEntry ? 'uploadAbsenceSuccessful' : 'saved',
          ),
          type: 'SUCCESS',
        });
        this.emitCloseModal();
      },
    );
  }

  reset(): void {
    this.absence = {
      uuid: randomId(),
    };
    this.isNewEntry = true;
    this.loadingDocument = false;
    this.canEditAbsence = true;
    this.isOwnEntry = false;
    this.isAdjustment = false;
    this.storedDocument = null;
    this.allDaysHaveOpenStatus = true;
    this.absenceDurationForm.reset({}, { emitEvent: false });
    this.absenceDurationForm.enable();
    this.previousAbsenceDurationForm.reset({}, { emitEvent: false });
  }

  emitCloseModal(): void {
    this.modalClosed.emit();
    this.reset();
  }
  discardChanges(id: string): void {
    this.modalService.close(id);
    this.emitCloseModal();
  }

  failedToClose(): void {
    this.modalService.open(this.unsavedChangesModalId);
  }

  async saveChanges(): Promise<void> {
    this.modalService.close(this.unsavedChangesModalId);

    validateAllFormFields(this.absenceDurationForm);

    if (this.absenceDurationForm.invalid) {
      setTimeout(() => {
        this.toastService.makeToast({
          type: 'ERROR',
          message: this.i18nPipe.transform('pleaseFillAllTheRequiredFields'),
        });
      }, 500);
    } else {
      await this.saveAbsence();
    }
  }

  showDeletePrompt(): void {
    this.modalService.open(this.deleteEntryModalId);
  }
}
