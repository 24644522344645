<ng-container *ngIf="day && isInitialized && loadingFinished">
  <div class="uj-flex uj-flex-col">
    <div class="uj-flex uj-flex-col">
      <div class="uj-flex uj-justify-between sm:uj-pr-[52px]">
        <app-day-info
          [isCompanyUser]="isCompanyUser"
          [day]="day"></app-day-info>
        <span
          class="uj-flex uj-gap-4 uj-items-end uj-icon-grey"
          *ngIf="totalHours && totalHours !== '0'">
          <unijob-icon
            icon="clock_stop_watch"
            size="12"
            class="uj-pb-2"></unijob-icon>
          <span class="text-s-b uj-text-text">{{ totalHours }}</span>
        </span>
      </div>
      <ng-container *ngIf="showFromToPauseHeadings">
        <div class="uj-flex uj-pt-12 sm:uj-pr-[52px]">
          <div class="text-xs-b uj-text-text-grey uj-w-full">
            {{ "from" | i18next | firstUppercase }}
          </div>
          <div class="text-xs-b uj-text-text-grey uj-w-full">
            {{ "to" | i18next | firstUppercase }}
          </div>
          <div class="text-xs-b uj-text-text-grey uj-w-full">
            {{ "pause" | i18next | firstUppercase }}
          </div>
          <div class="uj-w-[40px] uj-flex-none sm:uj-w-[80px]"></div>
        </div>
      </ng-container>
      <div class="uj-flex uj-flex-col sm:uj-flex-row uj-gap-12">
        <ng-container
          *ngIf="day.status === dayStatusEnum.APPROVED || day.reportUUID; else openHours">
          <app-approved-day-list-item
            class="uj-flex-1"
            [day]="day"
            [dayIsEmpty]="dayIsEmpty.value"
            [sortedHoursEntries]="sortedHoursEntries"
            [employment]="employment"
            [isCompanyUser]="isCompanyUser"></app-approved-day-list-item>
        </ng-container>
        <ng-template #openHours>
          <div class="uj-flex-1 uj-flex uj-flex-col uj-gap-8">
            <div class="uj-flex uj-flex-col uj-gap-8">
              <ng-container *ngIf="!isCompanyUser; else companyUserHours">
                <ng-container *ngTemplateOutlet="employeeHours"></ng-container>
              </ng-container>
            </div>
            <div
              *ngIf="day.absenceUUID"
              class="uj-pt-4">
              <button
                (click)="editAbsence(day.absenceUUID)"
                class="!uj-w-full"
                unijob-button
                type="button"
                variant="outline"
                theme="flat"
                size="m"
                corner="rounded"
                iconLeft="travel_airplane_holiday">
                {{ "absence" | i18next | firstUppercase }}
              </button>
            </div>
            <div
              *ngIf="day.expenseUUID"
              class="uj-pt-12">
              <button
                (click)="editExpenses(day.expenseUUID)"
                class="!uj-w-full"
                unijob-button
                type="button"
                variant="outline"
                theme="flat"
                size="m"
                corner="rounded"
                iconLeft="money">
                {{ "expenses" | i18next | firstUppercase }}
              </button>
            </div>
          </div>
          <div
            class="uj-flex uj-justify-center sm:uj-block"
            *ngIf="day.hoursEntries && day.hoursEntries.length >= 1">
            <button
              unijob-button
              type="button"
              variant="primary"
              theme="flat"
              size="m"
              corner="rounded"
              iconOnly="plus"
              [contextMenu]="addButton"
              [horizontalAlignment]="
                (responsiveService.isSmDown$ | async) ? 'center' : 'right'
              "
              [verticalAlignment]="
                (responsiveService.isSmDown$ | async) ? 'top' : 'bottom'
              "
              contextMenuBgColor="bg-violet"
              btnColor="bg-violet"></button>
            <ng-template #addButton>
              <div
                class="uj-flex uj-flex-col uj-gap-4 uj-bg-brand-primary uj-rounded">
                <button
                  *ngFor="let option of addMenuOptions"
                  [iconLeft]="option.iconName"
                  (click)="option.action()"
                  class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
                  unijob-button
                  type="button"
                  variant="quaternary"
                  theme="flat"
                  size="m"
                  contentColor="content-color-white"
                  corner="rounded">
                  {{ option.label | i18next | firstUppercase }}
                </button>
              </div>
            </ng-template>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #employeeHours>
  <ng-container
    *ngFor="
      let entry of sortedHoursEntries.hoursEntry;
      let i = index;
      trackBy: trackByUuid
    ">
    <div>
      <ng-container *ngIf="sortedHoursEntries.pauseEntry[i]">
        <div class="uj-pb-8">
          <span class="unijob-divider size-xs border-color-grey">
            <div class="caption color-grey">
              <unijob-icon
                size="16"
                icon="coffee"></unijob-icon>
              <span class="text-xs"
                >{{ "pause" | i18next | firstUppercase }}
                {{ sortedHoursEntries.pauseEntry[i] }}</span
              >
            </div>
          </span>
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          !entry.createdBy || entry.createdBy.email === loggedInUserEmail;
          else adjustedHoursEntry
        ">
        <div class="uj-flex uj-gap-12">
          <div class="uj-flex-1">
            <div [class.uj-pt-0]="i === 0">
              <app-edit-hours-row
                *ngIf="formHoursEntries"
                [entry]="entry"
                [isDefaultEntry]="day.hoursEntries.length === 1"
                [formReferenceArray]="formHoursEntries"
                (validateOverlappingEntries)="validateDay()"
                (removeEntry)="removeEntry(entry)"></app-edit-hours-row>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #adjustedHoursEntry>
        <app-hours-row
          [entry]="entry"
          [isOwnEntry]="
            entry.createdBy.email === loggedInUserEmail
          "></app-hours-row>
      </ng-template>
    </div>
  </ng-container>
</ng-template>

<ng-template #companyUserHours>
  <ng-container
    *ngFor="
      let entry of sortedHoursEntries.hoursEntry;
      let i = index;
      trackBy: trackByUuid
    ">
    <div>
      <ng-container *ngIf="sortedHoursEntries.pauseEntry[i]">
        <div class="uj-pb-8">
          <span class="unijob-divider size-xs border-color-grey">
            <div class="caption color-grey">
              <unijob-icon
                size="16"
                icon="coffee"></unijob-icon>
              <span class="text-xs"
                >{{ "pause" | i18next | firstUppercase }}
                {{ sortedHoursEntries.pauseEntry[i] }}</span
              >
            </div>
          </span>
        </div>
      </ng-container>
      <div class="uj-flex uj-gap-12">
        <ng-container
          *ngIf="
            !entry.createdAt ||
              !entry.createdBy ||
              entry.createdBy.email === loggedInUserEmail;
            else staticHoursRow
          ">
          <app-edit-hours-row
            *ngIf="formHoursEntries"
            [entry]="entry"
            [formReferenceArray]="formHoursEntries"
            (validateOverlappingEntries)="validateDay()"
            (removeEntry)="removeEntry(entry)"></app-edit-hours-row>
        </ng-container>

        <ng-template #staticHoursRow>
          <app-hours-row
            [entry]="entry"
            [isCompanyUser]="isCompanyUser"
            [dayStatus]="day.status"
            [canAddAdjustment]="isCompanyUser"></app-hours-row>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-template>
