import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'addSpacesToIban' })
export class AddSpacesToIbanPipe implements PipeTransform {
  transform(iban: string): string {
    const ibanString = iban.replace(/\s/g, '');
    const ibanParts = [];
    for (let i = 0; i < ibanString.length; i = i + 4) {
      ibanParts.push(ibanString.slice(i, i + 4));
    }
    return ibanParts.join(' ');
  }
}
