import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ModalService,
  ResponsiveService,
  ToastService,
} from '@intemp/unijob-ui';
import { ModalComponent } from '@intemp/unijob-ui/lib/components/modal/modal.component';
import { I18NextPipe } from 'angular-i18next';
import { getUnixTime, startOfDay, addMonths } from 'date-fns';
import { lastValueFrom, startWith } from 'rxjs';
import {
  DayFragment,
  DayStatusEnum,
  DocumentTypeEnum,
  EmploymentFragment,
  ExpenseEntryInput,
  ExpenseEnum,
  ExpensesInput,
  FileUploadInput,
  StoredDocumentWithDataFragment,
  ValueCurrency,
  ValueCurrencyInput,
} from 'src/app/graphql/generated';
import { randomId } from 'src/app/shared/helpers/functions/randomId';
import { DocumentService } from '../../../models/shared/document/document.service';
import { EmploymentService } from '../../../models/uniweb/employment/employment.service';
import { storedDocumentToJsFile } from '../../../shared/helpers/documents/storedDocumentToJsFile';
import { getDayDateString } from '../../../shared/helpers/functions/date-helpers/getDayDateString';
import { parseDayDateString } from '../../../shared/helpers/functions/date-helpers/parseDayDateString';
import { parseValueCurrency } from '../../../shared/helpers/functions/parseValueCurrency';
import { validateAllFormFields } from '../../../shared/helpers/functions/validateAllFormFields';
import { UserService } from '../../../models/shared/user/user.service';
import { map } from 'rxjs/operators';
import { formControlValue$ } from '../../../shared/helpers/functions/formControlValue$';

type ExpenseFormGroup = {
  uuid: FormControl<string | null>;
  cost: FormControl<string | null>;
  document?: FormControl<StoredDocumentWithDataFragment | null>;
  documentFiles?: FormControl<File[] | null>;
  previousVersion?: FormGroup<ExpenseFormGroup>;
  createdAt?: FormControl<string | null>;
  createdByFullName?: FormControl<string | null>;

  documentUUID?: FormControl<string | null>;
  expense?: FormControl<ValueCurrencyInput | null>;
  file?: FormControl<FileUploadInput | null>;
  kind?: FormControl<ExpenseEnum | null>;
  note?: FormControl<string | null>;

  // custom for ui only
  isNewAdjustment?: FormControl<boolean | null>;
};

type ExpensesEntryPartial = Partial<EmploymentFragment['expenses'][number]> & {
  expenseDate?: string;
};

type ExpenseEntry = EmploymentFragment['expenses'][number]['expenses'][number];

type ExpenseEntryPreviousVersion =
  EmploymentFragment['expenses'][number]['expenses'][number]['previousVersion'];

@Component({
  selector: 'app-expenses-modal',
  templateUrl: './expenses-modal.component.html',
  styleUrls: ['./expenses-modal.component.scss'],
})
export class ExpensesModalComponent implements OnChanges, AfterViewInit {
  @Input({ required: true }) modalId!: string;
  @Input({ required: true }) employment!: EmploymentFragment;
  @Input() isCompanyUser = false;
  @Input() expensesEntry?: ExpensesEntryPartial;

  @Output() modalClosed = new EventEmitter();
  @Output() requestEmploymentUpdate = new EventEmitter();

  hasNewFile = false;

  isSmDown$ = this.responsiveService.isSmDown$;

  modals: ModalService;

  expensesEntriesControl = new FormArray<FormGroup<ExpenseFormGroup>>([]);
  expenseDateControl = new FormControl<Date[] | undefined>(undefined, [
    Validators.required,
  ]);

  expensesForm = new FormGroup({
    expenseDate: this.expenseDateControl,
    expensesEntries: this.expensesEntriesControl,
  });

  day?: DayFragment;

  modal?: ModalComponent;
  modalIsOpen = false;

  unsavedChangesModalId = 'expenses-unsaved-changes-modal';

  deleteEntryModalId = 'delete-entry-modal-' + randomId();

  loggedInUserEmail: string | undefined;

  loggedInUserFullName: string | undefined;

  canDeleteExpenses$ = formControlValue$(this.expensesEntriesControl).pipe(
    map(() => {
      if (!this.expensesEntry?.createdAt) return false;
      const hasOnlyHisOwnEntries = this.hasOnlyHisOwnEntries();
      if (hasOnlyHisOwnEntries) return true;
      return this.isCompanyUser;
    }),
  );

  dayStatusOpen = true;

  saveButtonIsDisabled$ = this.expensesForm.statusChanges.pipe(
    startWith('DISABLED'),
    map((status) => {
      const invalidStatus = status === 'INVALID' || status === 'DISABLED';
      const hasNoEntries = this.expensesEntriesControl.length === 0;
      const isNewEntry = !this.expensesEntry?.createdAt;
      return invalidStatus || (hasNoEntries && isNewEntry);
    }),
  );

  constructor(
    private employmentService: EmploymentService,
    private toastService: ToastService,
    private modalService: ModalService,
    private i18nPipe: I18NextPipe,
    private responsiveService: ResponsiveService,
    private documentService: DocumentService,
    private userService: UserService,
  ) {
    this.modals = modalService;
    this.userService.user$?.subscribe((user) => {
      if (user) {
        this.loggedInUserEmail = user.profile.email;
        this.loggedInUserFullName = `${user.profile.firstName} ${user.profile.lastName}`;
      }
    });
  }

  ngAfterViewInit(): void {
    if (!this.modal) {
      this.modal = this.modals.modals.find(
        (modal) => modal.id === this.modalId,
      );
    }
    this.modal?.isOpen.subscribe((isOpen) => {
      this.modalIsOpen = isOpen;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isFirstChange = changes.expensesEntry?.firstChange;
    const currentValue = changes.expensesEntry?.currentValue;
    if (isFirstChange) {
      this.reset();
    } else if (!isFirstChange && currentValue) {
      this.expensesEntriesControl.clear();
      this.addExpenseEntry(); // to add the default entry
      this.expensesForm.markAsPristine();
      this.prepareModal(currentValue);
    }
  }

  prepareModal(expensesEntry: ExpensesEntryPartial): void {
    this.patchForm(expensesEntry).then(() => {
      this.prepareDay(expensesEntry);
      this.disableOrEnableForm();
    });
  }

  async patchForm(expensesEntry: ExpensesEntryPartial) {
    if (expensesEntry.expenses && expensesEntry.expenses.length > 0) {
      this.expensesEntriesControl.clear(); // to clear the default entry
      for (const expenseEntry of expensesEntry.expenses) {
        await this.pushExpenseEntry(expenseEntry);
      }
    }
  }

  prepareDay(expensesEntry: ExpensesEntryPartial) {
    let date: Date | undefined;
    if (expensesEntry.expenseDate) {
      date = startOfDay(new Date(expensesEntry.expenseDate));
    }
    if (date) {
      const dateToSet = date;
      this.setDay([dateToSet]);
      setTimeout(() => {
        this.expenseDateControl.setValue([dateToSet]);
      });
    }
  }

  hasOnlyHisOwnEntries(): boolean {
    const allControls = this.expensesEntriesControl.controls;
    const hasPreviousVersion = allControls.some(
      (control) => control.controls.previousVersion,
    );
    if (hasPreviousVersion) return false;
    return allControls.every(
      (control) =>
        control.controls.createdByFullName?.value === this.loggedInUserFullName,
    );
  }

  disableEntries(): void {
    const allControls = this.expensesEntriesControl.controls;
    const notOwnedEntries = allControls.filter(
      (control) =>
        control.value.createdByFullName !== this.loggedInUserFullName,
    );
    for (const control of notOwnedEntries) {
      control.disable();
    }
  }

  disableOrEnableForm() {
    if (this.day?.status === DayStatusEnum.APPROVED) {
      this.expensesForm.disable();
    }
    this.disableEntries();
  }

  expensesEntries() {
    return this.expensesForm.controls['expensesEntries'];
  }

  trackByUuid(index: number, item: FormGroup<ExpenseFormGroup>): string {
    return item.value.uuid as string;
  }

  costValidation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isInvalid = false;
      if (control.value && control.status === 'VALID') {
        const parsedExpense: ValueCurrency = parseValueCurrency(control.value);
        if (parsedExpense.value === 0) {
          isInvalid = true;
        }
      }
      return isInvalid ? { invalid: true } : null;
    };
  }

  addExpenseEntry(): void {
    if (!this.loggedInUserFullName) {
      console.error('No user full name found');
      return;
    }
    this.expensesEntriesControl.push(
      new FormGroup<ExpenseFormGroup>({
        uuid: new FormControl(randomId()),
        note: new FormControl(null, {
          validators: [Validators.required],
        }),
        cost: new FormControl(null, {
          validators: [Validators.required, this.costValidation()],
        }),
        document: new FormControl<StoredDocumentWithDataFragment | null>(null, {
          validators: [],
        }),
        documentFiles: new FormControl<File[] | null>(null, {
          validators: [],
        }),
        createdByFullName: new FormControl<string | null>(
          this.loggedInUserFullName,
        ),
      }),
    );
  }

  setDay(date: Date[]) {
    if (!date) {
      return;
    }
    const day = this.employment?.days?.find((employmentDay): boolean => {
      return employmentDay.date === getDayDateString(date[0]);
    });
    if (day) {
      this.day = day;
      this.dayStatusOpen = day.status === DayStatusEnum.OPEN;
    }
  }

  async createNewEntryFromValues(
    expenseEntry: NonNullable<ExpenseEntryPreviousVersion>,
    addValidation?: boolean,
    isAdjusted?: boolean,
  ): Promise<FormGroup<ExpenseFormGroup>> {
    const cost = `${expenseEntry.expense.value} ${expenseEntry.expense.currency}`;
    let validators: ValidatorFn[] = [Validators.required];
    let costValidation: ValidatorFn[] = [
      Validators.required,
      this.costValidation(),
    ];
    if (addValidation === false) {
      validators = [];
      costValidation = [];
    }

    let storedDocumentWithDataFragment:
      | StoredDocumentWithDataFragment
      | undefined = undefined;
    if (expenseEntry.document) {
      storedDocumentWithDataFragment = await this.getStoredDocumentByUUID(
        expenseEntry.document.uuid,
      );
      expenseEntry.document = storedDocumentWithDataFragment || null;
    }

    const entry = new FormGroup<ExpenseFormGroup>({
      uuid: new FormControl(expenseEntry.uuid),
      note: new FormControl(expenseEntry.note || null, {
        validators: validators,
      }),
      document: new FormControl(storedDocumentWithDataFragment || null),
      cost: new FormControl(cost, {
        validators: costValidation,
      }),
      documentFiles: new FormControl(null),
    });

    if (isAdjusted) {
      entry.addControl('isNewAdjustment', new FormControl(true));
    }

    if (storedDocumentWithDataFragment?.data) {
      const file = storedDocumentToJsFile(storedDocumentWithDataFragment);
      entry.controls.documentFiles?.setValue([file]);
    }

    if (expenseEntry.createdBy) {
      const fullName = `${expenseEntry.createdBy.firstName} ${expenseEntry.createdBy.lastName}`;
      entry.addControl('createdByFullName', new FormControl(fullName));
    }

    return entry;
  }

  canEditEntry = (prevExpense: NonNullable<ExpenseEntryPreviousVersion>) => {
    return (
      this.isCompanyUser &&
      this.dayStatusOpen &&
      prevExpense.createdBy.firstName !== this.employment.employee?.firstName
    );
  };

  async pushExpenseEntry(expenseEntry: ExpenseEntry): Promise<void> {
    let entry: FormGroup<ExpenseFormGroup> | undefined;

    if (expenseEntry.previousVersion) {
      entry = await this.getPreviousVersionsEntryForm(
        expenseEntry,
        expenseEntry.previousVersion,
      );
    } else {
      entry = await this.createNewEntryFromValues(expenseEntry);
    }

    this.expensesEntriesControl.push(entry);
  }

  async getPreviousVersionsEntryForm(
    expenseEntry: ExpenseEntry,
    previousVersion: NonNullable<ExpenseEntryPreviousVersion>,
  ): Promise<FormGroup<ExpenseFormGroup>> {
    let entry: FormGroup<ExpenseFormGroup> | undefined = undefined;

    const createdByLatest = `${expenseEntry.createdBy?.firstName} ${expenseEntry.createdBy?.lastName}`;

    const canEditLatestVersion = this.canEditEntry(expenseEntry);

    if (this.isCompanyUser) {
      entry = await this.createNewEntryFromValues(expenseEntry, true, true);
    } else {
      entry = await this.createNewEntryFromValues(expenseEntry, true);
    }

    let storedDocumentWithDataFragment:
      | StoredDocumentWithDataFragment
      | undefined;
    if (expenseEntry.document) {
      storedDocumentWithDataFragment = await this.getStoredDocumentByUUID(
        expenseEntry.document.uuid,
      );
      expenseEntry.document = storedDocumentWithDataFragment || null;
    }

    entry.setControl(
      'document',
      new FormControl(storedDocumentWithDataFragment || null),
    );

    if (storedDocumentWithDataFragment?.data) {
      const file = storedDocumentToJsFile(storedDocumentWithDataFragment);
      entry.controls.documentFiles?.setValue([file]);
    }

    if (canEditLatestVersion) {
      setTimeout(() => {
        entry?.enable();
      });
    } else {
      setTimeout(() => {
        entry?.disable();
      });
    }

    const createdBy = `${previousVersion.createdBy.firstName} ${previousVersion.createdBy.lastName}`;

    if (!this.isCompanyUser && createdByLatest !== createdBy) {
      entry.addControl('createdByFullName', new FormControl(createdByLatest));
      entry.addControl('createdAt', new FormControl(expenseEntry.createdAt));
    }

    const canEditPreVersions = this.canEditEntry(previousVersion);

    const preCost = `${previousVersion.expense.value} ${previousVersion.expense.currency}`;
    const pre = new FormGroup<ExpenseFormGroup>({
      uuid: new FormControl(previousVersion.uuid),
      note: new FormControl(previousVersion.note || null),
      cost: new FormControl(preCost),
    });

    if (!this.isCompanyUser && createdByLatest !== createdBy) {
      pre.addControl('createdByFullName', new FormControl(createdBy));
      pre.addControl('createdAt', new FormControl(previousVersion.createdAt));
    }

    if (!canEditPreVersions) {
      setTimeout(() => {
        pre.disable();
      });
    }

    entry.controls.previousVersion = pre;

    return entry;
  }

  getStoredDocumentByUUID = async (
    uuid: string,
  ): Promise<StoredDocumentWithDataFragment | undefined> => {
    return lastValueFrom(this.documentService.getStoredDocument$(uuid));
  };

  datePickerDayDisabledFunction(): (dayDate: Date) => boolean {
    return (dayDate: Date): boolean => {
      const dayData = this.employment?.days?.find((employmentDay): boolean => {
        return (
          getUnixTime(parseDayDateString(employmentDay.date)) ===
          getUnixTime(startOfDay(dayDate))
        );
      });

      if (dayData?.expenseUUID) {
        // dont disable selected day
        if (
          this.expensesEntry?.uuid &&
          dayData?.expenseUUID === this.expensesEntry.uuid
        ) {
          return false;
        }

        // disable other days with expenses
        return true;
      }

      const afterToday =
        getUnixTime(startOfDay(dayDate)) > getUnixTime(startOfDay(new Date()));

      if (afterToday) return true;

      const dayIsBeforeEmploymentStart =
        getUnixTime(startOfDay(dayDate)) <
        getUnixTime(parseDayDateString(this.employment?.startDate));

      if (dayIsBeforeEmploymentStart) return true;

      if (this.employment.endDate) {
        const endDate = addMonths(new Date(this.employment.endDate), 1);
        const dayIsAfterEmploymentEnd = dayDate > endDate;
        if (dayIsAfterEmploymentEnd) return true;
      }

      // day does not exist yet
      if (!dayData) {
        return false;
      }

      return dayData.status !== DayStatusEnum.OPEN;
    };
  }

  adjustEntry(index: number): void {
    const entry = this.expensesEntriesControl.controls[
      index
    ] as FormGroup<ExpenseFormGroup>;
    const previousUUID = JSON.parse(JSON.stringify(entry.controls.uuid.value));
    const previousNote = entry.controls.note?.value
      ? JSON.parse(JSON.stringify(entry.controls.note.value))
      : undefined;
    const previousCost = JSON.parse(JSON.stringify(entry.controls.cost.value));

    const previousVersion = new FormGroup<ExpenseFormGroup>({
      uuid: new FormControl<string>(previousUUID),
      note: new FormControl<string>({
        value: previousNote,
        disabled: true,
      }),
      cost: new FormControl({ value: previousCost, disabled: true }),
    });
    previousVersion.disable();

    entry.controls.isNewAdjustment = new FormControl(true);
    entry.controls.previousVersion = previousVersion;

    entry.controls.uuid.setValue(randomId());
    entry.controls.cost.enable();
    entry.controls.note?.enable();
    entry.controls.cost.setValidators([
      Validators.required,
      this.costValidation(),
    ]);
    entry.controls.note?.setValidators([Validators.required]);

    entry.markAsTouched();
    entry.markAsPristine();
  }

  deleteEntry(index: number): void {
    const control = this.expensesEntriesControl.controls[index];
    const isNewAdjustment = control.get('isNewAdjustment')?.value;
    const entryUUID = control.get('uuid')?.value;
    if (isNewAdjustment && entryUUID) {
      this.deleteAdjustment(index, entryUUID);
    } else {
      this.expensesEntriesControl.removeAt(index);
    }
  }

  deleteAdjustment(index: number, entryUUID: string): void {
    const existingEntry = this.employment.expenses.find((expense) =>
      expense.expenses.some((e) => e.uuid === entryUUID),
    );
    const expenseEntry = existingEntry?.expenses.find(
      (e) => e.uuid === entryUUID,
    );

    const hasPreviousVersion = expenseEntry?.previousVersion;

    if (!existingEntry || hasPreviousVersion) {
      this.restoreEntry(index);
    } else {
      this.expensesEntriesControl.removeAt(index);
    }
  }

  restoreEntry(index: number): void {
    const newVersion = this.expensesEntriesControl.controls[index];
    const entryToRestore = newVersion.controls.previousVersion;
    if (!entryToRestore) {
      console.error('Missing entry to restore');
      return;
    }
    const uuid = structuredClone(entryToRestore.controls.uuid.value);
    const note = structuredClone(entryToRestore.controls.note?.value);
    const cost = structuredClone(entryToRestore.controls.cost.value);
    const document = structuredClone(newVersion.controls.document?.value);
    const originalVersion = new FormGroup<ExpenseFormGroup>({
      uuid: new FormControl(uuid),
      note: new FormControl(
        {
          value: note || null,
          disabled: true,
        },
        { validators: [Validators.required] },
      ),
      cost: new FormControl(
        { value: cost, disabled: true },
        { validators: [Validators.required, this.costValidation()] },
      ),
      document: new FormControl(document || null),
      documentFiles: newVersion.controls.documentFiles,
    });
    this.expensesEntriesControl.removeAt(index);
    this.expensesEntriesControl.controls.splice(index, 0, originalVersion);
  }

  isDisabled(index: number): boolean {
    if (index === 0 && !this.isCompanyUser) {
      return !this.expensesEntriesControl.controls[index].valid;
    }
    return false;
  }

  allFieldsAreInvalid(index: number): boolean {
    const formGroup = this.expensesEntriesControl.controls[index] as FormGroup;
    return (
      formGroup.controls.note?.invalid &&
      formGroup.controls.note?.touched &&
      formGroup.controls.cost?.invalid &&
      formGroup.controls.cost?.touched
    );
  }

  costInputUpdate = (index: number): any => {
    const formGroup = this.expensesEntriesControl.controls[index] as FormGroup;
    if (formGroup.controls.cost && formGroup.controls.cost.valid) {
      const parsedExpense: ValueCurrency = parseValueCurrency(
        formGroup.controls.cost.value,
      );
      formGroup.controls.cost.setValue(
        parsedExpense.value + ' ' + parsedExpense.currency,
      );
    }
  };

  async deleteAllEntries() {
    this.expensesEntriesControl.clear();
    this.modalService.close(this.deleteEntryModalId);
    await this.saveExpenses('delete');
  }

  fileChange(files: File[], index: number): void {
    if (files?.length === 0) {
      this.expensesEntriesControl.controls[index]?.controls?.document?.setValue(
        null,
      );
    }
  }

  async getExpenseEntryValues(
    control: FormGroup<ExpenseFormGroup>,
  ): Promise<ExpensesInput | undefined> {
    const cost = control.controls.cost.value;
    const uuid = control.controls.uuid.value;
    const note = control.controls.note?.value;
    const files = control.controls.documentFiles?.value;
    if (!cost || !uuid || !note) {
      console.error('Missing expense entry values');
      return;
    }
    const parsedExpense: ValueCurrency = parseValueCurrency(cost);
    if (parsedExpense.value === 0) {
      console.error('Invalid expense value-->', parsedExpense.value);
      return;
    }

    const input: ExpensesInput = {
      uuid: uuid,
      expense: {
        value: parsedExpense.value,
        currency: parsedExpense.currency,
      },
      note,
    };

    if (files?.length) {
      const file = files[0];
      const uuid = control.controls.document?.value?.uuid;
      input.file = {
        uuid: uuid || randomId(),
        file: file,
        documentType: DocumentTypeEnum.OTHER,
      };
    }

    return input;
  }

  async saveExpenses(action: 'create' | 'update' | 'delete'): Promise<void> {
    if (this.expensesForm.invalid) {
      return;
    }
    const date = this.expenseDateControl.value?.[0];
    if (!date) return console.error('date is missing');

    const data: ExpenseEntryInput = {
      employmentId: this.employment.uuid,
      uuid: this.expensesEntry?.uuid ?? randomId(),
      dayDate: getDayDateString(date),
      expenses: [],
    };

    if (this.isCompanyUser) {
      await this.companyUserSaving(data);
    } else {
      for (const entryControl of this.expensesEntriesControl.controls) {
        const expenseInput = await this.getExpenseEntryValues(entryControl);
        if (!expenseInput) continue;
        data.expenses.push(expenseInput);
      }
    }

    this.employmentService.updateExpensesEntry(data).subscribe((): void => {
      this.modals.close(this.modalId);
      this.requestEmploymentUpdate.emit();
      this.toastService.makeToast({
        message: this.i18nPipe.transform(
          action === 'create'
            ? 'createExpensesSuccessful'
            : action === 'update'
              ? 'updateExpensesSuccessful'
              : 'deleteExpensesSuccessful',
        ),
        type: 'SUCCESS',
      });
    });
  }

  async companyUserSaving(expenseEntryInput: ExpenseEntryInput) {
    for (const expenseEntryControl of this.expensesEntriesControl.controls) {
      const expenseInput =
        await this.getExpenseEntryValues(expenseEntryControl);
      if (!expenseInput) {
        console.error('Missing expense input');
        continue;
      }

      const previousVersionUUID =
        expenseEntryControl.controls.previousVersion?.controls.uuid.value;

      if (previousVersionUUID) {
        expenseInput.previousVersion = {
          uuid: previousVersionUUID,
        };
      }
      expenseEntryInput.expenses.push(expenseInput);
    }
  }

  reset() {
    this.expensesEntry = {
      uuid: randomId(),
      expenseDate: undefined,
      expenses: [],
    };
    this.hasNewFile = false;
    this.expensesEntriesControl.clear();
    this.expensesForm.reset({});
    this.addExpenseEntry();
    this.expensesForm.markAsPristine();
  }

  closeModal(): void {
    this.reset();
    this.modalClosed.emit();
  }

  discardChanges(id: string): void {
    this.modalService.close(id);
    this.closeModal();
  }

  failedToClose(): void {
    this.modalService.open(this.unsavedChangesModalId);
  }

  async saveChanges(): Promise<void> {
    this.modalService.close(this.unsavedChangesModalId);

    validateAllFormFields(this.expensesForm);

    if (this.expensesForm.invalid) {
      setTimeout(() => {
        this.toastService.makeToast({
          type: 'ERROR',
          message: this.i18nPipe.transform('pleaseResolveErrorsInOrderToSave'),
        });
      }, 500);
    } else {
      await this.saveExpenses(
        this.expensesEntry?.createdAt ? 'update' : 'create',
      );
    }
  }

  showDeletePrompt(): void {
    this.modalService.open(this.deleteEntryModalId);
  }
}
