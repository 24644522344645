import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutePermissionKeyEnum } from './models/shared/user/user.service';
import { LanguageSwitchComponent } from './pages/language-switch/language-switch.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AuthGuardService } from './core/services/auth-guard.service';
import { ErrorComponent } from './pages/error/error.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    canMatch: [AuthGuardService],
    data: {
      routePermissionKey: RoutePermissionKeyEnum.DASHBOARD,
    },
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
  },
  {
    path: 'employments',
    canMatch: [AuthGuardService],
    data: {
      routePermissionKey: RoutePermissionKeyEnum.EMPLOYMENTS,
    },
    loadChildren: () =>
      import('./pages/employments/employments.module').then(
        (m) => m.EmploymentsModule,
      ),
  },
  {
    path: 'employees',
    canMatch: [AuthGuardService],
    data: {
      routePermissionKey: RoutePermissionKeyEnum.EMPLOYEES,
    },
    loadChildren: () =>
      import('./pages/employees/employees.module').then(
        (m) => m.EmployeesModule,
      ),
  },
  {
    path: 'documents',
    canMatch: [AuthGuardService],
    loadChildren: () =>
      import('./pages/documents/documents.module').then(
        (m) => m.DocumentsModule,
      ),
  },
  {
    path: 'profile',
    canMatch: [AuthGuardService],
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'contact',
    canMatch: [AuthGuardService],
    data: {
      routePermissionKey: RoutePermissionKeyEnum.CONTACT,
    },
    loadChildren: () =>
      import('./pages/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'export',
    canMatch: [AuthGuardService],
    data: {
      routePermissionKey: RoutePermissionKeyEnum.EXPORT,
    },
    loadChildren: () =>
      import('./pages/export/export.module').then((m) => m.ExportModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'logout',
    redirectTo: '/login?logout=1',
    pathMatch: 'full',
  },
  {
    path: 'register',
    redirectTo: '/login?register=1',
    pathMatch: 'full',
  },
  {
    path: 'policy',
    loadChildren: () =>
      import('./pages/policy/policy.module').then((m) => m.PolicyModule),
  },
  {
    path: 'support',
    loadChildren: () =>
      import('./pages/support/support.module').then((m) => m.SupportModule),
  },
  {
    path: 'language',
    canMatch: [AuthGuardService],
    component: LanguageSwitchComponent,
    pathMatch: 'full',
  },
  {
    path: 'benefits',
    canMatch: [AuthGuardService],
    loadChildren: () =>
      import('./pages/benefits/benefits.module').then((m) => m.BenefitsModule),
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
