<unijob-modal
  [id]="modalId"
  size="s"
  (closeClicked)="updateLastSeenVersion()">
  <ng-container slot="header">
    {{ "newFunctionsAndChanges" | i18next | firstUppercase }} 🚀
  </ng-container>
  <ng-container slot="content">
    {{ "newFunctionsAndChangesDescription" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col sm:uj-flex-row uj-w-full uj-gap-8 uj-justify-end">
      <a
        [href]="link"
        target="_blank"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap !uj-text-white"
        unijob-button
        type="button"
        variant="primary"
        theme="flat"
        size="m"
        corner="rounded"
        btnColor="bg-pink"
        iconRight="chevron_right"
        contentColor="content-color-white">
        {{ "whatsNew" | i18next | firstUppercase }}
      </a>
    </div>
  </ng-container>
</unijob-modal>
