import { parseDecimalToTimeString } from './parseDecimalToTimeString';

export function parseDayTime(time: string): string {
  if (time) {
    // remove unexpected characters
    let transformedTime = time.replace(new RegExp('([^0-9:.,-])', 'g'), '');

    const hasDecimal = time.indexOf('.') !== -1 || time.indexOf(',') !== -1;
    if (hasDecimal && transformedTime.length > 2) {
      transformedTime = parseDecimalToTimeString(transformedTime);
      return transformedTime;
    }

    // remove multiple separators
    transformedTime = transformedTime.replace(
      new RegExp('([:.,-].*?)[:.,-].*', 'g'),
      '$1',
    );

    // replace all separators with :
    transformedTime = transformedTime.replace(new RegExp('[.,-]', 'g'), ':');

    const separatorIndex = transformedTime.indexOf(':');

    if (separatorIndex !== -1) {
      const hoursStringPart = transformedTime.slice(0, separatorIndex);
      const minutesStringPart = transformedTime.slice(
        separatorIndex + 1,
        transformedTime.length,
      );
      const hours =
        '' + (hoursStringPart ? parseInt(hoursStringPart, 10) : '0');
      const minutes = increaseTo2Digits(minutesStringPart);
      return hours + ':' + minutes;
    } else {
      // if has no separator dump anything after the 4th char
      transformedTime = transformedTime.slice(0, 4);

      if (transformedTime.length < 3) {
        if (parseInt(transformedTime, 10) < 24) {
          // typed full hour number (9 -> 9:00)
          transformedTime = transformedTime + ':00';
        } else {
          transformedTime = '00:' + transformedTime;
        }
      } else {
        transformedTime =
          transformedTime.slice(0, transformedTime.length - 2) +
          ':' +
          transformedTime.slice(
            transformedTime.length - 2,
            transformedTime.length,
          );
      }

      if (transformedTime === '00:') {
        transformedTime = '';
      }
    }
    return transformedTime;
  } else {
    return time;
  }
}

function increaseTo2Digits(numberString: string): string {
  // removes prepending zeroes with parseInt and prepends one zero on one digit numbers (<10)
  if (numberString) {
    return (
      (parseInt(numberString, 10) < 10 ? '0' : '') +
      (parseInt(numberString, 10) ?? '00')
    );
  } else {
    return '00';
  }
}
