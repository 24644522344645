import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollableFormErrorComponent } from './scrollable-form-error/scrollable-form-error.component';
import { SharedDefaultModule } from '../shared-default/shared-default.module';

@NgModule({
  declarations: [ScrollableFormErrorComponent],
  imports: [FormsModule, ReactiveFormsModule, SharedDefaultModule],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    ScrollableFormErrorComponent,
  ],
})
export class SharedFormsModule {}
