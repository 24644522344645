<div
  class="unijob-card size-sm tw-bg-white uj-px-20 uj-py-8"
  *ngIf="employment && employment.statistics">
  <div
    (click)="collapseTarget.toggle()"
    class="uj-w-full uj-flex uj-justify-between uj-cursor-pointer uj-transition-[padding] uj-duration-300"
    [class.uj-border-b-2]="!isCollapsed"
    [class.uj-pb-8]="!isCollapsed"
    [class.uj-border-border]="!isCollapsed">
    <h3 class="button-text-m">{{ "statistics" | i18next | firstUppercase }}</h3>
    <unijob-icon [icon]="isCollapsed ? 'chevron_down' : 'chevron_up'">
    </unijob-icon>
  </div>
  <div class="uj-flex uj-col-auto"></div>
  <div
    class="uj-flex uj-flex-col uj-gap-8"
    [class.uj-pt-12]="!isCollapsed"
    collapse
    #collapseTarget="collapse"
    [collapseSpeed]="300"
    [collapsedSize]="0"
    collapseDirection="vertical"
    [initialCollapse]="false">
    <div class="uj-flex md:uj-flex-col">
      <div
        class="uj-flex uj-flex-col uj-flex-1 uj-border-b-2 uj-border-border uj-pb-12">
        <h3 class="text-s-b uj-text-text-grey">
          {{ "totalHours" | i18next | firstUppercase }}
        </h3>
        <span class="text-l-b">
          {{ parseDuration(employment.statistics.totalHours) }}
        </span>
      </div>
      <div
        class="uj-flex uj-flex-col uj-flex-1 uj-border-b-2 uj-border-border uj-pb-12 md:uj-pt-12">
        <h3 class="text-s-b uj-text-text-grey">
          {{ "vacationReferred" | i18next | firstUppercase }}
        </h3>
        <span class="text-l-b">
          {{ employment.statistics.vacationDays }}
          {{ "days" | i18next | firstUppercase }}
        </span>
      </div>
    </div>
    <div class="uj-flex md:uj-flex-col">
      <div
        class="uj-flex uj-flex-col uj-flex-1 uj-pb-12 md:uj-border-b-2 md:uj-border-border">
        <h3 class="text-s-b uj-text-text-grey">
          {{ "absent" | i18next | firstUppercase }}
        </h3>
        <span class="text-l-b">
          {{ employment.statistics.absences }}
          {{ "days" | i18next | firstUppercase }}
        </span>
      </div>
      <div class="uj-flex uj-flex-col uj-flex-1 uj-pb-12 md:uj-pt-12">
        <h3 class="text-s-b uj-text-text-grey">
          {{ "totalExpenses" | i18next | firstUppercase }}
        </h3>
        <ng-container
          *ngIf="totalExpenses?.length; else noExpenses"></ng-container>
        <ng-container *ngFor="let expense of totalExpenses">
          <span class="text-l-b">
            <ng-container>
              {{ expense?.value + " " + expense.currency }}
            </ng-container>
          </span>
        </ng-container>
        <ng-template #noExpenses>
          <span class="text-l-b"> 0 CHF </span>
        </ng-template>
      </div>
    </div>
  </div>
</div>
