<div
  (click)="editEmployment()"
  class="unijob-card interactable uj-flex"
  [ngClass]="{
    'uj-flex-row size-sm': view === 'LIST',
    'uj-flex-col size-md uj-gap-[57px]': view === 'TILES'
  }"
  [class]="view.toLowerCase() + '-view'">
  <div
    *ngIf="employment.company"
    [ngClass]="
      view === 'LIST'
        ? 'uj-flex-row uj-flex-1 uj-gap-12 '
        : 'uj-flex-col uj-gap-8'
    "
    class="uj-flex uj-justify-start uj-items-center">
    <unijob-avatar
      interactive="false"
      content="letters"
      [letters]="employment.company.contact.name | slice : 0 : 2"
      [size]="view === 'TILES' ? 'm' : 's'"
      backgroundColor="grey">
    </unijob-avatar>
    <div
      [class.uj-text-center]="view === 'TILES'"
      [class.uj-text-left]="view === 'LIST'"
      [class.uj-w-full]="view === 'TILES'"
      class="title-s uj-text-text uj-truncate uj-w-0 uj-flex-1"
      [attr.title]="employment.company.contact.name">
      {{ employment.company.contact.name }}
    </div>
  </div>
  <div
    [ngClass]="view === 'LIST' ? 'uj-flex-row' : 'uj-flex-col uj-gap-8'"
    class="uj-flex uj-justify-center uj-items-center">
    <button
      unijob-button
      [class.uj-mb-12]="view === 'TILES'"
      [class.uj-mr-8]="view === 'LIST'"
      variant="quaternary"
      class="open-info-modal-button"
      size="m"
      [iconLeft]="view === 'LIST' ? undefined : 'info_circle'"
      [iconOnly]="view === 'LIST' ? 'info_circle' : undefined"
      contentColor="content-color-violet"
      (click)="modals.open(infoModalId); $event.stopPropagation()">
      {{ "showDetails" | i18next | firstUppercase }}
    </button>
    <button
      unijob-button
      type="button"
      variant="primary"
      theme="flat"
      size="m"
      corner="rounded"
      iconLeft="clock_stop_watch"
      [iconOnly]="
        view === 'LIST' && (isSmDown$ | async) ? 'clock_stop_watch' : undefined
      "
      btnColor="bg-violet"
      class="uj-justify-center">
      <span class="hours-button-text">
        <ng-container *ngIf="employment?.active">
          {{
            (employment.isTimeTrackingEnabled ? "inputHours" : "enterReport")
              | i18next
              | firstUppercase
          }}
        </ng-container>
        <ng-container *ngIf="!employment?.active">
          {{ "showHours" | i18next | firstUppercase }}
        </ng-container>
      </span>
    </button>
  </div>
</div>

<unijob-modal
  [id]="infoModalId"
  size="s">
  <ng-container slot="header">
    <span>
      {{ employment.company?.contact?.name }}
    </span>
  </ng-container>
  <ng-container slot="content">
    <div class="uj-flex uj-flex-col uj-gap-20">
      <div class="uj-flex uj-flex-col uj-gap-4">
        <div class="title-xxs">
          {{ "assignment" | i18next | firstUppercase }}
        </div>
        <div class="uj-flex uj-flex-col">
          <span *ngIf="employment.startDate">
            {{ "start" | i18next | firstUppercase }}:
            <span class="employment-start-info">{{
              employment.startDate | date
            }}</span>
          </span>
          <span *ngIf="employment.endDate">
            {{ "end" | i18next | firstUppercase }}:
            <span class="employment-end-info">{{
              employment.endDate | date
            }}</span>
          </span>
        </div>
      </div>
      <div class="uj-flex uj-flex-col uj-gap-4">
        <div class="title-xxs">
          {{ "address" | i18next | firstUppercase }}
        </div>
        <div
          class="uj-flex uj-flex-col"
          *ngIf="employment.company">
          <span>{{ employment.company.contact.address.street }}</span>
          <span>{{ employment.company.contact.address.zip }}</span>
          <span>{{ employment.company.contact.address.location }}</span>
        </div>
      </div>
      <button
        unijob-button
        type="button"
        variant="outline"
        iconLeft="map"
        theme="flat"
        size="m"
        corner="rounded"
        bgColor="bg-white"
        contentColor="content-color-violet"
        class="map-button !uj-w-full"
        (click)="openMap()">
        {{ "showMap" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
  <ng-container slot="footer">
    <button
      unijob-button
      type="button"
      variant="primary"
      theme="flat"
      size="m"
      corner="rounded"
      btnColor="bg-pink"
      class="modal-close-button"
      (click)="modals.close(infoModalId)">
      {{ "close" | i18next | firstUppercase }}
    </button>
  </ng-container>
</unijob-modal>
