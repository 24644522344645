<unijob-sheet
  [id]="sheetId"
  size="l"
  [preventClosing]="!!profileForm?.dirty"
  (closePrevented)="failedToClose()"
  (sheetClosed)="closeSheet()">
  <ng-container slot="header">
    <h1 class="title-m">
      {{ "profile" | i18next | firstUppercase }}
    </h1>
  </ng-container>
  <ng-container slot="content">
    <form
      class="uj-flex uj-flex-col uj-min-h-full"
      *ngIf="user() as user"
      [formGroup]="profileForm"
      (ngSubmit)="onSubmit()">
      <div
        class="uj-flex-col uj-flex-grow md:uj-flex-row uj-flex uj-justify-between md:uj-gap-54">
        <div
          class="card-wrapper uj-mb-20 md:uj-mb-0 uj-w-full md:uj-max-w-[320px]">
          <h2 class="title-xs uj-my-12">
            {{ "personalDetails" | i18next | firstUppercase }}
          </h2>
          <div class="unijob-card size-md rounded-md tw-bg-white uj-pt-34">
            <unijob-avatar
              class="uj-flex uj-justify-center"
              [content]="user.profile.image ? 'image' : 'letters'"
              [imageUrl]="
                user.profile.image
                  ? (user.profile.image | storedDocumentUrl)
                  : ''
              "
              [letters]="
                user.profile.firstName.charAt(0) +
                user.profile.lastName.charAt(0)
              "
              size="l">
            </unijob-avatar>
            <div class="title-s uj-my-12 uj-text-center">
              {{ user.profile.firstName }} {{ user.profile.lastName }}
            </div>
            <div
              class="upload-button"
              [formGroup]="profileImageForm">
              <button
                unijob-text-button
                class="profile-image-upload-text-button uj-relative uj-w-full uj-mb-4"
                type="button"
                size="M"
                iconLeft="camera"
                contentColor="accent">
                <ng-container *ngIf="user.profile.image">
                  {{ "changePicture" | i18next | firstUppercase }}
                </ng-container>
                <ng-container *ngIf="!user.profile.image">
                  {{ "uploadPicture" | i18next | firstUppercase }}
                </ng-container>
                <input
                  type="file"
                  accept="image/*"
                  class="upload-input"
                  name="uploadUserImage"
                  (change)="openCropperModal($event)"
                  formControlName="image" />
              </button>
              <button
                *ngIf="user.profile.image"
                unijob-text-button
                (click)="removeProfileImage()"
                class="profile-image-upload-text-button uj-w-full uj-mb-4"
                type="button"
                size="M"
                iconLeft="trash"
                contentColor="accent">
                <ng-container>
                  {{ "removePicture" | i18next | firstUppercase }}
                </ng-container>
              </button>
            </div>
            <div class="uj-mt-20 uj-mb-12">
              <label
                class="text-m-b uj-mb-4 tw-block"
                [for]="'firstname'">
                {{ "firstName" | i18next | firstUppercase }}
              </label>
              <unijob-input
                inputId="firstname"
                name="firstname"
                elevation="box-outline"
                formControlName="firstName"
                inputType="text"
                bgColor="bg-white"
                size="size-m"></unijob-input>
            </div>

            <div class="uj-mb-12">
              <label
                class="text-m-b uj-mb-4 tw-block"
                [for]="'lastname'">
                {{ "lastName" | i18next | firstUppercase }}
              </label>
              <unijob-input
                inputId="lastname"
                name="lastname"
                elevation="box-outline"
                formControlName="lastName"
                inputType="text"
                bgColor="bg-white"
                size="size-m"></unijob-input>
            </div>
            <div class="uj-mb-12">
              <label
                class="text-m-b uj-mb-4 tw-block"
                [for]="'phone'">
                {{ "phone" | i18next | firstUppercase }}
              </label>
              <unijob-input
                inputId="phone"
                name="phone"
                elevation="box-outline"
                formControlName="phone"
                inputType="tel"
                bgColor="bg-white"
                size="size-m"></unijob-input>
            </div>
            <div class="uj-mb-12">
              <label
                class="text-m-b uj-mb-4 tw-block"
                [for]="'email'">
                {{ "email" | i18next | firstUppercase }}
              </label>
              <unijob-input
                inputId="email"
                name="email"
                elevation="box-outline"
                formControlName="email"
                inputType="email"
                bgColor="bg-white"
                size="size-m"></unijob-input>
            </div>
          </div>
        </div>
        <div class="card-wrapper uj-w-full md:uj-max-w-[542px] md:uj-w-1/2">
          <h2 class="title-xs uj-mb-12 md:uj-mt-12">
            {{ "address" | i18next | firstUppercase }}
          </h2>
          <div
            class="unijob-card size-md rounded-md tw-bg-white uj-mb-34"
            [formGroup]="addressForm">
            <div class="uj-mb-12">
              <label
                class="text-m-b uj-mb-4 tw-block"
                [for]="'street'">
                {{ "street" | i18next | firstUppercase }}
              </label>
              <unijob-input
                inputId="street"
                name="street"
                elevation="box-outline"
                formControlName="street"
                inputType="text"
                bgColor="bg-white"
                size="size-m"></unijob-input>
            </div>
            <div class="uj-flex uj-gap-8 uj-mb-12">
              <div class="uj-w-[120px]">
                <label
                  class="text-m-b uj-mb-4 tw-block"
                  [for]="'zip'">
                  {{ "zip" | i18next | firstUppercase }}
                </label>
                <unijob-input
                  inputId="zip"
                  name="zip"
                  elevation="box-outline"
                  formControlName="zip"
                  inputType="text"
                  bgColor="bg-white"
                  size="size-m"></unijob-input>
              </div>
              <div class="uj-grow">
                <label
                  class="text-m-b uj-mb-4 tw-block"
                  [for]="'location'">
                  {{ "location" | i18next | firstUppercase }}
                </label>
                <unijob-input
                  inputId="location"
                  name="location"
                  elevation="box-outline"
                  formControlName="location"
                  inputType="text"
                  bgColor="bg-white"
                  size="size-m"></unijob-input>
              </div>
            </div>
            <div class="uj-mb-12">
              <label
                class="text-m-b uj-mb-4 tw-block"
                [for]="'country'">
                {{ "country" | i18next | firstUppercase }}
              </label>
              <unijob-input
                inputId="country"
                name="country"
                elevation="box-outline"
                formControlName="country"
                inputType="text"
                bgColor="bg-white"
                size="size-m"></unijob-input>
            </div>
          </div>
          <h2 class="title-xs uj-mb-12">
            {{ "bankingInfo" | i18next | firstUppercase }}
          </h2>
          <div class="unijob-card size-md rounded-md tw-bg-white uj-mb-34">
            <div class="uj-flex uj-mb-12">
              <unijob-icon
                class="uj-mr-4 uj-mt-2"
                size="12"
                icon="info_circle"></unijob-icon>
              <div class="text-s">
                Deine Bankinformationen werden für die Lohnauszahlung benötigt.
              </div>
            </div>
            <div
              class="uj-mb-12 uj-w-full uj-mb-12"
              [formGroup]="bankingInformationForm">
              <label
                class="text-m-b uj-mb-4 tw-block"
                [for]="'iban'">
                {{ "iban" | i18next | firstUppercase }}
              </label>
              <unijob-input
                inputId="iban"
                name="iban"
                elevation="box-outline"
                formControlName="iban"
                inputType="text"
                bgColor="bg-white"
                size="size-m"
                [errorMessage]="
                  !ibanControl.valid ? ('invalidIBAN' | i18next) : ''
                "></unijob-input>
            </div>
            <div [formGroup]="recipientForm">
              <unijob-checkbox
                formControlName="deviatingRecipient"
                label="{{ 'deviatingRecipient' | i18next | firstUppercase }}"
                [inverted]="false"
                colorTheme="white"
                elevation="outline"
                [withIntermediateState]="false"></unijob-checkbox>
              <ng-container *ngIf="deviatingRecipientControl.value">
                <div class="uj-mt-12 uj-mb-12">
                  <label
                    class="text-m-b uj-mb-4 tw-block"
                    [for]="'banking-firstname'">
                    {{ "firstName" | i18next | firstUppercase }}
                  </label>
                  <unijob-input
                    inputId="banking-firstname"
                    name="banking-firstname"
                    elevation="box-outline"
                    formControlName="firstName"
                    inputType="text"
                    bgColor="bg-white"
                    size="size-m"></unijob-input>
                </div>
                <div class="uj-mb-12">
                  <label
                    class="text-m-b uj-mb-4 tw-block"
                    [for]="'banking-lastname'">
                    {{ "lastName" | i18next | firstUppercase }}
                  </label>
                  <unijob-input
                    inputId="banking-lastname"
                    name="banking-lastname"
                    elevation="box-outline"
                    formControlName="lastName"
                    inputType="text"
                    bgColor="bg-white"
                    size="size-m"></unijob-input>
                </div>
                <div class="uj-mb-12">
                  <label
                    class="text-m-b uj-mb-4"
                    [for]="'banking-street'">
                    {{ "street" | i18next | firstUppercase }}
                  </label>
                  <unijob-input
                    inputId="banking-street"
                    name="banking-street"
                    elevation="box-outline"
                    formControlName="street"
                    inputType="text"
                    bgColor="bg-white"
                    size="size-m"></unijob-input>
                </div>
                <div class="uj-flex uj-gap-8 uj-mb-12">
                  <div class="uj-w-[120px]">
                    <label
                      class="text-m-b uj-mb-4 tw-block"
                      [for]="'banking-zip'">
                      {{ "zip" | i18next | firstUppercase }}
                    </label>
                    <unijob-input
                      inputId="banking-zip"
                      name="banking-zip"
                      elevation="box-outline"
                      formControlName="zip"
                      inputType="text"
                      bgColor="bg-white"
                      size="size-m"></unijob-input>
                  </div>
                  <div class="uj-grow">
                    <label
                      class="text-m-b uj-mb-4 tw-block"
                      [for]="'banking-location'">
                      {{ "location" | i18next | firstUppercase }}
                    </label>
                    <unijob-input
                      inputId="banking-location"
                      name="banking-location"
                      elevation="box-outline"
                      formControlName="location"
                      inputType="text"
                      bgColor="bg-white"
                      size="size-m"></unijob-input>
                  </div>
                </div>
                <div class="uj-mb-12">
                  <label
                    class="text-m-b uj-mb-4 tw-block"
                    [for]="'banking-country'">
                    {{ "country" | i18next | firstUppercase }}
                  </label>
                  <unijob-input
                    inputId="banking-country"
                    name="banking-country"
                    elevation="box-outline"
                    formControlName="country"
                    inputType="text"
                    bgColor="bg-white"
                    size="size-m"></unijob-input>
                </div>
              </ng-container>
            </div>
          </div>
          <h2 class="title-xs uj-mb-12">
            {{ "personalDocuments" | i18next | firstUppercase }}
          </h2>
          <div class="unijob-card size-md rounded-md tw-bg-white">
            <div class="files">
              <div class="text-m">
                {{ "uploadFilesHint" | i18next | firstUppercase }}
                <ul class="files-list uj-mb-20">
                  <li>
                    {{ "ahvCard" | i18next }}
                  </li>
                  <li>
                    {{ "possiblyPermit" | i18next }}
                  </li>
                  <li>
                    {{ "possiblyOther" | i18next }}
                  </li>
                </ul>
                <h3 class="text-m-b uj-mb-4">{{ "documentType" | i18next }}</h3>
                <div class="uj-mb-8">
                  <ng-template
                    [ngTemplateOutlet]="documentTypeSelector"></ng-template>
                </div>
              </div>
              <unijob-input-file
                formControlName="personalDocuments"
                inputId="personalDocumentsFileUpload"
                multiple
                name="personalDocuments"
                [namePrefix]="
                  newFileType
                    ? ('documentTypesEnum.' + newFileType.toString() | i18next)
                    : ''
                "
                compression
                [fileTypes]="['image/*', '.pdf', '.doc', '.docx']"
                [maxFileSize]="2000000"
                [errorMessage]="'uploadError' | i18next"
                [errorFileTooLargeMessage]="'fileToLargeError' | i18next"
                [showError]="personalDocumentsError"
                [disabledLabel]="
                  'pleaseSelectDocumentType' | i18next | firstUppercase
                "
                [uploadTitle]="'dropDocumentHere' | i18next | firstUppercase"
                [uploadLabel]="'chooseDocument' | i18next | firstUppercase"
                [uploadDescription]="'acceptedFileTypes' | i18next"
                [compressionLabel]="'beingCompressed' | i18next"
                [errorCompressionMessage]="'imageCompressionError' | i18next">
              </unijob-input-file>
              <div class="uj-flex uj-flex-col uj-gap-4 uj-mt-34">
                <h3 class="text-m-b uj-mb-4">
                  {{ "uploadedDocuments" | i18next }}
                </h3>
                @for (doc of storedDocuments(); track doc.uuid) {
                  <div
                    (click)="openDocument(doc)"
                    class="unijob-card size-sm rounded-sm uj-flex uj-gap-4 uj-justify-between interactable">
                    <div class="uj-flex uj-flex-col uj-gap-4 uj-truncate">
                      <span class="text-m-b uj-truncate">
                        {{ doc.fileName }}
                      </span>
                      <span class="text-s">{{
                        doc.createdAt | date: "dd.MM.yyyy - HH:mm"
                      }}</span>
                    </div>
                    <div class="uj-flex uj-gap-4">
                      <button
                        unijob-button
                        variant="quaternary"
                        theme="flat"
                        size="s"
                        type="button"
                        iconOnly="download"></button>
                      <button
                        unijob-button
                        type="button"
                        variant="quaternary"
                        theme="flat"
                        size="s"
                        corner="rounded"
                        bgColor="bg-white"
                        iconOnly="trash"
                        (click)="
                          $event.stopPropagation(); deleteFile(doc.uuid)
                        "></button>
                    </div>
                  </div>
                } @empty {
                  <span class="text-s uj-text-grey">{{
                    "noDocumentsUploaded" | i18next
                  }}</span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="uj-sticky uj-bottom-0 uj-z-10 uj-flex uj-justify-center"
        *ngIf="formHasChanges | async">
        <button
          unijob-button
          type="submit"
          variant="primary"
          theme="flat"
          size="m"
          corner="rounded"
          btnColor="bg-pink"
          contentColor="content-color-white">
          {{ "saveChanges" | i18next | firstUppercase }}
        </button>
      </div>
    </form>
    <ng-template #documentTypeSelector>
      <unijob-select
        [options]="documentTypeOptions"
        [(ngModel)]="newFileType"
        (ngModelChange)="filetypeSelected($event)"
        placeholder=" {{ 'selectDocumentType' | i18next | firstUppercase }}"
        elevation="box-outline"
        [allowEmptyValue]="false"
        bgColor="bg-white"
        name="city"
        size="size-m"></unijob-select>
    </ng-template>
  </ng-container>
</unijob-sheet>

<unijob-modal
  [id]="cropImageModalId"
  (modalOpened)="resetProfileImageFormControl()">
  <ng-container slot="header">
    {{ "cropProfileImage" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    <div class="cropper-ratio-wrapper">
      <div
        class="cropper-box"
        id="customCroppie"></div>
      <div class="rotate-left-button">
        <button
          unijob-button
          type="button"
          variant="outline"
          iconOnly="rotate_counter_clockwise"
          contentColor="content-color-pink"
          (click)="rotateCroppingImageLeft()"></button>
      </div>
    </div>
  </ng-container>
  <ng-container slot="footer">
    <div class="modal-actions-row uj-justify-end">
      <button
        class="uj-mr-8"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        size="m"
        corner="rounded"
        bgColor="bg-white"
        contentColor="content-color-violet"
        (click)="
          modals.close(cropImageModalId); resetProfileImageFormControl()
        ">
        <span class="text-m-b">
          {{ "cancel" | i18next | firstUppercase }}
        </span>
      </button>
      <button
        unijob-button
        type="button"
        variant="primary"
        theme="flat"
        size="m"
        corner="rounded"
        btnColor="bg-pink"
        (click)="modals.close('crop-profile-image-modal'); saveCroppedImage()">
        <span class="text-m-b">
          {{ "save" | i18next | firstUppercase }}
        </span>
      </button>
    </div>
  </ng-container>
</unijob-modal>

<unijob-modal
  [id]="unsavedChangesModalId"
  size="s">
  <ng-container slot="header">
    {{ "unsavedChangesTitle" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="content">
    {{ "unsavedChangesConfirmation" | i18next | firstUppercase }}
  </ng-container>
  <ng-container slot="footer">
    <div
      class="uj-flex uj-flex-col sm:uj-flex-row uj-w-full uj-gap-8 uj-justify-end">
      <button
        (click)="discardChanges()"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="outline"
        theme="flat"
        size="m"
        iconLeft="trash"
        contentColor="content-color-dark-grey"
        corner="rounded">
        {{ "discardChanges" | i18next | firstUppercase }}
      </button>
      <button
        (click)="saveAndClose()"
        class="!uj-w-full sm:!uj-w-auto uj-whitespace-nowrap"
        unijob-button
        type="button"
        variant="primary"
        theme="flat"
        size="m"
        corner="rounded"
        btnColor="bg-pink"
        contentColor="content-color-white">
        {{ "save" | i18next | firstUppercase }}
      </button>
    </div>
  </ng-container>
</unijob-modal>
