import {
  DayFragment,
  EmploymentFragment,
} from 'src/app/graphql/generated';
import { ValueCurrency } from 'src/app/graphql/generated';
import { getExpensesEntry } from './getExpensesEntry';

export function getDaysExpensesTotal(
  days: DayFragment[],
  employment: EmploymentFragment,
): ValueCurrency[] {
  return getExpensesTotal(getDaysExpensesEntries(days, employment));
}

export function getExpensesTotal(expenses: ValueCurrency[]): ValueCurrency[] {
  const totals: ValueCurrency[] = [];
  expenses.forEach((expense) => {
    if (expense?.value && expense?.currency) {
      const expenseCurrencyIndex = totals.findIndex(
        (valueCurrency) => valueCurrency.currency === expense.currency,
      );
      if (expenseCurrencyIndex !== -1) {
        totals[expenseCurrencyIndex].value += expense.value;
      } else {
        totals.push(Object.assign({}, expense));
      }
    }
  });
  totals.map((expenseTotal: ValueCurrency) => {
    expenseTotal.value = Math.round(expenseTotal.value * 100) / 100;
    return expenseTotal;
  });
  return totals;
}

export function getDaysExpensesEntries(
  days: DayFragment[],
  employment: EmploymentFragment,
): ValueCurrency[] {
  return days.reduce((acc: ValueCurrency[], day) => {
    if (day.expenseUUID) {
      const dayExpenses = getExpensesEntry(
        employment,
        day.expenseUUID,
      )?.expenses;
      if (dayExpenses.length) {
        acc.push(...dayExpenses.map((e) => e.expense));
      }
    }
    return acc;
  }, []);
}
