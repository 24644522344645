import {
  HoursEntryFragment,
  HoursEntryInput,
} from '../../../../graphql/generated';

export function getHoursEntryInput(entry: HoursEntryFragment): HoursEntryInput {
  const inputEntry: HoursEntryInput = {
    uuid: entry.uuid,
    note: entry.note,
    from: entry.from,
    to: entry.to,
    pause: entry.pause,
    duration: entry.duration,
    delete: !!entry.deletedAt,
  };
  if (entry.previousVersions) {
    inputEntry.previousVersions = entry.previousVersions.map((prevEntry) => ({
      uuid: prevEntry.uuid,
    }));
  }
  return inputEntry;
}
