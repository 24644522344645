import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { DOCUMENT } from '@angular/common';
import i18next from 'i18next';
import { UpdateUserGQL } from 'src/app/graphql/generated';
import { UserService } from '../../models/shared/user/user.service';
import { SupportedLanguage } from 'src/app/graphql/generated';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private router: Router,
    private apollo: Apollo,
    public userService: UserService,
    @Inject(DOCUMENT) private document: Document,
    private UpdateUserGQL: UpdateUserGQL,
  ) {
    this.document.documentElement.lang = this.getActiveLanguage();
  }

  defaultLanguage: SupportedLanguage = SupportedLanguage.DE;
  languageWhitelist: SupportedLanguage[] = [
    SupportedLanguage.EN,
    SupportedLanguage.DE,
    SupportedLanguage.FR,
  ];

  setLanguage(isoCode: string, force = false) {
    if (force || this.getActiveLanguage() !== isoCode) {
      this.document.documentElement.lang = isoCode;
      this.router
        .navigate(['language'], {
          queryParams: { redirect: this.router.url, language: isoCode },
        })
        .then();
    }
  }

  applyLanguage(isoCode: string): void {
    localStorage.setItem('lang', isoCode);
    this.updateUserLanguage(isoCode).subscribe();
    i18next.changeLanguage(isoCode.toLowerCase()).then();
  }

  getActiveLanguage(): string {
    const lang = localStorage.getItem('lang');
    const browserLanguage = navigator.language.split('-')?.[0]?.toUpperCase();
    return (
      lang ||
      (this.languageWhitelist.includes(browserLanguage as SupportedLanguage)
        ? browserLanguage
        : this.defaultLanguage)
    );
  }

  getUserLanguage(): void {
    this.userService.getUser().then((user) => {
      if (user.profile.language) {
        this.setLanguage(user.profile.language);
      }
    });
  }

  updateUserLanguage(isoCode: string) {
    return this.UpdateUserGQL.mutate({
      userInput: {
        profile: {
          language: isoCode,
        },
      },
    });
  }
}
