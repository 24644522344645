import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StoredDocumentWithDataFragment } from 'src/app/graphql/generated';
import { storedDocumentToJsFile } from './storedDocumentToJsFile';

@Pipe({
  name: 'storedDocumentUrl',
})
export class StoredDocumentUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(
    storedDocument: StoredDocumentWithDataFragment,
    sanitize = false,
  ): any {
    if (sanitize) {
      return this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(storedDocumentToJsFile(storedDocument)),
      );
    } else {
      return URL.createObjectURL(storedDocumentToJsFile(storedDocument));
    }
  }
}
