import { parseDayDateString } from './date-helpers/parseDayDateString';
import {
  addDays,
  differenceInDays,
  getUnixTime,
  startOfDay,
  subMonths,
} from 'date-fns';
import { getDayDateString } from './date-helpers/getDayDateString';
import {
  DayFragment,
  DayStatusEnum,
  EmploymentFragment,
} from '../../../graphql/generated';

export function mergeIncomingDays(
  inComingDays: DayFragment[],
  currentDays: DayFragment[],
) {
  for (const day of inComingDays) {
    const index = currentDays.findIndex((d) => d.date === day.date);
    if (index > -1) {
      currentDays[index] = day;
    } else {
      currentDays.push(day);
    }
  }
  return currentDays;
}

export function createPendingDays(
  employment: EmploymentFragment,
  existingDays: DayFragment[],
): DayFragment[] {
  const start = parseDayDateString(employment.startDate);
  let end = employment.endDate
    ? parseDayDateString(employment.endDate)
    : new Date();
  if (getUnixTime(end) > getUnixTime(new Date())) {
    end = startOfDay(new Date());
  }
  existingDays.sort(
    (a, b) =>
      getUnixTime(parseDayDateString(a.date)) -
      getUnixTime(parseDayDateString(b.date)),
  );
  const daysInRange = existingDays.filter(
    (day) => getUnixTime(parseDayDateString(day.date)) >= getUnixTime(start),
  );

  let dateIterator = start;
  const newDaysArray = existingDays;
  while (differenceInDays(end, dateIterator) >= 0) {
    const dayExistsAlready =
      daysInRange.length > 0 &&
      daysInRange[0].date === getDayDateString(dateIterator);
    // this fixes the mont -09- to not be equal to month -9-
    if (dayExistsAlready) {
      daysInRange.splice(0, 1);
    } else {
      if (
        getUnixTime(startOfDay(dateIterator)) >
        getUnixTime(subMonths(startOfDay(new Date()), 3))
      ) {
        newDaysArray.push({
          status: DayStatusEnum.OPEN,
          date: getDayDateString(startOfDay(dateIterator)),
          hoursEntries: [],
          absenceUUID: null,
          expenseUUID: null,
          reportUUID: null,
          approvedAt: null,
          approvedBy: null,
        });
      }
    }
    dateIterator = addDays(dateIterator, 1);
  }

  return newDaysArray;
}
