import { DayFragment, DayStatusEnum } from 'src/app/graphql/generated';
import { parseDayDateString } from './date-helpers/parseDayDateString';
import { getUnixTime } from 'date-fns';

export function dayIsApprovable(day: DayFragment): boolean {
  return (
    day &&
    day.status === DayStatusEnum.OPEN &&
    getUnixTime(parseDayDateString(day.date)) < getUnixTime(new Date()) &&
    dayHasData(day)
  );
}

export function dayHasData(day: DayFragment): boolean {
  return !!(
    day.hoursEntries.filter((entry) => entry.duration && !entry.deletedAt)
      .length > 0 ||
    day.expenseUUID ||
    day.absenceUUID
  );
}
