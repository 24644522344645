import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {
  I18NEXT_SERVICE,
  I18NextModule,
  ITranslationService,
} from 'angular-i18next';
import serverErrorsEn from '../locales/server-errors.en.json';
import serverErrorsDe from '../locales/server-errors.de.json';
import serverErrorsFr from '../locales/server-errors.fr.json';
import messagesEn from '../locales/uniweb-frontend.en.json';
import messagesDe from '../locales/uniweb-frontend.de.json';
import messagesFr from '../locales/uniweb-frontend.fr.json';
import enumsEn from '../locales/enums.en.json';
import enumsDe from '../locales/enums.de.json';
import enumsFr from '../locales/enums.fr.json';
import { LanguageMockService } from './core/mock-services/language-mock.service';
import {
  Event,
  NavigationEnd,
  Router,
  RouterEvent,
  Scroll,
} from '@angular/router';
import { CommonModule, ViewportScroller } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedDefaultModule } from './shared/modules/shared-default/shared-default.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AvatarModule,
  ButtonModule,
  ContextMenuModule,
  IconModule,
  ToastModule,
  UjSelectInputModule,
} from '@intemp/unijob-ui';
import { ProfileModule } from './pages/profile/profile.module';
import { UniBaseXGraphQLModule } from './core/graphql.module';
import { AuthModule } from '@auth0/auth0-angular';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { LanguageSwitchComponent } from './pages/language-switch/language-switch.component';
import { LanguageService } from './core/services/language.service';
import { GlobalSheetsModule } from './shared/modules/global-sheets/global-sheets.module';
import { ScrollToTopModule } from './shared/modules/scroll-to-top/scroll-to-top.module';
import { UserAvatarModule } from './shared/modules/user-avatar/user-avatar.module';
import { ErrorComponent } from './pages/error/error.component';
import { AppVersionComponent } from './shared/components/app-version.component';

export function appInit(
  i18next: ITranslationService,
  languageService: LanguageService,
): any {
  const defaultLanguage = languageService.defaultLanguage.toLowerCase();
  const language = languageService.getActiveLanguage().toLowerCase();

  return () =>
    i18next.init({
      fallbackLng: defaultLanguage,
      lng: language,
      resources: {
        en: {
          translation: { ...messagesEn, ...enumsEn, ...serverErrorsEn },
        },
        de: {
          translation: { ...messagesDe, ...enumsDe, ...serverErrorsDe },
        },
        fr: {
          translation: { ...messagesFr, ...enumsFr, ...serverErrorsFr },
        },
      },
      debug: false,
      returnEmptyString: false,
      ns: ['translation', 'validation', 'error'],
    });
}

export function localeIdFactory(i18next: ITranslationService): string {
  return i18next.language;
}

export const I18N_TESTING_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE, LanguageMockService],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE, LanguageService],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LanguageSwitchComponent,
    ErrorComponent,
  ],
  imports: [
    CommonModule,
    UniBaseXGraphQLModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    I18NextModule.forRoot(),
    SharedDefaultModule,
    FormsModule,
    AuthModule.forRoot({
      domain: environment.auth0ApiUrl,
      clientId: environment.auth0ClientId,
      cacheLocation: 'localstorage',
      authorizationParams: {
        audience: environment.audience,
        redirect_uri: window.location.origin,
      },
    }),
    AvatarModule,
    ButtonModule,
    GlobalSheetsModule,
    UjSelectInputModule,
    ScrollToTopModule,
    IconModule,
    ProfileModule,
    ToastModule,
    ScrollToTopModule,
    ContextMenuModule,
    UserAvatarModule,
    AppVersionComponent,
    ReactiveFormsModule,
  ],
  providers: [I18N_PROVIDERS],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
  ) {
    this.router.events
      .pipe(
        filter((e: Event | RouterEvent): e is Scroll => e instanceof Scroll),
        pairwise(),
      )
      .subscribe((eventPair) => {
        const previousEvent = eventPair[0];
        const event = eventPair[1];
        if (event.position) {
          // backward navigation
          this.viewportScroller.scrollToPosition(event.position);
        } else if (event.anchor) {
          // anchor navigation
          this.viewportScroller.scrollToAnchor(event.anchor);
        } else {
          // forward navigation
          if (
            previousEvent.routerEvent instanceof NavigationEnd &&
            event.routerEvent instanceof NavigationEnd &&
            previousEvent.routerEvent.urlAfterRedirects.split('?')[0] !==
              event.routerEvent.urlAfterRedirects.split('?')[0]
          ) {
            // Routes don't match, this is actual forward navigation
            // Default behavior: scroll to top
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });
  }
}
