import { Injectable } from '@angular/core';
import {
  firstValueFrom,
  lastValueFrom,
  Observable,
  Subject,
  switchMap,
} from 'rxjs';
import { DocumentTypeEnum, StoredDocument } from 'src/app/graphql/generated';
import { map, tap } from 'rxjs/operators';
import {
  DownloadUserDocumentGQL,
  GetDocumentsGQL,
  GetStoredDocumentGQL,
} from 'src/app/graphql/generated';
import { filterDocuments } from '../../../shared/helpers/documents/filterDocuments';
import { DocumentPageTypesEnum } from '../../types';
import { UnreadDocumentsState } from './UnreadDocumentsState';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  documentPagesEnum = DocumentPageTypesEnum;
  private documentCountSource = new Subject<UnreadDocumentsState>();
  public documentCountObservable = this.documentCountSource.asObservable();

  constructor(
    private GetStoredDocumentGQL: GetStoredDocumentGQL,
    private DownloadUserDocumentGQL: DownloadUserDocumentGQL,
    private GetDocumentsGQL: GetDocumentsGQL,
  ) {
    this.updateUnreadDocumentCount().subscribe();
  }

  getStoredDocument = async (uuid: string) => {
    return lastValueFrom(this.getStoredDocument$(uuid));
  };

  getDocuments() {
    return this.GetDocumentsGQL.fetch().pipe(
      map((res) => res.data[`User`][`storedDocuments`] || []),
    );
  }

  getStoredDocument$(uuid: string) {
    return this.GetStoredDocumentGQL.fetch({
      uuid,
    }).pipe(map((res) => res.data['getStoredDocument'] || undefined));
  }

  getUserDocument(documentId: string) {
    return this.DownloadUserDocumentGQL.fetch({
      uuid: documentId,
    }).pipe(
      switchMap(async (res) => {
        await firstValueFrom(this.updateUnreadDocumentCount());
        return res.data['downloadUserDocument'];
      }),
    );
  }

  updateUnreadDocumentCount(): Observable<StoredDocument[]> {
    return this.GetDocumentsGQL.fetch().pipe(
      map((res) => res.data[`User`][`storedDocuments`] as StoredDocument[]),
      tap((documents) => this.updateUnreadDocumentsSubject(documents)),
    );
  }

  updateUnreadDocumentsSubject(documents: StoredDocument[]) {
    const responseObject: UnreadDocumentsState = {
      unread: {
        all: filterDocuments(documents, true, false).length,
        others: filterDocuments(
          documents,
          true,
          false,
          // @ts-ignore
          Object.values(this.documentPagesEnum) as DocumentTypeEnum[],
        ).length,
      },
      read: {
        all: filterDocuments(documents, false, false).length,
        others: filterDocuments(
          documents,
          false,
          false,
          // @ts-ignore
          Object.values(this.documentPagesEnum) as DocumentTypeEnum[],
        ).length,
      },
    };
    for (const [pagetype, doctype] of Object.entries(this.documentPagesEnum)) {
      responseObject.unread[pagetype] = filterDocuments(
        documents,
        true,
        DocumentTypeEnum[doctype as keyof typeof DocumentTypeEnum],
      ).length;
      responseObject.read[pagetype] = filterDocuments(
        documents,
        false,
        DocumentTypeEnum[doctype as keyof typeof DocumentTypeEnum],
      ).length;
    }
    this.documentCountSource.next(responseObject);
  }
}
