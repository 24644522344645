import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import {
  RoutePermissionKeyEnum,
  UserService,
} from '../../models/shared/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(public userService: UserService, public router: Router) {}

  async canMatch(
    route: Route,
    segments: UrlSegment[],
  ): Promise<boolean | undefined> {
    let redirectUrl = null;

    const link = this.router.getCurrentNavigation()?.extractedUrl.toString();
    if (link && link !== '/') {
      redirectUrl = encodeURIComponent(link);
    }
    await this.userService.authUserReady;
    const authUser = await this.userService.authUser$.value;
    if (!authUser) {
      // if not logged in go to login
      this.router
        .navigate(['/login'], { queryParams: { redirectTo: redirectUrl } })
        .then();
      return;
    }

    if (authUser.isVerified) {
      const loadablePages = await this.userService.getLoadablePages();
      const routePermissionKey: RoutePermissionKeyEnum =
        route?.data?.routePermissionKey;
      if (routePermissionKey && loadablePages) {
        if (loadablePages[routePermissionKey]) {
          return true;
        } else {
          if (loadablePages[RoutePermissionKeyEnum.DASHBOARD]) {
            this.router.navigate([RoutePermissionKeyEnum.DASHBOARD]).then();
          }
          return false;
        }
      } else {
        return true;
      }
    }
    if (authUser && !authUser.isVerified) {
      this.router
        .navigate(['login'], { queryParams: { verifyUser: 1 } })
        .then();
      return false;
    }
    this.router
      .navigate(['/login'], { queryParams: { redirectTo: redirectUrl } })
      .then();
    return false;
  }
}
