import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, shareReplay } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { filter, map } from 'rxjs/operators';
import {
  StoredDocumentInput,
  UserInput,
  GetConsultantsGQL,
  GetSamsungBenefitGQL,
  GetUserGQL,
  PreVerificationUserCheckGQL,
  RemoveProfileImageGQL,
  RemoveUserDocumentGQL,
  ResendVerificationEmailGQL,
  SendVerificationSmsGQL,
  UpdateUserGQL,
  UploadProfileImageGQL,
  UploadUserDocumentGQL,
  UserFragment,
  VerifyUserGQL,
} from 'src/app/graphql/generated';
import { AuthService } from '@auth0/auth0-angular';
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js/src/global';
import { jwtDecode } from 'jwt-decode';
import { Writable } from 'type-fest';
import { AuthUserModel } from './AuthUserModel';

export type CanLoadPages = Partial<Record<RoutePermissionKeyEnum, boolean>>;

export enum RoutePermissionKeyEnum {
  DASHBOARD = 'DASHBOARD',
  EMPLOYEES = 'EMPLOYEES',
  EMPLOYMENTS = 'EMPLOYMENTS',
  CONTACT = 'CONTACT',
  COMPANIES = 'COMPANIES',
  PROFILE = 'PROFILE',
  SUPPORT = 'SUPPORT',
  DOCUMENTS = 'DOCUMENTS',
  EXPORT = 'EXPORT',
  BENEFITS = 'BENEFITS',
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  env = environment;
  user$ = new BehaviorSubject<UserFragment | undefined>(undefined);
  readyUser$ = this.user$.pipe(filter((user): user is UserFragment => !!user));

  userIsCompanyUser$ = this.readyUser$.pipe(
    map((user) => !!user.companyIds?.length),
    shareReplay(1),
  );

  get user() {
    return this.user$.value;
  }

  userIsVerifiedSubject = new BehaviorSubject<boolean>(false);
  userIsVerified = false;

  authUser$ = new BehaviorSubject<undefined | AuthUserModel>(undefined);

  authUserReady: Promise<void>;

  private canLoadPages?: CanLoadPages;

  constructor(
    private authService: AuthService,
    private GetConsultantsGQL: GetConsultantsGQL,
    private GetSamsungBenefitGQL: GetSamsungBenefitGQL,
    private GetUserGQL: GetUserGQL,
    private PreVerificationUserCheckGQL: PreVerificationUserCheckGQL,
    private RemoveUserDocumentGQL: RemoveUserDocumentGQL,
    private RemoveProfileImageGQL: RemoveProfileImageGQL,
    private ResendVerificationEmailGQL: ResendVerificationEmailGQL,
    private SendVerificationSmsGQL: SendVerificationSmsGQL,
    private UpdateUserGQL: UpdateUserGQL,
    private UploadUserDocumentGQL: UploadUserDocumentGQL,
    private UploadProfileImageGQL: UploadProfileImageGQL,
    private VerifyUserGQL: VerifyUserGQL,
  ) {
    this.authUserReady = new Promise<void>((resolve) => {
      this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this.loadAuthUser().then(() => {
            resolve();
          });
        } else {
          resolve();
        }
      });
    });
  }

  async loadAuthUser(
    cacheMode: GetTokenSilentlyOptions['cacheMode'] = 'off',
  ): Promise<AuthUserModel> {
    const token = await firstValueFrom(
      this.authService.getAccessTokenSilently({ cacheMode }),
    );
    const newAuthUser = new AuthUserModel(jwtDecode(token));
    this.authUser$.next(newAuthUser);
    return newAuthUser;
  }

  checkUserIsVerified(): boolean {
    const authUser = this.authUser$.value;
    const verified = !!(authUser && authUser.isVerified);
    if (verified !== this.userIsVerified) {
      this.userIsVerified = verified;
      this.userIsVerifiedSubject.next(verified);
    }
    return verified;
  }

  async getLoadablePages(): Promise<CanLoadPages> {
    if (!this.canLoadPages) {
      const user = await this.getUser();
      this.canLoadPages = {};
      if (user) {
        if (user.staff) {
          this.canLoadPages[RoutePermissionKeyEnum.DASHBOARD] = true;
          this.canLoadPages[RoutePermissionKeyEnum.DOCUMENTS] = true;
          this.canLoadPages[RoutePermissionKeyEnum.PROFILE] = true;
          this.canLoadPages[RoutePermissionKeyEnum.BENEFITS] = true;
        }
        if (user.personalNumber && !user.staff) {
          this.canLoadPages[RoutePermissionKeyEnum.DASHBOARD] = true;
          this.canLoadPages[RoutePermissionKeyEnum.EMPLOYMENTS] = true;
          this.canLoadPages[RoutePermissionKeyEnum.CONTACT] = true;
          this.canLoadPages[RoutePermissionKeyEnum.SUPPORT] = true;
          this.canLoadPages[RoutePermissionKeyEnum.PROFILE] = true;
          this.canLoadPages[RoutePermissionKeyEnum.DOCUMENTS] = true;
          this.canLoadPages[RoutePermissionKeyEnum.BENEFITS] = true;
        }
        if (user.companyIds?.length) {
          this.canLoadPages[RoutePermissionKeyEnum.DASHBOARD] = true;
          this.canLoadPages[RoutePermissionKeyEnum.EMPLOYEES] = true;
          this.canLoadPages[RoutePermissionKeyEnum.CONTACT] = true;
          this.canLoadPages[RoutePermissionKeyEnum.SUPPORT] = true;
          this.canLoadPages[RoutePermissionKeyEnum.EXPORT] = true;
        }
      }
    }
    return this.canLoadPages;
  }

  uploadProfileDocument(storedDocument: StoredDocumentInput) {
    return this.UploadUserDocumentGQL.mutate(
      { storedDocumentInput: storedDocument },
      {
        context: {
          useMultipart: true,
        },
      },
    );
  }

  preVerificationUserCheck(
    personalNumber: number,
    dateOfBirth: string,
  ): Observable<any> {
    return this.PreVerificationUserCheckGQL.mutate({
      checkUserVerificationInput: {
        personalNumber,
        dateOfBirth,
      },
    });
  }

  sendVerificationSMS(personalNumber: number, dateOfBirth: string) {
    return this.SendVerificationSmsGQL.mutate({
      checkUserVerificationInput: {
        personalNumber,
        dateOfBirth,
      },
    });
  }

  verifyUser$(
    personalNumber: number,
    dateOfBirth: string,
    secret: string,
    language: string,
  ): Observable<any> {
    return this.VerifyUserGQL.mutate({
      verifyUserInput: {
        personalNumber,
        dateOfBirth,
        secret,
        language,
      },
    });
  }

  sendVerifyMail$() {
    return this.ResendVerificationEmailGQL.mutate();
  }

  async getUser(reFetch = false) {
    if (this.user && !reFetch) {
      return this.user;
    } else {
      const user = await this._requestUser();
      this.user$.next(user);
      return user;
    }
  }

  private _requestUser() {
    return firstValueFrom(
      this.GetUserGQL.fetch({}, { fetchPolicy: 'no-cache' }).pipe(
        map((res) => {
          return res.data.User;
        }),
      ),
    );
  }

  updateUser$(userInput: UserInput) {
    this.applyUserUpdateOptimistically(userInput);
    return this.UpdateUserGQL.mutate({ userInput });
  }

  applyUserUpdateOptimistically(userInput: UserInput): void {
    const user: Writable<UserFragment> | undefined = this.user;
    if (!user) {
      return;
    }
    if (userInput.responsibleForEmployees !== undefined) {
      if (userInput.responsibleForEmployees === null) {
        user.responsibleForEmployees = null;
      } else {
        user.responsibleForEmployees = userInput.responsibleForEmployees;
      }
    }
    if (userInput.trackedActions) {
      user.trackedActions = {
        hasSeenSamsungBenefit:
          userInput.trackedActions.hasSeenSamsungBenefit || false,
        hasSeenGalaxusBenefit:
          userInput.trackedActions.hasSeenGalaxusBenefit || false,
        hasSeenKieserBenefit:
          userInput.trackedActions.hasSeenKieserBenefit || false,
        hasSeenCarifyBenefit:
          userInput.trackedActions.hasSeenCarifyBenefit || false,
        hasSeenFitpassBenefit:
          userInput.trackedActions.hasSeenFitpassBenefit || false,
        hasSeenAmagBenefit:
          userInput.trackedActions.hasSeenAmagBenefit || false,
      };
    }
    this.user$.next(user);
  }

  getConsultants$() {
    return this.GetConsultantsGQL.fetch().pipe(
      map((res) => {
        return res.data.User?.consultants || [];
      }),
    );
  }

  getSamsungBenefitLink$() {
    return this.GetSamsungBenefitGQL.fetch().pipe(
      map((res) => {
        return res.data.getSamsungBenefit;
      }),
    );
  }

  uploadProfileImage$(storedDocument: StoredDocumentInput): Observable<any> {
    return this.UploadProfileImageGQL.mutate({
      storedDocumentInput: storedDocument,
    });
  }

  removeFile(uuid: string) {
    return this.RemoveUserDocumentGQL.mutate(
      { uuid },
      {
        context: {
          useMultipart: true,
        },
      },
    );
  }

  removeProfileImage$(uuid: string): Observable<any> {
    return this.RemoveProfileImageGQL.mutate(
      { uuid },
      {
        context: {
          useMultipart: true,
        },
      },
    );
  }
}
