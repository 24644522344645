import { EmploymentUpdateData } from 'src/app/models/types';
import {
  DayInput,
  DayStatusEnum,
  DayFragment,
} from 'src/app/graphql/generated';
import { getHoursEntryInput } from './getHoursEntryInput';
import { hourEntryHasData } from './hourEntryHasData';

export function getDayInput(
  day: DayFragment,
  updateData: EmploymentUpdateData,
): DayInput {
  return {
    date: day.date || new Date().toISOString().split('T')[0],
    status: day.status || DayStatusEnum.OPEN,
    hoursEntries: updateData?.hoursEntries
      ?.filter((entry) =>
        day.hoursEntries?.some(
          (dayHours) =>
            dayHours.uuid === entry.uuid && hourEntryHasData(dayHours),
        ),
      )
      .map((hours) => getHoursEntryInput(hours)),
  };
}
