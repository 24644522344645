<div (window:scroll)="setScrollPosistion()">
  <div
    (click)="scrollToToday()"
    class="day-scroll-link"
    *ngIf="scrollPosition">
    <div class="hint">
      {{ "scrollTop" | i18next | firstUppercase }}
    </div>
    <button
      class="day-scroll-button"
      unijob-button
      variant="secondary"
      theme="flat"
      iconOnly="arrow_up"
      size="m"
      corner="round"
      bgColor="bg-white"
      contentColor="content-color-black"></button>
  </div>
</div>
