import { EmploymentUpdateData } from 'src/app/models/types';
import { EmploymentInput } from 'src/app/graphql/generated';
import { getDayInput } from './getDayInput';

export function getEmploymentInput(
  updateData: EmploymentUpdateData,
): EmploymentInput {
  return {
    uuid: updateData.employmentUuid || '',
    days: updateData.days?.map((day) => getDayInput(day, updateData)) || [],
  };
}
