import { HoursEntryFragment } from 'src/app/graphql/generated';

export function hourEntryHasData(entry: HoursEntryFragment) {
  return (
    entry.from ||
    entry.to ||
    entry.duration ||
    entry.pause ||
    entry?.previousVersions?.length
  );
}
